import React, {useState, useEffect} from 'react';
//import { Link } from "react-router-dom";
import Header from "../../components/Headers/Header";
import { Spinner, Card, CardHeader, CardBody, Table, Container, Row, Button, Input, InputGroupAddon, InputGroup, InputGroupText} from "reactstrap";
import { getAllNotificationUsersData } from '../../api/config';

const Notification = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getAllNotificationUsers();
  }, []);

  const getAllNotificationUsers = async () => {
    setLoading(true);
    const res = await getAllNotificationUsersData();

    if(res.success === true) {
      setError(false);
      setData(res.data);
      setDataSearch(res.data);
    }
    else {
      setError(true);
      setData([]);
      setDataSearch([]);
    }
    setLoading(false);
  }

  const handleSearch = (text) => {
    if (text) {
      const newData = data.filter( (item) => {
        const itemData = item.name
          ? item.name.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setSearch(text);
      setDataSearch(newData);
    } 
    else {
      setSearch(text);
      setDataSearch(data);
    }   
  }

  return (
    <>
      <Header />
      
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Notificaciones </h3>
                  </div>
                  {/* 
                    <div className="col">
                      <Button 
                        to={`/admin/notification/new`} 
                        tag={Link}
                        className="float-right btn-sm"
                        style={{
                          backgroundColor:"#004899",
                          color: "#fff",
                          border: 0
                        }}>Nueva notificación</Button>
                    </div>
                  */}
                </Row>
              </CardHeader>
              
              <CardBody>
                <div className='paddingHorizonal'>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={search}
                      style={{paddingLeft: '5px'}}
                      placeholder="Buscar"
                      onChange={(val) =>  handleSearch(val.target.value)}
                      />
                  </InputGroup>
                  <br />
                </div>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" />
                      <th scope="col"> Nombre </th>
                      <th scope="col"> E-mail </th>
                      <th scope="col"> Tipo de notifiación </th>
                      <th scope="col" />
                    </tr>
                  </thead>

                  <tbody>
                    {
                      (loading) ?
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <Spinner style={{color: '#004899'}} />
                        </td>
                      </tr>:
                      (error) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'>
                          <label> Ha ocurrido un error al obtener las notificaiones </label>
                          <br />
                          <Button 
                            color="secondary"
                            onClick={() => getAllNotificationUsers()}>Cargar</Button>
                        </td>
                      </tr>:
                      (data.length === 0) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'> No hay información regristrada. </td>
                      </tr>
                      :
                      dataSearch.map( (d,i) => (
                        <tr key={i}>
                          <td>
                            {++i}
                          </td>
                          <td>
                            {d.name}
                          </td>
                          <td>
                            {d.email}
                          </td>
                          <td>
                            {d.type}
                          </td>
                          {
                            /*
                              <td className="text-right">
                                <Button 
                                  size='sm'
                                  style = {{
                                    backgroundColor:"#004899",
                                    color: "#fff",
                                    border: 0
                                  }}
                                  to={`/admin/notification/${d.id}`} 
                                  tag={Link}
                                > Editar </Button>
                              </td>
                            */
                          }
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Notification;