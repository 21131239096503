import React, {useEffect, useState} from "react";
import { Spinner, Container, Row, Col, Card, CardHeader, CardBody, FormGroup, Button, Input } from "reactstrap";
import Header from "../../components/Headers/Header";
import { getAllReportData, 
  getReportByCategoryData, 
  getReportByBrandCategoryData,
  getReportByStatusBillsData, 
  getReportByStatusBillsDateData, 
  getReportApproveDateData } from "./../../api/reports";
import { getBrandData } from '../../api/database';
import moment from'moment';
import NotificationAlert from "react-notification-alert";

export default function Reports() {
  const notificationAlertRef = React.useRef(null);

  const [loading, setLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState({
    byPieces: false,
  });

  const [loadingGeneral, setLoadingGeneral] = useState(false);
  const [loadingStatusAll, setLoadingStatusAll] = useState(false);
  const [loadingStatusDate, setLoadingStatusDate] = useState(false);
  const [loadingApproval, setLoadingApproval] = useState(false);
  const [brand, setBrand] = useState([]);
  const [selBrand, setSelBrand] = useState(''); 
  const [selCategory, setSelCategory] = useState(''); 
  const [selStatus, setSelStatus] = useState(''); 
  const [initDate, setInitDate] = useState(''); 
  const [endDate, setEndDate] = useState(''); 
  const [approveDate, setApproveDate] = useState('');

  useEffect( () => {
    getAllActiveBrand();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getAllReport = async () => {
    setLoadingGeneral(true);
    const res = await getAllReportData();

    if(res.success === true) {
      var link = document.createElement('a');
      link.download = `Reporte general ${moment().format('DD/MM/YYYY')}.pdf`;
      link.href = 'data:application/octet-stream;base64,' + res.data;
      link.click();
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al generar el reporte");
    }
    setLoadingGeneral(false);
  }

  const getReportByCategory = async (value) => {
    setLoadingGeneral(true);

    const res = await getReportByCategoryData(value);

    if(res.success === true) {
      var link = document.createElement('a');
      link.download = `Reporte general ${moment().format('DD/MM/YYYY')}.pdf`;
      link.href = 'data:application/octet-stream;base64,' + res.data;
      link.click();
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al generar el reporte");
    }
    setLoadingGeneral(false);
  }

  const getAllActiveBrand = async () => {
    setLoading(true);
    const res = await getBrandData();

    if(res.success === true) {
      const data = (res.data).filter( b => {
        return b.active === 0
      });

      setBrand(data);
    }
    else {
      setBrand([]);
    }
    setLoading(false);
  }

  const makeReportByBrand = async () => {
    setLoadingReport({...loadingReport, byPieces: true});
    
    if(selBrand === "") {
      notifyAlert("danger", "Error", "Debe seleccionar la marca");
    }
    else if(selCategory === "") {
      notifyAlert("danger", "Error", "Debe seleccionar la categoría");
    }
    else {
      const res = await getReportByBrandCategoryData(selBrand, selCategory);
      
      if(res.success === true) {
        var link = document.createElement('a');
        link.download = `Reporte general ${moment().format('DD/MM/YYYY')}.pdf`;
        link.href = 'data:application/octet-stream;base64,' + res.data;
        link.click();
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al generar el reporte");
      }
    }

    setLoadingReport({...loadingReport, byPieces: false});
  }

  const makeReportStatusBills = async () => {
    setLoadingStatusAll(true);
    if(selStatus === "") {
      notifyAlert("danger", "Error", "Debe seleccionar el estado");
    }
    else {
      const res = await getReportByStatusBillsData(selStatus);
      
      if(res.success === true) {
        var link = document.createElement('a');
        link.download = `Reporte general ${moment().format('DD/MM/YYYY')}.pdf`;
        link.href = 'data:application/octet-stream;base64,' + res.data;
        link.click();
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al generar el reporte");
      }
    }
    setLoadingStatusAll(false);
  }

  const makeReportStatusBillsDate = async () => {
    setLoadingStatusDate(true);
    if(initDate=== "") {
      notifyAlert("danger", "Error", "Debe seleccionar una fecha inicial");
    }
    else if(endDate === "") {
      notifyAlert("danger", "Error", "Debe seleccionar una fecha final");
    }
    else {
      const res = await getReportByStatusBillsDateData(initDate, endDate);
      
      if(res.success === true) {
        var link = document.createElement('a');
        link.download = `Reporte general ${moment().format('DD/MM/YYYY')}.pdf`;
        link.href = 'data:application/octet-stream;base64,' + res.data;
        link.click();
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al generar el reporte");
      }
    }
    setLoadingStatusDate(false);
  }

  const getApprovalReport = async () => {
    setLoadingApproval(true);

    if(approveDate=== "") {
      notifyAlert("danger", "Error", "Debe seleccionar una fecha");
    }
    else {
      const res = await getReportApproveDateData(approveDate);
      
      if(res.success === true) {
        var link = document.createElement('a');
        link.download = `Reporte de aprobados ${moment().format('DD/MM/YYYY')}.pdf`;
        link.href = 'data:application/octet-stream;base64,' + res.data;
        link.click();
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al generar el reporte");
      }
    }
    setLoadingApproval(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Reportes </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div>
                  <h5> Reporte por piezas </h5>
                  <Row>
                    <Col md="6">
                      {
                        (loading) ? <>
                          <Spinner style={{color: '#004899'}} />
                        </> : <>
                          <FormGroup>
                            <span className="form-control-label">Seleccionar marca</span>
                            <select 
                              className="form-control" 
                              style={{appearance: 'auto'}} 
                              value={selBrand}
                              onChange={(val) => setSelBrand(val.target.value)}
                            >
                              <option value="">Seleccionar</option>
                              {
                                brand.map((b,i) => (
                                  <option key={i} value={b.code}>{b.name}</option>
                                ))
                              }
                            </select>
                          </FormGroup>
                        </>
                      }

                      <FormGroup>
                        <span className="form-control-label">Seleccionar categoría</span>
                        <select 
                          className="form-control" 
                          style={{appearance: 'auto'}} 
                          value={selCategory}
                          onChange={(val) => setSelCategory(val.target.value)}
                        >
                          <option value="">Seleccionar</option>
                          <option value="all">Todos</option>
                          <option value="1">Montura</option>
                          <option value="2">Sol</option>
                        </select>
                      </FormGroup>

                      <div style={{textAlign: 'right'}}>

                        {
                          (loadingReport.byPieces) ? <>
                            <Spinner color="primary" />
                          </> : <>
                            <Button color="primary" onClick={() => makeReportByBrand()}> Generar </Button>
                          </>
                        }
                      </div>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <p>Esta opcion genera un reporte con todas las piezas</p>
                      </FormGroup>
                      <div style={{textAlign: 'right'}}>
                        {
                          (loadingGeneral) ? <> 
                            <Spinner color="primary" />
                          </> : <>
                            <Button color="primary" 
                              style={{
                                marginBottom: '10px'
                              }}
                              onClick={() => getAllReport()}> Reporte general </Button>

                            <Button color="primary" 
                              style={{
                                marginBottom: '10px'
                              }}
                              onClick={() => getReportByCategory(1)}> Reporte montura </Button>
                              
                            <Button color="primary" 
                              style={{
                                marginBottom: '10px'
                              }}
                              onClick={() => getReportByCategory(2)}> Reporte sol </Button>
                          </>
                        }
                      </div>
                    </Col>
                  </Row>
                </div>
                
                <hr />
                <div>
                  <h5> Reporte de ordenes </h5>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <span className="form-control-label">Seleccionar estatus</span>
                        <select 
                          className="form-control" 
                          style={{appearance: 'auto'}} 
                          value={selStatus}
                          onChange={(val) => setSelStatus(val.target.value)}
                        >
                          <option value="">Seleccionar</option>
                          <option value="0">Todos</option>
                          <option value="1">Pendientes</option>
                          <option value="2">Aprobado</option>
                          <option value="3">Cancelado</option>
                          <option value="4">Anulado</option>
                        </select>
                      </FormGroup>

                      <div style={{textAlign: 'right'}}>
                        {
                          (loadingStatusAll) ? <>
                            <Spinner style={{color: '#004899'}} />
                          </> : <>
                            <Button color="primary" onClick={() => makeReportStatusBills()}> Generar </Button>
                          </>
                        }
                      </div>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <span className="form-control-label">Fecha inicial</span>
                        <Input
                          type="date"
                          value={initDate}
                          className="form-control"
                          onChange={val => setInitDate(val.target.value)}/>
                      </FormGroup>

                      <FormGroup>
                        <span className="form-control-label">Fecha Final</span>
                        <Input
                          type="date"
                          value={endDate}
                          className="form-control"
                          onChange={val => setEndDate(val.target.value)}/>
                      </FormGroup>

                      <div style={{textAlign: 'right'}}>
                        {
                          (loadingStatusDate) ? <>
                            <Spinner style={{color: '#004899'}} />
                          </> : <>
                            <Button color="primary" onClick={() => makeReportStatusBillsDate()}> Generar </Button>
                          </>
                        }
                      </div>
                    </Col>
                  </Row>
                </div>

                <hr />
                <div>
                  <h5> Reporte de aprobados </h5>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <span className="form-control-label">Fecha</span>
                        <Input
                          type="date"
                          value={approveDate}
                          className="form-control"
                          onChange={val => setApproveDate(val.target.value)}/>
                      </FormGroup>
                      
                      <div style={{
                        textAlign: 'right'
                      }}>
                        {
                          (loadingApproval) ? <>
                            <Spinner style={{color: '#004899'}} />
                          </> : <>
                            <Button color="primary" 
                              style={{
                                marginBottom: '10px'
                              }}
                              onClick={() => getApprovalReport()}> Generar </Button>
                          </>
                        }
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}