import React, {useState, useEffect} from 'react';
import Header from "../../../components/Headers/Header";
import {Spinner, Card, CardHeader, CardBody, Table, Container, Col, Row, Badge, Button, Input, InputGroupAddon, InputGroup, InputGroupText, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from "reactstrap";
import { getAllFAQData, saveFAQData , updateFAQData, deleteFAQData } from '../../../api/page';
import NotificationAlert from "react-notification-alert";

const FAQ = () => {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [search, setSearch] = useState('');
  const [modal, setModal] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState("1");
  const [nameEdit, setNameEdit] = useState('');
  const [descriptionEdit, setDescriptionEdit] = useState('');
  const [activeEdit, setActiveEdit] = useState("1");

  useEffect(() => {
    getFAQ();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getFAQ = async () => {
    setLoading(true);
    const res = await getAllFAQData();

    if(res.success === true) {
      setError(false);
      setData(res.data);
      setDataSearch(res.data);
    }
    else {
      setError(true);
      setData([]);
      setDataSearch([]);
    }
    setLoading(false);
  }

  const handleSearch = (text) => {
    if (text) {
      const newData = data.filter( (item) => {
        const itemData = item.name
          ? item.name.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setSearch(text);
      setDataSearch(newData);
    } 
    else {
      setSearch(text);
      setDataSearch(data);
    }   
  }

  const checkActive = (i) => {
    return (i === 0) ? 
      <Badge
        style={{
          backgroundColor: '#cc415d',
          color: '#ffffff'
        }}
        className='badgePadding'>No</Badge> :
      <Badge 
        style={{
          backgroundColor: '#14a969',
          color: '#ffffff'
        }}
        className='badgePadding'>Si</Badge>;
  }

  const saveFAQ = async () => {
    if(String(name).trim() === '') {
      notifyAlert("danger", "Error", "El campo nombre es obligatorio");
    }
    else if (String(description).trim() === '') {
      notifyAlert("danger", "Error", "El campo descripcion es obligatorio");
    }
    else {
      const jsn = {
        name: name,
        description: description,
        active: active
      }
  
      const res = await saveFAQData(jsn);
  
      if(res.success === true) {
        notifyAlert("success", "Mensaje", "El FAQ se ha creado correctamente");
        setName('');
        setDescription('');
        setActive("1");
        getFAQ();
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al crear el FAQ");
      }
  
      setModal(!modal);
    }
  }

  const editableFAQ = (value) => {
    setId(value.id);
    setNameEdit(value.title);
    setDescriptionEdit(value.description);
    setActiveEdit(value.active);
    setModalEdit(!modalEdit);
  }

  const updateFAQ = async () => {
    if(String(nameEdit).trim() === '') {
      notifyAlert("danger", "Error", "El campo nombre es obligatorio");
    }
    else if (String(descriptionEdit).trim() === '') {
      notifyAlert("danger", "Error", "El campo descripcion es obligatorio");
    }
    else {
      const jsn = {
        id: id,
        name: nameEdit,
        description: descriptionEdit,
        active: activeEdit
      }
  
      const res = await updateFAQData(jsn);
  
      if(res.success === true) {
        notifyAlert("success", "Mensaje", "El FAQ se ha editado correctamente");
        setId('');
        setNameEdit('');
        setDescriptionEdit('');
        setActiveEdit("1");
        getFAQ();
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al editar el FAQ");
      }
  
      setModalEdit(!modalEdit);
    }
  }

  const setToDelete = (value) => {
    setId(value);
    setModalDelete(!modalDelete);
  }

  const deleteFAQ = async () => {
    const res = await deleteFAQData(id);

    if(res.success === true) {
      notifyAlert("success", "Mensaje", "El FAQ se ha eliminado correctamente");
      setId('');
      getFAQ();
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al eliminar el FAQ");
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> FAQ </h3>
                  </div>
                  <div className="col">
                    <Button 
                      onClick={() => setModal(!modal)}
                      className="float-right btn-sm"
                      style={{
                        backgroundColor:"#004899",
                        color: "#fff",
                        border: 0
                      }}>Nuevo FAQ</Button>
                  </div>
                </Row>
              </CardHeader>
              
              <CardBody>
                <div className='paddingHorizonal'>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={search}
                      style={{paddingLeft: '5px'}}
                      placeholder={"Buscar"}
                      onChange={(val) =>  handleSearch(val.target.value)}
                      />
                  </InputGroup>
                  <br />
                </div>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" />
                      <th scope="col"> Nombre </th>
                      <th scope="col"> Descripcion </th>
                      <th scope="col"> Activo </th>
                      <th scope="col" />
                    </tr>
                  </thead>

                  <tbody>
                    {
                      (loading) ?
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <Spinner style={{color: '#004899'}} />
                        </td>
                      </tr>:
                      (error) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'>
                          <label> Ha ocurrido un error, por favor intente nuevamente otra vez.</label>
                          <br />
                          <Button 
                            color="secondary"
                            onClick={() => getFAQ()}> Cargar </Button>
                        </td>
                      </tr>:
                      (data.length === 0) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'> No hay información registrada </td>
                      </tr>
                      :
                      dataSearch.map( (d,i) => (
                        <tr key={i}>
                          <td>
                            {++i}
                          </td>
                          <td>
                            {d.title}
                          </td>
                          <td>
                            {d.description}
                          </td>
                          <td>
                            {checkActive(d.active)}
                          </td>
                          <td className="text-right">
                            <Button 
                              size='sm'
                              style={{backgroundColor: '#004899', color: "#ffffff"}} 
                              onClick={() => editableFAQ(d)}
                            > Editar </Button>

                            <Button 
                              size='sm'
                              color='danger' 
                              onClick={() => setToDelete(d.id)}
                            > Eliminar </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>Nuevo FAQ</ModalHeader>
        <ModalBody>
          <Container>
              <FormGroup>
                <label> Titulo </label>
                <Input 
                  value={name}
                  onChange={(val) => setName(val.target.value)}
                />
              </FormGroup>

              <FormGroup>
                <label> Descripcion </label>
                <Input 
                  value={description}
                  onChange={(val) => setDescription(val.target.value)}
                type="textarea" />
              </FormGroup>

              <FormGroup>
                <label> Activo </label>
                <select 
                  value={active}
                  onChange={(val) => setActive(val.target.value)}
                  className='form-control'>
                  <option value="1"> Si </option>
                  <option value="0"> No </option>
                </select>
              </FormGroup>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal(!modal)}>
            Cancel
          </Button>
          <Button 
            color="primary"
            onClick={() => saveFAQ()}>
            Guardar
          </Button>{' '}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEdit} toggle={() => setModalEdit(!modalEdit)}>
        <ModalHeader toggle={() => setModalEdit(!modalEdit)}>Actualizar FAQ</ModalHeader>
        <ModalBody>
          <Container>
              <FormGroup>
                <label> Titulo </label>
                <Input 
                  value={nameEdit}
                  onChange={(val) => setNameEdit(val.target.value)}
                />
              </FormGroup>

              <FormGroup>
                <label> Descripcion </label>
                <Input 
                  value={descriptionEdit}
                  onChange={(val) => setDescriptionEdit(val.target.value)}
                type="textarea" />
              </FormGroup>

              <FormGroup>
                <label> Activo </label>
                <select 
                  value={activeEdit}
                  onChange={(val) => setActiveEdit(val.target.value)}
                  className='form-control'>
                  <option value="1"> Si </option>
                  <option value="0"> No </option>
                </select>
              </FormGroup>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalEdit(!modalEdit)}>
            Cancel
          </Button>
          <Button 
            color="primary"
            onClick={() => updateFAQ()}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
        <ModalHeader toggle={() => setModalDelete(!modalDelete)}>Actualizar FAQ</ModalHeader>
        <ModalBody>
          <Container>
            <p> ¿Esta seguro que desea eliminar este FAQ? </p>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalDelete(!modalDelete)}>
            Cancel
          </Button>
          <Button 
            color="danger"
            onClick={() => deleteFAQ()}>
            Eliminar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default FAQ;