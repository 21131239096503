/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../components/Headers/Header";
import lang from '../../lang';
import { getNotificationTypeData, updateNotificationData, getNotificationUserIdData, deleteUserNotificationData } from "../../api/config";
import { getUsersData } from "../../api/users";


const EditNotification = () => {
  const {id} = useParams();
  const [userArr, setUserArr] = useState([]);
  const [typeNoArr, setTypeNoArr] = useState([]);
  const [user, setUser] = useState('');
  const [typeNo, setTypeNo] = useState('');
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    funct();
  }, []);

  const toggle = () => setModal(!modal);
  
  const funct = async () => {
    await getUsers();
    await getNotificationType();
    getNotificationUserId();
  }
  
  const getNotificationUserId = async () => {
    const res = await getNotificationUserIdData(id);
    setUser(res.data.user);
    setTypeNo(res.data.type);
  }
  
  const getNotificationType = async () => {
    const res = await getNotificationTypeData();
    setTypeNoArr(res.data);
  }

  const getUsers = async () => {
    const res = await getUsersData();
    setUserArr(res.data);
  }

  const setNewNotification = async () => {
    setLoading(true);
    
    if(user === '') {
      setMsg(lang().RESPONSE.USER_EMPTY);
      setModal(true);
    }
    else if(typeNo === '') {
      setMsg(lang().RESPONSE.NOTYPE_EMPTY);
      setModal(true);
    }
    else {
      const res = await updateNotificationData(id, user, typeNo);
      
      if(res.success === true) {
        setMsg(res.data);
        setModal(true);
      }
      else {
        setMsg(res.error.message);
        setModal(true);
      }
    }

    setLoading(false);
  }

  const deleteUserNotification = async () => {
    const res = await deleteUserNotificationData(id);

    if(res.success === true) {
      window.location.href = '/admin/notification'
    }
    else {
      setModal(true);
      setMsg(res.error.message);
    }
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

            <CardHeader className="border-0">
              <Row>
                <div className="col mb-0 float-left">
                  <h3 style={{color: '#004899',fontSize:'30px'}}> {lang().MENU.NOTIFICATION} </h3>
                </div>
              </Row>
            </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang().MENU.USERS} </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={user}
                          onChange={val => setUser(val.target.value)}
                        >
                          <option value="">{lang().TEXT.SELECT}</option>
                          {
                            userArr.map( (u, i) => (
                              <option key={i} value={u.id}>{u.name} - {u.email}</option>
                            ))
                          }
                        </select>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang().TEXT.NOTIFICATION_TYPE} </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={typeNo}
                          onChange={val => setTypeNo(val.target.value)}
                        >
                          <option value="">{lang().TEXT.SELECT}</option>
                          {
                            typeNoArr.map( (t, i) => (
                              <option key={i} value={t.id}>{t.name}</option>
                            ))
                          }
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading) ? <Spinner style={{color: '#004899'}}/> :
                          <div>
                            <Button color="gray" to="/admin/notification" tag={Link}> {lang().TEXT.BACK} </Button>
                            <Button color="danger" 
                              onClick={() => deleteUserNotification()}> {lang().TEXT.DELETE} </Button>
                            <Button
                              style={{
                                backgroundColor: '#004899',
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => setNewNotification()}
                              > {lang().TEXT.SAVE}
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
 
          </div>
        </Row>
	
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={() => setModal()}> {lang().TEXT.MESSAGE} </ModalHeader>
          <ModalBody>
            {msg}	
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal()}> OK </Button>
          </ModalFooter>
        </Modal>
     

      </Container>

    </>
  )
}

export default EditNotification;