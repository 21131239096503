/* eslint-disable no-unused-vars */
var BASE_URL;
const URL = window.location.href;
const _local = String(URL).includes('localhost');

if(_local) {
  BASE_URL = "http://localhost:3001/api";
}
else {
  BASE_URL = `https://spm-back.azurewebsites.net/api`;
}

export const session               = `${BASE_URL}/auth/session`;
export const login                 = `${BASE_URL}/auth/login`;
export const password              = `${BASE_URL}/auth/password`;
export const logout                = `${BASE_URL}/logout`;
export const invoices              = `${BASE_URL}/invoices`;
export const transit               = `${BASE_URL}/transit`;
export const report                = `${BASE_URL}/report`;
export const brand                 = `${BASE_URL}/brand`;
export const category              = `${BASE_URL}/category`;
export const warehouse             = `${BASE_URL}/warehouse`;
export const clients               = `${BASE_URL}/clients`;
export const pieces                = `${BASE_URL}/pieces`;
export const notification          = `${BASE_URL}/notification`;
export const config                = `${BASE_URL}/config`;
export const goals                 = `${BASE_URL}/goals`;
export const stats                 = `${BASE_URL}/stats`; 
export const budgets               = `${BASE_URL}/budgets`;
export const budgets_stats         = `${BASE_URL}/budgets/stats`;
export const budgets_seller        = `${BASE_URL}/budgets/seller`;
export const budgets_brand         = `${BASE_URL}/budgets/brand`;
export const budgets_piece         = `${BASE_URL}/budgets/pieces`;
export const budgets_graph         = `${BASE_URL}/budgets/stats/graph`;
export const budgets_seller_gen    = `${BASE_URL}/budgets/seller/all`;
export const users                 = `${BASE_URL}/users`;
export const notification_invoices = `${BASE_URL}/notification/invoices`;
export const page                  = `${BASE_URL}/page`;
export const catalog               = `${BASE_URL}/catalog`;


