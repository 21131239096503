/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Bar, Doughnut } from "react-chartjs-2";
import Header from "../../components/Headers/Header";
import {Spinner, Card, CardHeader, Table, Container, Row, Button, CardBody, Col, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import { getAllDatesData, getGeneralStasData, getMoreBrandPerYearData, 
  getMoreSellerPerYearData, getClientStatsData, getProfitPerYearData,
  getUserListData, getUserPieceStatData, getMoreBrandPerDateData } from '../../api/stats';

export default function Graph() {
  const arrMonth = [
    {
      "name": "Enero",
      "value": 1
    },
    {
      "name": "Febrero",
      "value": 2
    },
    {
      "name": "Marzo",
      "value": 3
    },
    {
      "name": "Abril",
      "value": 4
    },
    {
      "name": "Mayo",
      "value": 5
    },
    {
      "name": "Junio",
      "value": 6
    },
    {
      "name": "Julio",
      "value": 7
    },
    {
      "name": "Agosto",
      "value": 8
    },
    {
      "name": "Septiembre",
      "value": 9
    },
    {
      "name": "Octubre",
      "value": 10
    },
    {
      "name": "Noviembre",
      "value": 11
    },
    {
      "name": "Diciembre",
      "value": 12
    }
  ];
  const [loading, setLoading] = useState({
    general: false,
    brand: false,
    seller: false,
    clients: false,
    profit: false,
    list: false,
    pieces: false,
    brandDate: false,
    pieces_year: false,
    brand_year: false,
    seller_profit: false
  });
  const [arrDate, setArrDate] = useState([]);
  const [type , setType] = useState({
    brand: 'W',
    brand_range: "W",
    seller: 'W',
    client: 'W',
    profit: 'W',
    user: 'W',
    pieces: 'W',
    pieces_year: 'W',
    brand_year: 'W',
    seller_profit: 'W'
  });
 
  const [generalStats, setGeneralStats] = useState({});
  const [selMonth, setSelMonth] = useState(0);
  const [selYear, setSelYear] = useState(0);
  const [selMonthPieces, setSelMonthPieces] = useState(0);
  const [selYearSellerPieces, setSelYearSellerPieces] = useState(0);
  const [arrBrand, setArrBrand] = useState({legend: [] ,labels: [], datasets: [{ 
    label: 'Marcas',
    backgroundColor: '#004899',
    data: []
  }]});
  const [selMonthSeller, setSelMonthSeller] = useState(0);
  const [selYearSeller, setSelYearSeller] = useState(0);
  const [arrSeller, setArrSeller] = useState({legend: [] ,labels: [], datasets: [{ 
    label: 'Vendedores',
    backgroundColor: '#004899',
    data: []
  }]});
  const [lastClients, setLastClients] = useState([]);
  const [topClients, setTopClients] = useState([]);
  const [selYearProfit, setSelYearProfit] = useState(0);
  const [arrProfit, setArrProfit] = useState({legend: [] ,labels: [], datasets: [{ 
    label: 'Ganancias',
    backgroundColor: '#004899',
    data: []
  }]});
  const [userList, setUserList] = useState([]);
  const [arrTopPieces, setTopPieces] = useState({legend: [] ,labels: [], 
    datasets: [{ 
      label: 'Ganancias',
      backgroundColor: '#004899',
      data: []
    }]
  });
  const [gData, setGData] = useState(false);
  const [fullTotal, setFullTotal] = useState('');
  const [totalPiece, setTotalPiece] = useState('');
  const [rangeDate, setRangeDate] = useState({initDate: '', endDate: ''});
  const [arrBrandDate, setArrBrandDate] = useState({legend: [] ,labels: [], datasets: [{ 
    label: 'Marcas',
    backgroundColor: '#004899',
    data: []
  }]});
  const [modal, setModal] = useState(false);
  const [msg, setMsg] = useState('');
  const [brandByPieces, setBrandByPieces] = useState({
    brand: 0,
    total: 0
  });

  const [selYearProfitYear, setSelYearProfitYear] = useState(0);
  const [arrProfitYear, setArrProfitYear] = useState({legend: [] ,labels: [], datasets: [{ 
    label: 'Ganancias',
    backgroundColor: '#004899',
    data: []
  }]});

  const [selBrandYear, setSelBrandYear] = useState(0);
  const [arrBrandYear, setArrBrandYear] = useState({legend: [] ,labels: [], datasets: [{ 
    label: 'Ganancias',
    backgroundColor: '#004899',
    data: []
  }]});

  const [selMonthSellerProfit, setSelMonthSellerProfit] = useState(0);
  const [selYearSellerProfit, setSelYearSellerProfit] = useState(0);
  const [arrSellerProfit, setArrSellerProfit] = useState({legend: [] ,labels: [], datasets: [{ 
    label: 'Vendedores',
    backgroundColor: '#004899',
    data: []
  }]});

  useEffect(() => {
    funct();
  }, []);

  const toggle = () => setModal(!modal);

  const funct = async () => {
    await getAllDates();
    await getGeneralStas();
    await getMoreBrandPerYear();
    await getMoreSellerPerYear();
    await getClientStats();
    await getProfitPerYear();
    await getProfitPerYearYear();
    await getSelleProfitMonthYear();
    await getUserList();
    await getDateLast();
    await getBrandYear();
  }
  
  const getAllDates = async () => {
    const res = await getAllDatesData();
    console.log(res);
    setArrDate(res.data);
  }

  const getGeneralStas = async () => {
    const res = await getGeneralStasData();
    console.log(res);
    setGeneralStats(res.data);
    setLoading({...loading, general: false});
  }

  const getMoreBrandPerYear = async () => {
    setLoading({...loading, brand: true});

    let _month = new Date().getMonth()+1;
    let _year = new Date().getFullYear();

    if(selYear === 0 && selMonth === 0) {
      _month = new Date().getMonth()+1;
      _year = new Date().getFullYear();
    }
    else {
      _month = selMonth;
      _year = selYear;
    }

    const jsn = {
      month: _month,
      year: _year,
      type: type.brand,
      code: "BRAND"
    }

    const res = await getMoreBrandPerYearData(jsn);

    const data = res.data;
    const valuesText = data.map( (v) => {
      return v.BrandCode
    });
    const valuesData = data.map( (v) => {
      return v.Total
    });

    var colors = [];
    var pieces = 0;

    for(var i of data) {
      colors.push(dynamicColors());
      pieces += i.Total;
    }

    setBrandByPieces({
      brand: data.length,
      total: pieces
    });

    setArrBrand({
      legend: data,
      labels: valuesText, 
      datasets: [{
      label: 'Marcas',
      backgroundColor: colors,
      data: valuesData,
      type: 'bar'
    }]});

    setSelMonth(_month);
    setSelYear(_year);
    setLoading({...loading, brand: false});
  }

  const getBrandDateRange = async () => {
    setLoading({...loading, brandDate: true});
    const init = rangeDate.initDate;
    const end = rangeDate.endDate;

    if(init === "" || end === "") {
      setModal(true);
      setMsg('Debe indicar ambas fechas');
    }

    const jsn = {
      init: init,
      end: end,
      type: type.brand_range,
      code: "BRAND_RANGE"
    }

    const res = await getMoreBrandPerDateData(jsn);

    const data = res.data;
    const valuesText = data.map( (v) => {
      return v.BrandCode
    });
    const valuesData = data.map( (v) => {
      return v.Total
    });

    var colors = [];
    
    for(var i of data) {
      colors.push(dynamicColors());
    }

    setArrBrandDate({
      legend: data,
      labels: valuesText, 
      datasets: [{
      label: 'Marcas',
      backgroundColor: colors,
      data: valuesData,
      type: 'bar'
    }]});

    setLoading({...loading, brandDate: false});
  }

  const getMoreSellerPerYear = async () => {
    setLoading({...loading, seller: true});
    
    let _month = new Date().getMonth()+1;
    let _year = new Date().getFullYear();

    if(selYear === 0 && selMonth === 0) {
      _month = new Date().getMonth()+1;
      _year = new Date().getFullYear();
    }
    else {
      _month = selMonthSeller;
      _year = selYearSeller;
    }

    const jsn = {
      month: _month,
      year: _year,
      type: type.seller,
      code: "SELLER"
    }

    const res = await getMoreSellerPerYearData(jsn);
    const data = res.data;
    const valuesText = data.map( (v) => {
      return v.Name
    });
    const valuesData = data.map( (v) => {
      return v.Total
    });

    setArrSeller({
      legend: data,
      labels: valuesText, 
      datasets: [{
      label: 'Vendedores',
      backgroundColor: '#004899',
      data: valuesData
    }]});

    setSelMonthSeller(_month);
    setSelYearSeller(_year);
    setLoading({...loading, seller: false});
  }

  const getClientStats = async () => {
    const jsn = {
      type: type.seller,
      code: "CLIENT"
    }

    setLoading({...loading, clients: true});
    const res = await getClientStatsData(jsn);
    setLastClients(res.data.last);
    setTopClients(res.data.top);

    setLoading({...loading, clients: false});
  }

  const getProfitPerYear = async () => {
    setLoading({...loading, profit: true});

    const _year = (selYearProfit === 0 || selYearProfit === "0") ? new Date().getFullYear() : selYearProfit;

    const jsn = {
      year: _year,
      type: type.profit,
      code: "PROFIT"
    }

    const res = await getProfitPerYearData(jsn);
    const data = res.data;

    const valuesText = data.map( (v) => {
      return arrMonth[v.month-1].name;
    });
    const valuesData = data.map( (v) => {
      return v.total;
    });

    setArrProfit({
      legend: data,
      labels: valuesText, 
      datasets: [{
        label: 'Ganancias',
        backgroundColor: '#004899',
        data: valuesData,
        fill: false,
        type: 'line'
      }]
    });

    setSelYearProfit(_year);
    setLoading({...loading, profit: false});
  }

  const getUserList = async () => {
    setLoading({...loading, list: true});

    const res = await getUserListData();
    setUserList(res.data)
    setLoading({...loading, list: false});
  }

  const getDateLast = async () => {
    let _month = new Date().getMonth()+1;
    let _year = new Date().getFullYear();

    if(selYear === 0 && selMonth === 0) {
      _month = new Date().getMonth()+1;
      _year = new Date().getFullYear();
    }
    else {
      _month = selMonthSeller;
      _year = selYearSeller;
    }

    setSelMonthPieces(_month);
    setSelYearSellerPieces(_year);
  }
  
  const getUserPieceStats = async (id) => {
    setLoading({...loading, pieces: true});

    const jsn = {
      id: id,
      month: selMonthPieces,
      year: selYearSellerPieces,
      type: type.pieces,
      code: "PIECES"
    }

    const res = await getUserPieceStatData(jsn);
    const data = res.data;

    const valuesText = data.map( (v) => {
      return v.BrandCode;
    });
    const valuesData = data.map( (v) => {
      return v.Total;
    });
    
    var colors = [];
    
    for(var i of data) {
      colors.push(dynamicColors());
    }

    var total = 0;
    var pieces = 0;

    for(var v of data) {
      total += v.Amount;
      pieces += v.Total;
    }

    setTopPieces({
      legend: data,
      labels: valuesText, 
      datasets: [{
        label: 'Ganancias',
        backgroundColor: colors,
        data: valuesData,
        type: 'doughnut'
      }]
    });

    setGData(true);
    setFullTotal(total.toString());
    setTotalPiece(pieces);
    setLoading({...loading, pieces: false});
  }

  const getProfitPerYearYear = async () => {
    setLoading({...loading, pieces_year: true});

    const _year = (selYearProfitYear === 0 || selYearProfitYear === "0") ? new Date().getFullYear() : selYearProfitYear;

    const jsn = {
      year: _year,
      type: type.pieces_year,
      code: "PIECES_YEAR"
    }

    const res = await getProfitPerYearData(jsn);
    const data = res.data;

    const valuesText = data.map( (v) => {
      return arrMonth[v.month-1].name;
    });
    const valuesData = data.map( (v) => {
      return v.total;
    });

    setArrProfitYear({
      legend: data,
      labels: valuesText, 
      datasets: [{
        label: 'Ganancias',
        backgroundColor: '#004899',
        data: valuesData,
        fill: false,
        type: 'line'
      }]
    });

    setSelYearProfitYear(_year);
    setLoading({...loading, pieces_year: false});
  }

  const getBrandYear = async () => {
    setLoading({...loading, pieces_year: true});

    const _year = (selBrandYear === 0 || selBrandYear === "0") ? new Date().getFullYear() : selBrandYear;

    const jsn = {
      year: _year,
      type: type.brand_year,
      code: "BRAND_YEAR"
    }

    const res = await getProfitPerYearData(jsn);
    const data = res.data;

    const valuesText = data.map( (v) => {
      return arrMonth[v.month-1].name;
    });
    const valuesData = data.map( (v) => {
      return v.total;
    });

    var colors = [];
    var pieces = 0;

    for(var i of data) {
      colors.push(dynamicColors());
      pieces += i.Total;
    }

    setArrBrandYear({
      legend: data,
      labels: valuesText, 
      datasets: [{
      label: 'Marcas',
      backgroundColor: colors,
      data: valuesData,
      type: 'bar'
    }]});

    setSelBrandYear(_year);
    setLoading({...loading, pieces_year: false});
  }

  const getSelleProfitMonthYear = async () => {
    setLoading({...loading, seller_profit: true});
    
    let _month = new Date().getMonth()+1;
    let _year = new Date().getFullYear();

    if(selYear === 0 && selMonth === 0) {
      _month = new Date().getMonth()+1;
      _year = new Date().getFullYear();
    }
    else {
      _month = selMonthSellerProfit;
      _year = selYearSellerProfit;
    }

    const jsn = {
      month: _month,
      year: _year,
      type: type.seller,
      code: "SELLER_PROFIT"
    }

    const res = await getMoreSellerPerYearData(jsn);
    const data = res.data;

    console.log(data);


    const valuesText = data.map( (v) => {
      return v.name
    });
    const valuesData = data.map( (v) => {
      return v.total
    });

    setArrSellerProfit({
      legend: data,
      labels: valuesText, 
      datasets: [{
        label: 'Vendedores',
        backgroundColor: '#004899',
        data: valuesData
      }]
    });

    setSelMonthSellerProfit(_month);
    setSelYearSellerProfit(_year);
    setLoading({...loading, seller_profit: false});
  }

  const sumTotal = () => {
    var total = 0;

    const dataToSum = arrProfitYear.datasets[0].data;

    for(var v of dataToSum) {
      total += v;
    }

    return total;
  }

  const getSumSellerProfit = () => {
    var total = 0;
    const dataToSum = arrSellerProfit.datasets[0].data;

    for(var v of dataToSum) {
      total += v;
    }

    return total;
  }
  
  const getSumProfit = () => {
    var total = 0;
    const dataToSum = arrProfit.datasets[0].data;

    for(var v of dataToSum) {
      total += v;
    }

    return total;
  }

  const dynamicColors = () => {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
 };

  const NumberFormat = (num) => {
    const options = { style: 'currency', currency: 'USD' };
    const numberFormat = new Intl.NumberFormat('en-US', options);
    return numberFormat.format(num);
  }
  
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Graficas </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <Row>
                  {
                    (loading.general) ? <div className="text-center">
                      <Spinner style={{color: '#004899'}} />
                    </div> : <>
                      <Col className="mb-5 mb-xl-0">
                        <Card className="bg-gradient-default shadow">
                          <CardHeader className="bg-transparent border-0">
                            <Row className="align-items-center">
                              <div className="col">
                                <h3 className="mb-0"
                                style={{
                                  color: '#fff',
                                  textAlign: 'center',
                                  fontSize: '20px'
                                }}> Ventas pedidos </h3>
                              </div>
                            </Row>
                          </CardHeader>
                          <CardBody style={{
                            backgroundColor: '#fff',
                            textAlign: 'center',
                            fontSize: '20px'
                          }}>
                            <h2> {generalStats.web} </h2>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col className="mb-5 mb-xl-0">
                        <Card className="bg-gradient-default shadow">
                          <CardHeader className="bg-transparent border-0">
                            <Row className="align-items-center">
                              <div className="col">
                                <h3 className="mb-0"
                                style={{
                                  color: '#fff',
                                  textAlign: 'center',
                                  fontSize: '20px'
                                }}> Ventas transito </h3>
                              </div>
                            </Row>
                          </CardHeader>
                          <CardBody style={{
                            backgroundColor: '#fff',
                            textAlign: 'center'
                          }}>
                            <h2> {generalStats.transit} </h2>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col className="mb-5 mb-xl-0">
                        <Card className="bg-gradient-default shadow">
                          <CardHeader className="bg-transparent border-0">
                            <Row className="align-items-center">
                              <div className="col">
                                <h3 className="mb-0" 
                                style={{
                                  color: '#fff',
                                  textAlign: 'center',
                                  fontSize: '20px'
                                }}> Total Vendido </h3>
                              </div>
                            </Row>
                          </CardHeader>
                          <CardBody style={{
                            backgroundColor: '#fff',
                            textAlign: 'center'
                          }}>
                            <h2> ${new Intl.NumberFormat('en-US').format(generalStats.total_sold)} </h2>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col className="mb-5 mb-xl-0">
                        <Card className="bg-gradient-default shadow">
                          <CardHeader className="bg-transparent border-0">
                            <Row className="align-items-center">
                              <div className="col">
                                <h3 className="mb-0" 
                                style={{
                                  color: '#fff',
                                  textAlign: 'center',
                                  fontSize: '20px'
                                }}> Ganancias Neta </h3>
                              </div>
                            </Row>
                          </CardHeader>
                          <CardBody style={{
                            backgroundColor: '#fff',
                            textAlign: 'center'
                          }}>
                            <h2> ${new Intl.NumberFormat('en-US').format(generalStats.gross_value)} </h2>
                          </CardBody>
                        </Card>
                      </Col>
                    </>
                  }
                </Row>
                
                <Row>
                  <div style={{
                    marginTop: '40px',
                    width: '100%'
                  }}>
                     {
                      (loading.brand) ? <div className="text-center">
                        <Spinner style={{color: '#004ff899'}} />
                      </div> : <>
                        <div>
                          <ul style={{
                            padding: '0px',
                            listStyle: 'none',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                            <li>
                              <h3>Marcas por fecha</h3>
                            </li>
                            <li style={{
                              display: 'flex',
                            }}>
                              <select 
                                className="form-control" 
                                style={{appearance: 'auto', width: '150px'}}
                                value={type.brand}
                                onChange={val => setType({...type, brand: val.target.value})}>
                                <option value="W"> Pedidos Web </option>
                                <option value="T"> Transito </option>
                              </select>
                              <select 
                                className="form-control" 
                                  style={{appearance: 'auto', marginLeft: '5px',}}
                                value={selMonth}
                                onChange={val => setSelMonth(val.target.value)}>
                                <option value=""> Mes </option>
                                {
                                  arrMonth.map( (m, i) => ( 
                                    <option key={i} value={m.value}> {m.name}</option>
                                  ))
                                }
                              </select>
                              <select
                                className="form-control" 
                                style={{
                                  marginLeft: '5px',
                                  appearance: 'auto'
                                }}
                                value={selYear}
                                onChange={val => setSelYear(val.target.value)}>
                                <option value=""> Año </option>
                                {
                                  arrDate.map( (d, i) => ( 
                                    <option key={i} value={d.dates}> {d.dates}</option>
                                  ))
                                }
                              </select>
                              <Button
                                size='sm'
                                style={{
                                  backgroundColor: '#004899',
                                  color: '#fff',
                                  border: 0,
                                  marginLeft: '5px'
                                }}
                                onClick={() => getMoreBrandPerYear()}
                                > Actualizar
                              </Button>
                            </li>
                          </ul>
                        </div>
                        <Row>
                          <Col md="9">
                            <Bar
                              data={arrBrand}
                              options= {{maintainAspectRatio: false}}
                            />
                          </Col>
                          <Col md="3">
                            <ul style={{
                              padding: '0px',
                              listStyle: 'none'
                            }}>
                              {
                                arrBrand.legend.map( (v, i) => (
                                  <li key={i}> 
                                    <b>{v.BrandCode}:</b> {v.BrandName} - {v.Total}
                                  </li>
                                ))
                              }
                              <hr />
                              <li>
                                <b>MARCAS:</b> <span>{brandByPieces.brand}</span>
                              </li>
                              <li>
                                <b>TOTAL DE PIEZAS:</b> <span>{brandByPieces.total}</span>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </>
                    }
                  </div>
                </Row>

                <Row>
                  <div style={{
                    marginTop: '40px',
                    width: '100%'
                  }}>
                     {
                      (loading.brandDate) ? <div className="text-center">
                        <Spinner style={{color: '#004899'}} />
                      </div> : <>
                        <div>
                          <ul style={{
                            padding: '0px',
                            listStyle: 'none',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                            <li>
                              <h3> Marcas por rango de fechas </h3>
                            </li>
                            <li style={{
                              display: 'flex',
                            }}>
                              <select 
                                className="form-control" 
                                style={{appearance: 'auto', width: '150px'}}
                                value={type.brand_range}
                                onChange={val => setType({...type, brand_range: val.target.value})}>
                                <option value="W"> Pedidos Web </option>
                                <option value="T"> Transito </option>
                              </select>
                              <input 
                                type="date" 
                                className="form-control"
                                value={rangeDate.initDate}
                                onChange={(val) => setRangeDate({...rangeDate, initDate:val.target.value})} />
                              <input 
                                type="date" 
                                className="form-control"
                                value={rangeDate.endDate}
                                onChange={(val) => setRangeDate({...rangeDate, endDate:val.target.value})} />
                              <Button
                                size='sm'
                                style={{
                                  backgroundColor: '#004899',
                                  color: '#fff',
                                  border: 0,
                                  marginLeft: '5px'
                                }}
                                onClick={() => getBrandDateRange()}
                                > Actualizar
                              </Button>
                            </li>
                          </ul>
                        </div>
                        <Row>
                          <Col md="9">
                            <Bar
                              data={arrBrandDate}
                              options= {{maintainAspectRatio: false}}
                            />
                          </Col>
                          <Col md="3">
                              <ul style={{
                                padding: '0px',
                                listStyle: 'none'
                              }}>
                                {
                                  arrBrandDate.legend.map( (v, i) => (
                                    <li key={i}> 
                                      <b>{v.BrandCode}:</b> {v.BrandName} - {v.Total}
                                    </li>
                                  ))
                                }
                              </ul>
                            </Col>
                        </Row>
                      </>
                    }
                  </div>
                </Row>

                <Row>
                  <div style={{
                    marginTop: '40px',
                    width: '100%'
                  }}>
                     {
                      (loading.seller) ? <div className="text-center">
                        <Spinner style={{color: '#004899'}} />
                      </div> : <>
                        <div>
                          <ul style={{
                            padding: '0px',
                            listStyle: 'none',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                            <li>
                              <h3> Vendedor por fecha </h3>
                            </li>
                            <li style={{
                              display: 'flex',
                            }}>
                              <select 
                                className="form-control" 
                                style={{appearance: 'auto', width: '150px', marginRight: '5px'}}
                                value={type.seller}
                                onChange={val => setType({...type, seller: val.target.value})}>
                                <option value="W"> Pedidos Web </option>
                                <option value="T"> Transito </option>
                              </select>

                              <select 
                                className="form-control" 
                                style={{appearance: 'auto'}}
                                value={selMonthSeller}
                                onChange={val => setSelMonthSeller(val.target.value)}>
                                <option value=""> Mes </option>
                                {
                                  arrMonth.map( (m, i) => ( 
                                    <option key={i} value={m.value}> {m.name}</option>
                                  ))
                                }
                              </select>
                              <select
                                className="form-control" 
                                style={{
                                  marginLeft: '5px',
                                  appearance: 'auto'
                                }}
                                value={selYearSeller}
                                onChange={val => setSelYearSeller(val.target.value)}>
                                <option value=""> Año </option>
                                {
                                  arrDate.map( (d, i) => ( 
                                    <option key={i} value={d.dates}> {d.dates}</option>
                                  ))
                                }
                              </select>
                              <Button
                                size='sm'
                                style={{
                                  backgroundColor: '#004899',
                                  color: '#fff',
                                  border: 0,
                                  marginLeft: '5px'
                                }}
                                onClick={() => getMoreSellerPerYear()}
                                > Actualizar
                              </Button>
                            </li>
                          </ul>
                        </div>
                        <Row>
                          <Col md="10">
                            <Bar
                              data={arrSeller}
                              options= {{maintainAspectRatio: false}}
                            />
                          </Col>
                          <Col md="2">
                            <ul style={{
                              padding: '0px',
                              listStyle: 'none'
                            }}>
                              {
                                arrSeller.legend.map( (v, i) => (
                                  <li key={i}> 
                                    <b>{v.Name}:</b> {v.Total}
                                  </li>
                                ))
                              }
                            </ul>
                          </Col>
                        </Row>
                      </>
                     }
                  </div>
                </Row>
                
                <div style={{
                  marginTop: '30px'
                }}>
                  {
                    (loading.clients) ? <div className="text-center">
                      <Spinner style={{color: '#004899'}} />
                    </div> : <>
                      <Row>
                        <h3>Clientes</h3>
                        <Col md="12">
                        <ul style={{
                            padding: '0px',
                            listStyle: 'none',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                            <li>
                              <h3> Ultimas 5 compras </h3>
                            </li>
                            <li style={{
                              display: 'flex',
                            }}>
                              <select 
                                className="form-control" 
                                style={{appearance: 'auto', width: '150px', marginRight: '5px'}}
                                value={type.client}
                                onChange={val => setType({...type, client: val.target.value})}>
                                <option value="W"> Pedidos Web </option>
                                <option value="T"> Transito </option>
                              </select>
                              <Button
                                size='sm'
                                style={{
                                  backgroundColor: '#004899',
                                  color: '#fff',
                                  border: 0,
                                  marginLeft: '5px'
                                }}
                                onClick={() => getClientStats()}
                                > Actualizar
                              </Button>
                            </li>
                          </ul>
                          <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th scope="col" />
                                <th scope="col"> Nombre </th>
                                <th scope="col"> Contacto </th>
                                <th scope="col"> Estatus </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                lastClients.map( (d,i) => (
                                  <tr key={i}>
                                    <td>
                                      {++i}
                                    </td>
                                    <td>
                                      {String(d.name).trim()}
                                    </td>
                                    <td>
                                      {String(d.contact).trim()}
                                    </td>
                                    <td>
                                      {d.status}
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </Col>
                        <Col md="12" style={{marginTop: '30px'}}>
                          <h4>Top clientes</h4>
                          <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th scope="col" />
                                <th scope="col"> Nombre </th>
                                <th scope="col"> Contacto </th>
                                <th scope="col"> Total </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                topClients.map( (d,i) => (
                                  <tr key={i}>
                                    <td>
                                      {++i}
                                    </td>
                                    <td>
                                      {String(d.name).trim()}
                                    </td>
                                    <td>
                                      {String(d.contact).trim()}
                                    </td>
                                    <td>
                                      {d.total}
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  }
                </div>
                
                <Row>
                  <div style={{
                    marginTop: '40px',
                    width: '100%'
                  }}>
                     {
                      (loading.profit) ? <div className="text-center">
                        <Spinner style={{color: '#004899'}} />
                      </div> : <>
                        <div>
                          <ul style={{
                            padding: '0px',
                            listStyle: 'none',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                            <li>
                              <h3>Facturacion</h3>
                            </li>
                            <li style={{
                              display: 'flex',
                            }}>
                              <select 
                                className="form-control" 
                                style={{appearance: 'auto', width: '150px', marginRight: '5px'}}
                                value={type.profit}
                                onChange={val => setType({...type, profit: val.target.value})}>
                                <option value="W"> Pedidos Web </option>
                                <option value="T"> Transito </option>
                              </select>

                              <select
                                className="form-control" 
                                style={{
                                  marginLeft: '5px',
                                  appearance: 'auto'
                                }}
                                value={selYearProfit}
                                onChange={val => setSelYearProfit(val.target.value)}>
                                <option value=""> Año </option>
                                {
                                  arrDate.map( (d, i) => ( 
                                    <option key={i} value={d.dates}> {d.dates}</option>
                                  ))
                                }
                              </select>
                              <Button
                                style={{
                                  backgroundColor: '#004899',
                                  color: '#fff',
                                  border: 0,
                                  marginLeft: '5px'
                                }}
                                onClick={() => getProfitPerYear()}
                                > Actualizar
                              </Button>
                            </li>
                          </ul>
                        </div>
                        <Row>
                          <Col md="9">
                            <Bar
                              data={arrProfit}
                              options= {{maintainAspectRatio: false}}
                            />
                          </Col>
                          <Col md="3">
                            <ul style={{
                              padding: '0px',
                              listStyle: 'none'
                            }}>
                              {
                                arrProfit.legend.map( (v, i) => (
                                  <li key={i}> 
                                    <b>{arrMonth[v.month-1].name}:</b> {NumberFormat(v.total)}
                                  </li>
                                ))
                              }
                              <li>
                                <br />
                                <b> Total: </b> {NumberFormat(getSumProfit())}
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </>
                    }
                  </div>
                </Row>
                
                <Row>
                  <div style={{
                    marginTop: '40px',
                    width: '100%'
                  }}>
                     {
                      (loading.seller_profit) ? <div className="text-center">
                        <Spinner style={{color: '#004ff899'}} />
                      </div> : <>
                        <div>
                          <ul style={{
                            padding: '0px',
                            listStyle: 'none',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                            <li>
                              <h3> Total de facturacion por vendedores </h3>
                            </li>
                            <li style={{
                              display: 'flex',
                            }}>
                              <select 
                                className="form-control" 
                                style={{appearance: 'auto', width: '150px', marginRight: '5px'}}
                                value={type.seller_profit}
                                onChange={val => setType({...type, seller_profit: val.target.value})}>
                                <option value="W"> Pedidos Web </option>
                                <option value="T"> Transito </option>
                              </select>

                              <select 
                                className="form-control" 
                                style={{appearance: 'auto'}}
                                value={selMonthSellerProfit}
                                onChange={val => setSelMonthSellerProfit(val.target.value)}>
                                <option value=""> Mes </option>
                                {
                                  arrMonth.map( (m, i) => ( 
                                    <option key={i} value={m.value}> {m.name}</option>
                                  ))
                                }
                              </select>
                              <select
                                className="form-control" 
                                style={{
                                  marginLeft: '5px',
                                  appearance: 'auto'
                                }}
                                value={selYearSellerProfit}
                                onChange={val => setSelYearSellerProfit(val.target.value)}>
                                <option value=""> Año </option>
                                {
                                  arrDate.map( (d, i) => ( 
                                    <option key={i} value={d.dates}> {d.dates}</option>
                                  ))
                                }
                              </select>

                              <Button
                                size='sm'
                                style={{
                                  backgroundColor: '#004899',
                                  color: '#fff',
                                  border: 0,
                                  marginLeft: '5px'
                                }}
                                onClick={() => getSelleProfitMonthYear()}
                                > Actualizar
                              </Button>
                            </li>
                          </ul>
                        </div>
                        <Row>
                          <Col md="9">
                            <Bar
                              data={arrSellerProfit}
                              options= {{maintainAspectRatio: false}}
                            />
                          </Col>
                          <Col md="3">
                            <ul style={{
                              padding: '0px',
                              listStyle: 'none'
                            }}>
                              {
                                arrSellerProfit.legend.map( (v, i) => (
                                  <li key={i}> 
                                    <b>{v.name}:</b> {NumberFormat(v.total)}
                                  </li>
                                ))
                              }
                              <li>
                                <br />
                                <b> Total: </b> {NumberFormat(getSumSellerProfit())}
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </>
                    }
                  </div>
                </Row>

                <Row>
                  <div style={{
                    marginTop: '40px',
                    width: '100%'
                  }}>
                     {
                      (loading.pieces_year) ? <div className="text-center">
                        <Spinner style={{color: '#004899'}} />
                      </div> : <>
                        <div>
                          <ul style={{
                            padding: '0px',
                            listStyle: 'none',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                            <li>
                              <h3> Facturacion de piezas por año </h3>
                            </li>
                            <li style={{
                              display: 'flex',
                            }}>
                              <select 
                                className="form-control" 
                                style={{appearance: 'auto', width: '150px', marginRight: '5px'}}
                                value={type.pieces_year}
                                onChange={val => setType({...type, pieces_year: val.target.value})}>
                                <option value="W"> Pedidos Web </option>
                                <option value="T"> Transito </option>
                              </select>

                              <select
                                className="form-control" 
                                style={{
                                  marginLeft: '5px',
                                  appearance: 'auto'
                                }}
                                value={selYearProfitYear}
                                onChange={val => setSelYearProfitYear(val.target.value)}>
                                <option value=""> Año </option>
                                {
                                  arrDate.map( (d, i) => ( 
                                    <option key={i} value={d.dates}> {d.dates}</option>
                                  ))
                                }
                              </select>
                              <Button
                                style={{
                                  backgroundColor: '#004899',
                                  color: '#fff',
                                  border: 0,
                                  marginLeft: '5px'
                                }}
                                onClick={() => getProfitPerYearYear()}
                                > Actualizar
                              </Button>
                            </li>
                          </ul>
                        </div>
                        <Row>
                          <Col md="10">
                            <Bar
                              data={arrProfitYear}
                              options= {{maintainAspectRatio: false}}
                            />
                          </Col>
                          <Col md="2">
                            <ul style={{
                              padding: '0px',
                              listStyle: 'none'
                            }}>
                              {
                                arrProfitYear.legend.map( (v, i) => (
                                  <li key={i}> 
                                    <b>{arrMonth[v.month-1].name}:</b> {v.total}
                                  </li>
                                ))
                              }
                              <li> <br /> <b> Total: </b> {sumTotal()} </li>
                            </ul>
                          </Col>
                        </Row>
                      </>
                    }
                  </div>
                </Row>

                <Row>
                  <div style={{
                    marginTop: '40px',
                    width: '100%'
                  }}>
                     {
                      (loading.brand_year) ? <div className="text-center">
                        <Spinner style={{color: '#004ff899'}} />
                      </div> : <>
                        <div>
                          <ul style={{
                            padding: '0px',
                            listStyle: 'none',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                            <li>
                              <h3> Marcas por año </h3>
                            </li>
                            <li style={{
                              display: 'flex',
                            }}>
                              <select 
                                className="form-control" 
                                style={{appearance: 'auto', width: '150px'}}
                                value={type.brand_year}
                                onChange={val => setType({...type, brand_year: val.target.value})}>
                                <option value="W"> Pedidos Web </option>
                                <option value="T"> Transito </option>
                              </select>
                              <select
                                className="form-control" 
                                style={{
                                  marginLeft: '5px',
                                  appearance: 'auto'
                                }}
                                value={selBrandYear}
                                onChange={val => setSelBrandYear(val.target.value)}>
                                <option value=""> Año </option>
                                {
                                  arrDate.map( (d, i) => ( 
                                    <option key={i} value={d.dates}> {d.dates}</option>
                                  ))
                                }
                              </select>
                              <Button
                                size='sm'
                                style={{
                                  backgroundColor: '#004899',
                                  color: '#fff',
                                  border: 0,
                                  marginLeft: '5px'
                                }}
                                onClick={() => getBrandYear()}
                                > Actualizar
                              </Button>
                            </li>
                          </ul>
                        </div>
                        <Row>
                          <Col md="10">
                            <Bar
                              data={arrBrandYear}
                              options= {{maintainAspectRatio: false}}
                            />
                          </Col>
                          <Col md="2">
                            <ul style={{
                              padding: '0px',
                              listStyle: 'none'
                            }}>
                              {
                                arrBrandYear.legend.map( (v, i) => (
                                  <li key={i}> 
                                    <b>{arrMonth[v.month-1].name}:</b> {v.total}
                                  </li>
                                ))
                              }
                            </ul>
                          </Col>
                        </Row>
                      </>
                    }
                  </div>
                </Row>

                <Row>
                  <div style={{
                    marginTop: '40px',
                    width: '100%'
                  }}>
                    {
                      (loading.list) ? <div className="text-center">
                        <Spinner style={{color: '#004899'}} />
                      </div> : <>
                        <ul style={{
                          padding: '0px',
                          listStyle: 'none',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                          <li>
                            <h3>Piezas mas vendidas por vendedor</h3>
                          </li>
                          <li style={{
                            display: 'flex',
                          }}>
                            <select 
                              className="form-control" 
                              style={{appearance: 'auto', width: '150px', marginRight: '5px'}}
                              value={type.pieces}
                              onChange={val => setType({...type, pieces: val.target.value})}>
                              <option value="W"> Pedidos Web </option>
                              <option value="T"> Transito </option>
                            </select>
                            <select 
                              className="form-control" 
                              style={{appearance: 'auto'}}
                              value={selMonthPieces}
                              onChange={val => setSelMonthPieces(val.target.value)}>
                              <option value=""> Mes </option>
                              {
                                arrMonth.map( (m, i) => ( 
                                  <option key={i} value={m.value}> {m.name}</option>
                                ))
                              }
                            </select>
                            <select
                              className="form-control" 
                              style={{
                                marginLeft: '5px',
                                appearance: 'auto'
                              }}
                              value={selYearSellerPieces}
                              onChange={val => setSelYearSellerPieces(val.target.value)}>
                              <option value=""> Año </option>
                              {
                                arrDate.map( (d, i) => ( 
                                  <option key={i} value={d.dates}> {d.dates}</option>
                                ))
                              }
                            </select>
                          </li>
                        </ul>
                        <Row>
                          <Col md="4">
                            <Table className="align-items-center table-flush" responsive>
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col" />
                                  <th scope="col"> Nombre </th>
                                  <th scope="col" />
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  userList.map( (d,i) => (
                                    <tr key={i}>
                                      <td>
                                        {++i}
                                      </td>
                                      <td>
                                        {String(d.name).trim()}
                                      </td>
                                      <td>
                                        <Button onClick={ () => getUserPieceStats(d.id)}>Ver</Button>
                                      </td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </Table>
                          </Col>
                          {
                            (loading.pieces) ? <Col md="8">
                              <div className="text-center">
                                <Spinner style={{color: '#004899'}} />
                              </div>
                            </Col> : <>
                              <Col md="5">
                                {
                                (gData) ? 
                                  (arrTopPieces.datasets[0].data.length === 0) ? 
                                    <label>No hay información</label>: 
                                  <Doughnut 
                                    data={arrTopPieces}
                                    options= {{maintainAspectRatio: false}}/> : 
                                  <label> Seleccionar para graficar</label> 
                                }
                              </Col>
                              <Col md="3">
                                <ul style={{
                                  padding: '0px',
                                  listStyle: 'none'
                                }}>
                                  {
                                    arrTopPieces.legend.map( (v, i) => (
                                      <li key={i}> 
                                        <b>{v.BrandCode}:</b> {v.BrandName} ({v.Total}) <b>${v.Amount}</b>
                                      </li>
                                    ))
                                  }
                                  {
                                    (fullTotal === "") ? <> </> :
                                      (fullTotal === "0") ? <> </> :
                                    <>
                                      <li>
                                        <hr />
                                      </li>
                                      <li>
                                        <b>VENTAS:</b> <span>{NumberFormat(fullTotal)}</span>
                                      </li>
                                      <li>
                                        <b>TOTAL DE PIEZAS:</b> <span>{totalPiece}</span>
                                      </li>
                                    </>
                                  }
                                </ul>
                              </Col>
                            </>
                          }
                        </Row>
                      </>
                    }
                  </div>
                </Row>
              </CardBody>
              
            </Card>
          </div>
        </Row>
        
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={() => setModal()}> Mensaje </ModalHeader>
          <ModalBody>
            {msg}	
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal()}> OK </Button>
          </ModalFooter>
        </Modal>

      </Container>
    </>
  )
}
