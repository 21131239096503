/* eslint-disable no-useless-escape */
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Compressor from 'compressorjs';
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../components/Headers/Header";
import { setNewUsersData, getAllRolData } from "../../api/users";
import NotificationAlert from "react-notification-alert";

const NewUsers = () => {
  const notificationAlertRef = React.useRef(null);
  const [picture, setPicture] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [rol, setRol] = useState('');
  const [rolData, setRolData] = useState([]);
  const [active, setActive] = useState('0');
  const [sellerProfile, setSellerProfile] = useState('0');
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllRol();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				setPicture(e.target.result);
			};

			reader.readAsDataURL(event.target.files[0]);
			setFile(event.target.files[0]);
		}
	}

	const renderImageToSend = async (f) => {
		return new Compressor(f, {quality: 0.6});
	}

  const getAllRol = async () => {
    const res = await getAllRolData();
    setRolData(res.data);
  }

  const addNewUsers = async () => {
    setLoading(true);
    let fileCompress = null;
    
    const _name = name.trim();
    const _email = email.trim();

    if(_name === "") {
      notifyAlert('danger', 'Error', 'Debe ingresar el nombre del usuario');
    }
    else if(_email === "") {
      notifyAlert('danger', 'Error', 'Debe ingresar el correo del usuario');
    }
    else if(!/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/.test(_email)) {
      notifyAlert('danger', 'Error', 'Debe ingresar un correo valido');
    }
    else if(rol === "") {
      notifyAlert('danger', 'Error', 'Debe seleccionar el rol del usuario');
    }
    else if (Number(rol) === 3 && Number(sellerProfile) === 0 ) {
      notifyAlert('danger', 'Error', 'Debe seleccionar el perfil del vendedor');
    }
    else {
      fileCompress = (picture === '') ? null : await renderImageToSend(file);
      const res = await setNewUsersData(_name, _email, phone, rol, active, code, fileCompress, sellerProfile);

      if(res.success === true) {
        notifyAlert('success', 'Exito', 'Usuario creado correctamente');
        setPicture('');
        setName('');
        setEmail('');
        setPhone('');
        setRol('');
        setCode('');
        setActive('0');
        setSellerProfile('0');
        setFile({});
      }
      else {
        notifyAlert('danger', 'Error', "Error al crear el usuario");
      }
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Usuarios </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <label className="form-control-label"> Imagen </label>
                      <div 
                        id="dvImage"
                        style={{
                          width: '100%',
                          height: '150px',
                          marginBottom: '10px',
                          borderRadius: '.25rem',
                          border: '1px solid #ced4da'
                        }}>
                          <img 
                            style={{
                              width: '100%',
                              height: '148px',
                              objectFit: 'scale-down',
                            }}
                            alt="Cargar imagen"
                            src={picture} />
                      </div>

                      <div className="float-right">
                        <label className="custom-file-upload">
                          <input 
                            type="file"
                            accept="image/*"
                            onChange={onImageChange}/>
                          Cargar imagen
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Nombre </label>
                        <Input
                          value={name}
                          className="form-control"
                          onChange={val => setName(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Email </label>
                        <Input
                          value={email}
                          className="form-control"
                          onChange={val => setEmail(val.target.value)}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Telefono </label>
                        <Input
                          value={phone}
                          className="form-control"
                          onChange={val => setPhone(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Codigo (este es el codigo del usuario en premium) </label>
                        <Input
                          value={code}
                          className="form-control"
                          onChange={val => setCode(val.target.value)}/>
                      </FormGroup>
                    </Col>
                    
                    
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Rol </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={rol}
                          onChange={val => setRol(val.target.value)}
                        >
                          <option value=''>Seleccionar</option>
                          {
                            rolData.map( (r, i) => (
                              <option key={i} value={r.id}>{r.name}</option>
                            ))
                          }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label">Perfil de ventas</label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={sellerProfile}
                          onChange={val => setSellerProfile(val.target.value)}
                        >
                          <option value="1"> Si </option>
                          <option value="0"> No </option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Activar </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={active}
                          onChange={val => setActive(val.target.value)}
                        >
                          <option value="0"> Si </option>
                          <option value="1"> No </option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading) ? <Spinner style={{color: '#004899'}}/> :
                          <div>
                            <Button color="gray" to="/admin/users" tag={Link}> Regresar </Button>
                            <Button
                              style={{
                                backgroundColor: '#004899',
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => addNewUsers()}
                              > Guardar
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
 
          </Col>
        </Row>
      </Container>

    </>
  )
}

export default NewUsers;