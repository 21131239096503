import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Collapse, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Navbar, NavItem, Nav, Container } from "reactstrap";
import { AuthContext } from "./../../context/AuthContext";
import lang from "./../../lang/lang";
import { getColor } from "utils/token";
const noPicture = require('assets/img/noPicture.png').default;


function AdminNavbar({name, picture, toggleSidenav }) {
  const {signOut} = React.useContext(AuthContext);
//border-bottom
  return (
    <>
      <Navbar 
        style={{
          backgroundColor: getColor(1)
        }}
        className={classnames("navbar-top navbar-expand navbar-dark")}>
        <Container fluid>
          <Collapse navbar isOpen={true}>

            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames("pr-3 sidenav-toggler sidenav-toggler-dark")}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
            </Nav>

            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center" style={{cursor: 'pointer'}}>
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={(picture === null) ? noPicture : picture}/>
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Menu</h6>
                  </DropdownItem>

                  <DropdownItem to="/admin/profile" tag={Link}>
                    <i className="ni ni-palette" />
                    <span>{lang('PROFILE')}</span>
                  </DropdownItem>

                  <DropdownItem divider />

                  <DropdownItem onClick={() => signOut()}>
                    <i className="fas fa-sign-out-alt" />
                    <span>{lang('LOGOUT')}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: true,
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
};

export default AdminNavbar;
