import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function getAllDatesData() {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.stats}/dates`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getGeneralStasData() {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(CONS.stats, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getMoreBrandPerYearData(jsn) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.post(`${CONS.stats}/graph` , jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getMoreBrandPerDateData(jsn) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.post(`${CONS.stats}/graph`, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getMoreSellerPerYearData(jsn) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.post(`${CONS.stats}/graph`, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getClientStatsData(jsn) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.post(`${CONS.stats}/graph`, jsn,{
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getProfitPerYearData(jsn) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.post(`${CONS.stats}/graph`, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getUserListData() {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.get(`${CONS.stats}/user`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getUserPieceStatData(jsn) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.post(`${CONS.stats}/graph`, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}
