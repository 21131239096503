/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getClientByIdData } from "../../../api/database";

const ViewClient = () => {
  const {id} = useParams();
  const [client, setClient] = useState({})
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect( ()=> {
    getClientById();
  }, []) 

  const getClientById = async () => {
    const res = await getClientByIdData(id);
    console.log(res.data);
    if(res.success === true) {
      setError(false);
      setClient(res.data);
    }
    else {
      setError(true);
      setErrorMsg(res.error.message)
    }
    setLoading(false);
  }


  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Clientes </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
              {
                (loading) ?
                <div className="text-center">
                  <Spinner style={{color: '#004899'}} />
                </div>:
                (error) ? 
                <div>
                  <div colSpan={10} className='text-muted text-center'>
                    <label>{errorMsg}</label>
                    <br />
                    <Button color="gray" to="/admin/clients" tag={Link}> Regresar </Button>
                  </div>
                </div>: 
                <div className="pl-lg-4">

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Nombre </label>
                        <Input
                          value={client.name}
                          className="form-control" readOnly={true}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Codigo </label>
                        <Input
                          value={client.code}
                          className="form-control" readOnly={true}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Contacto </label>
                        <Input
                          value={client.contact}
                          className="form-control" readOnly={true}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> E-mail </label>
                        <Input
                          value={client.email}
                          className="form-control" readOnly={true}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Estado </label>
                        <Input
                          value={client.state}
                          className="form-control" readOnly={true}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Ciudad </label>
                        <Input
                          value={client.city}
                          className="form-control" readOnly={true}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Telefono </label>
                        <Input
                          value={client.phone}
                          className="form-control" readOnly={true}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Telefono </label>
                        <Input
                          value={client.otherPhone}
                          className="form-control" readOnly={true}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> Dirección </label>
                        <textarea 
                          value={client.address}
                          className="form-control" readOnly={true}>
                        </textarea>
                      </FormGroup>
                    </Col>
                  </Row>


                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        <Button color="gray" to="/admin/clients" tag={Link}> Regresar </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              }
              </CardBody>
            </Card>
 
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ViewClient;