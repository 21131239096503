/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import Compressor from 'compressorjs';
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getCompanyInfoData, updateCompanyData } from "../../../api/page";
import NotificationAlert from "react-notification-alert";

const Company = () => {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({page: false, btn: false});
  const [picture, setPicture] = useState('');
  const [name, setName] = useState('');
  const [info, setInfo] = useState('');
  const [day, setDay] = useState("");
  const [hour, setHour] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [instagram, setInstagram] = useState("");
  const [file, setFile] = useState({});

  useEffect( ()=> {
    getCompanyInfo();
  }, []) 

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				setPicture(e.target.result);
			};

			reader.readAsDataURL(event.target.files[0]);
			setFile(event.target.files[0]);
		}
	}

	const renderImageToSend = async (f) => {
		return new Compressor(f, {quality: 0.6});
	}

  const getCompanyInfo = async () => {
    const res = await getCompanyInfoData();

    if(res.success === true) {
      const _picture = (res.data.footer === null) ? '' : res.data.footer;
      setPicture(_picture);
      setName(res.data.name);
      setInfo(res.data.info);
      setDay(res.data.day);
      setHour(res.data.hour);
      setEmail(res.data.email);
      setPhone(res.data.phone);
      setInstagram(res.data.instagram);
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al obtener los datos de la marca");
    }
    setLoading({...loading, page: false});
  }

  const updateCompanyInfo = async () => {
    setLoading({...loading, btn: true});
    let fileCompress = null;
 
    if(String(name).trim() === "") {
      notifyAlert("danger", "Error", "El nombre no puede estar vacío");
    }
    else if(String(info).trim() === "") {
      notifyAlert("danger", "Error", "La información no puede estar vacía");
    }
    else if(String(day).trim() === "") {
      notifyAlert("danger", "Error", "El día no puede estar vacío");
    }
    else if(String(hour).trim() === "") {
      notifyAlert("danger", "Error", "La hora no puede estar vacía");
    }
    else if(String(email).trim() === "") {
      notifyAlert("danger", "Error", "El correo no puede estar vacío");
    }
    else if(String(phone).trim() === "") {
      notifyAlert("danger", "Error", "El teléfono no puede estar vacío");
    }
    else if(String(instagram).trim() === "") {
      notifyAlert("danger", "Error", "El instagram no puede estar vacío");
    }
    else {
      fileCompress = (file && Object.keys(file).length === 0 && file.constructor === Object) ? null : await renderImageToSend(file);
   
      const res = await updateCompanyData(name, info, day, hour, email, phone, instagram, fileCompress);

      if(res.success === true) {
        notifyAlert("success", "Éxito", "Se ha actualizado la información exitosamente");
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al actualizar la información");
      }
    }
    setLoading({...loading, btn: false});

  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Compañia </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
              {
                (loading.page) ?
                <div className="text-center">
                  <Spinner style={{color: '#004899'}} />
                </div>: 
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <label className="form-control-label"> Imagen del Footer </label>
                      <div 
                        id="dvImage"
                        style={{
                          width: '100%',
                          height: '150px',
                          marginBottom: '10px',
                          borderRadius: '.25rem',
                          border: '1px solid #ced4da'
                        }}>
                          <img 
                            style={{
                              width: '100%',
                              height: '148px',
                              objectFit: 'scale-down',
                            }}
                            alt="Cargar imagen"
                            src={picture} />
                      </div>

                      <div className="float-right">
                        <label className="custom-file-upload">
                          <input 
                            type="file"
                            accept="image/*"
                            onChange={onImageChange}/>
                            Cargar imagen
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> Nombre de la compañia </label>
                        <Input
                          value={name}
                          className="form-control"
                          onChange={val => setName(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> Información de la compañia </label>
                        <Input
                          type='textarea'
                          value={info}
                          className="form-control"
                          onChange={val => setInfo(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Dias de atención </label>
                        <Input
                          value={day}
                          className="form-control"
                          onChange={val => setDay(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Horarios de atención </label>
                        <Input
                          value={hour}
                          className="form-control"
                          onChange={val => setHour(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Email </label>
                        <Input
                          value={email}
                          className="form-control"
                          onChange={val => setEmail(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Telefono </label>
                        <Input
                          value={phone}
                          className="form-control"
                          onChange={val => setPhone(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Instagram </label>
                        <Input
                          value={instagram}
                          className="form-control"
                          onChange={val => setInstagram(val.target.value)}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading.btn) ? <Spinner style={{color: '#004899'}}/> :
                          <div>
                            <Button
                              style={{
                                backgroundColor: '#004899',
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => updateCompanyInfo()}
                              > Guardar
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                </div>
              }
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

    </>
  )
}

export default Company;