/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Table } from "reactstrap";
import moment from "moment";
import Header from "../../components/Headers/Header";
import { getInvoicesByCode, setInvoiceStatusData } from "../../api/invoices";
import NotificationAlert from "react-notification-alert";

const ViewOrder = () => {
  const notificationAlertRef = React.useRef(null);
  const id = useParams().id;
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState({});
  const [piece, setPiece] = useState([]);
  
  useEffect( () => {
    getInvoices();
  }, [])
  
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getInvoices = async () => {
    const res = await getInvoicesByCode(id);

    if(res.success === true) {
      setError(false);
      setData(res.data.invoices_data);
      setPiece(res.data.invoices_details);
    }
    else {
      setError(true);
    }

    setLoading(false);
  }

  const setInvoiceStatus = async (val) => {
    setLoadingBtn(true);

    const res = await setInvoiceStatusData(val, id);
    
    if(res.success === true) {
      setLoadingBtn(false);
      notifyAlert("success", "Mensaje", "Pedido aprobado correctamente");
      window.location.href="/admin/orders";
    }
    else {
      notifyAlert("danger", "Mensaje", "Error procesar el pedido");
    }

    setLoadingBtn(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Orden </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody style={{
                marginTop: "-50px"
              }}>
                <div>
                  {
                    (loading) ?
                      <div className="text-center">
                        <br />
                        <Spinner style={{color: '#004899'}} />
                      </div>:
                    (error) ? 
                      <div>
                        <div colSpan={10} className='text-muted text-center'>
                          <label> Se ha presentado un error. Intente nuevamente mas tarde </label>
                        </div>
                      </div>: 
                      <div>
                        <br />
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Factura </label>
                              <Input
                                value={data.code}
                                className="form-control" readOnly={true}/>
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Estatus </label>
                              <Input
                                value={data.status}
                                className="form-control" readOnly={true}/>
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Total a pagar </label>
                              <Input
                                value={`$${data.total}`}
                                className="form-control" readOnly={true}/>
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Fecha </label>
                              <Input
                                  value={moment(new Date(data.date)).subtract(4, 'hour').format('L')}
                                className="form-control" readOnly={true}/>
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Descuento </label>
                              <Input
                                value={data.discount}
                                className="form-control" readOnly={true}/>
                            </FormGroup>
                          </Col>

                          <Col lg="12">
                            <FormGroup>
                              <label className="form-control-label"> Comentario </label>
                              <textarea 
                                value={data.comment}
                                className="form-control" readOnly={true}>
                              </textarea>
                            </FormGroup>
                          </Col>
                        </Row>

                        <h3>Datos del vendedor</h3>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Nombre </label>
                              <Input
                                value={data.seller_name}
                                className="form-control" readOnly={true}/>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Correo Electronico </label>
                              <Input
                                value={data.seller_email}
                                className="form-control" readOnly={true}/>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Telefono </label>
                              <Input
                                value={data.seller_phone}
                                className="form-control" readOnly={true}/>
                            </FormGroup>
                          </Col>
                        </Row>

                        <h3>Datos del cliente</h3>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Nombre </label>
                              <Input
                                value={data.client_name}
                                className="form-control" readOnly={true}/>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Codigo </label>
                              <Input
                                value={data.client_code}
                                className="form-control" readOnly={true}/>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Contacto </label>
                              <Input
                                value={data.client_contact}
                                className="form-control" readOnly={true}/>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> RIF </label>
                              <Input
                                value={data.client_rif}
                                className="form-control" readOnly={true}/>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Estado </label>
                              <Input
                                value={data.client_state}
                                className="form-control" readOnly={true}/>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label"> Ciudad </label>
                              <Input
                                value={data.client_city}
                                className="form-control" readOnly={true}/>
                            </FormGroup>
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label className="form-control-label"> Dirección </label>
                              <textarea 
                                value={data.client_address}
                                className="form-control" readOnly={true}>
                              </textarea>
                            </FormGroup>
                          </Col>
                        </Row>

                        <h3> Detalle </h3>
                        <Row>
                          <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th scope="col" />
                                <th scope="col"> Imagen </th>
                                <th scope="col"> Nombre </th>
                                <th scope="col"> Marca </th>
                                <th scope="col"> Cantidad </th>
                                <th scope="col"> Precio </th>
                                <th scope="col"> Almacen </th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                (loading) ?
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <Spinner style={{color: '#004899'}} />
                                  </td>
                                </tr>:
                                (error) ? 
                                <tr>
                                  <td colSpan={10} className='text-muted text-center'>
                                    <label>Se ha presentado un error</label>
                                    <br />
                                    <Button 
                                      color="secondary"
                                      onClick={() => getInvoices()}> Cargar </Button>
                                  </td>
                                </tr>:
                                (piece.length === 0) ? 
                                <tr>
                                  <td colSpan={10} className='text-muted text-center'> No hay información registrada </td>
                                </tr>
                                :
                                piece.map( (d,i) => (
                                  <tr key={i}>
                                    <td>
                                      {++i}
                                    </td>
                                    <td>
                                      <img 
                                        alt='Error'
                                        style={{
                                          width: '64px',
                                          height: '64px',
                                          objectFit:'scale-down'
                                        }}
                                        src={d.picture}/>
                                    </td>
                                    <td>
                                      {String(d.name).trim()}
                                    </td>
                                    <td>
                                      {d.brand}
                                    </td>
                                    <td>
                                      {d.quantity}
                                    </td>
                                    <td>
                                      ${d.price}
                                    </td>
                                    <td>
                                      {d.warehouse_name}
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </Row>
                        
                        <Row style={{display: "block"}}>
                          <div className="text-right">
                            <br />
                            <Button color="gray" to="/admin/orders" tag={Link}> Regresar </Button>
                            {
                              (data.status_code === 1) ? <>
                                {
                                  (loadingBtn) ? <>
                                    <Spinner style={{color: '#004899'}} />
                                  </> : <>
                                    <Button color="danger" onClick={() => setInvoiceStatus(3)} > Cancelar </Button>
                                    <Button color="success" onClick={() => setInvoiceStatus(2)} > Aprobar </Button>
                                  </>
                                }
                              </> : (data.status_code === 2) ? <>
                                <Button color="danger" onClick={() => setInvoiceStatus(4)} > Rechazar </Button>
                              </> : 
                              <></>
                            }
                          </div>
                        </Row>
                      </div>
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ViewOrder;