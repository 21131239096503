import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Spinner, Container, Row, Col, Card, CardHeader, CardBody, FormGroup, Button, Input } from "reactstrap";
import Header from "../../components/Headers/Header";
import { createCatalogData } from "../../api/catalog";
import { getBrandData } from '../../api/database';
import NotificationAlert from "react-notification-alert";

export default function NewCatalog() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [title, setTitle] = useState('');
  const [brand, setBrand] = useState([]);
  const [selBrand, setSelBrand] = useState(''); 


  useEffect( () => {
    getAllActiveBrand();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getAllActiveBrand = async () => {
    setLoading(true);
    const res = await getBrandData();

    if(res.success === true) {
      const data = (res.data).filter( b => {
        return b.active === 0
      });

      setBrand(data);
    }
    else {
      setBrand([]);
    }
    setLoading(false);
  }

  const createCatalog = async () => {
    setLoadingBtn(true);

    if(String(title).trim() === ""){
      notifyAlert("danger", "Error", "Debe ingresar un nombre para el archivo");
    }
    else if(selBrand === "") {
      notifyAlert("danger", "Error", "Debe seleccionar una marca");
    }
    else {
      const jsn = {
        "title": title,
        "brand": selBrand
      }

      const res = await createCatalogData(jsn);
      
      if(res.success === true) {
        notifyAlert("success", "Mensaje", "Se ha generado el reporte correctamente");
        setTitle('');
        setSelBrand('');
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al generar el reporte");
      }
    }

    setLoadingBtn(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Catalogo </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <span className="form-control-label">Nombre del archivo</span>
                        <Input
                          value={title}
                          className="form-control"
                          onChange={val => setTitle(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      {
                        (loading) ? <>
                          <Spinner style={{color: '#004899'}} />
                        </> : <>
                          <FormGroup>
                            <span className="form-control-label">Seleccionar marca</span>
                            <select 
                              className="form-control" 
                              style={{appearance: 'auto'}} 
                              value={selBrand}
                              onChange={(val) => setSelBrand(val.target.value)}
                            >
                              <option value="">Seleccionar</option>
                              {
                                brand.map((b,i) => (
                                  <option key={i} value={b.code}>{b.name}</option>
                                ))
                              }
                            </select>
                          </FormGroup>
                        </>
                      }
                    </Col>
                  </Row>

                  <div style={{textAlign: 'right'}}>
                    <Button color="gray" to="/admin/catalog" tag={Link}> Regresar </Button>
                    {
                      (loadingBtn) ? <>
                        <Spinner style={{color: '#004899'}} />
                      </> : <>
                        <Button 
                          color="primary" 
                          onClick={() => createCatalog()}> Generar </Button>
                      </>
                    }
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}