import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

//FAQ
export async function getAllFAQData() {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.page}/faq`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function saveFAQData(jsn) {
	const token = getToken('spm_token');

	try {
		const res = await axios.post(`${CONS.page}/faq`, jsn,  {
      headers: {
        'token': token,
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateFAQData(jsn) {
	const token = getToken('spm_token');

	try {
		const res = await axios.put(`${CONS.page}/faq`, jsn,  {
      headers: {
        'token': token,
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function deleteFAQData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.delete(`${CONS.page}/faq/${id}`, {
      headers: {
        'token': token,
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

//CONTACT
export async function getContactData() {
  const token = getToken('spm_token');

  try {
    const res = await axios.get(`${CONS.page}/contact`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

//CAROUSEL
export async function getCarouselData() {
  const token = getToken('spm_token');

  try {
    const res = await axios.get(`${CONS.page}/carousel`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function setCarouselData(title, subtitle, active, comImg) {
  const token = getToken('spm_token');

  const formData = new FormData();
  formData.append('title', title);
  formData.append('subtitle', subtitle);
  formData.append('active', active);
	formData.append('file', comImg.file, comImg.file.name);

  try {
    const res = await axios.post(`${CONS.page}/carousel`, formData,  {
      headers: {
        'token': token,
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getCarouselByIdData(id) {
  const token = getToken('spm_token');

  try {
    const res = await axios.get(`${CONS.page}/carousel/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function updateCarouselData(title, subtitle, active, comImg, id) {
  const token = getToken('spm_token');

  const formData = new FormData();
  formData.append('id', id);
  formData.append('title', title);
  formData.append('subtitle', subtitle);
  formData.append('active', active);
	(comImg === null) ?  formData.append('file', comImg) : formData.append('file', comImg.file);

  try {
    const res = await axios.put(`${CONS.page}/carousel`, formData,  {
      headers: {
        'token': token,
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function deleteCarouselData(id) {
  const token = getToken('spm_token');

  try {
    const res = await axios.delete(`${CONS.page}/carousel/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

//SERVICES
export async function getServicesData() {
  const token = getToken('spm_token');

  try {
    const res = await axios.get(`${CONS.page}/services`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function setServicesData(title, subtitle, active, comImg) {
  const token = getToken('spm_token');

  const formData = new FormData();
  formData.append('title', title);
  formData.append('subtitle', subtitle);
  formData.append('active', active);
	formData.append('file', comImg.file, comImg.file.name);

  try {
    const res = await axios.post(`${CONS.page}/services`, formData,  {
      headers: {
        'token': token,
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getServicesByIdData(id) {
  const token = getToken('spm_token');

  try {
    const res = await axios.get(`${CONS.page}/services/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function updateServicesData(title, subtitle, active, comImg, id) {
  const token = getToken('spm_token');

  const formData = new FormData();
  formData.append('id', id);
  formData.append('title', title);
  formData.append('subtitle', subtitle);
  formData.append('active', active);
	(comImg === null) ?  formData.append('file', comImg) : formData.append('file', comImg.file);

  try {
    const res = await axios.put(`${CONS.page}/services`, formData,  {
      headers: {
        'token': token,
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function deleteServicesData(id) {
  const token = getToken('spm_token');

  try {
    const res = await axios.delete(`${CONS.page}/services/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

//BLOG
export async function getBlogData() {
  const token = getToken('spm_token');

  try {
    const res = await axios.get(`${CONS.page}/blog`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function setNewBlogData(title, text, publisher, active, comImg) {
  const token = getToken('spm_token');

  const formData = new FormData();
  formData.append('title', title);
  formData.append('text', text);
  formData.append('publisher', publisher);
  formData.append('active', active);
	formData.append('file', comImg.file, comImg.file.name);

  try {
    const res = await axios.post(`${CONS.page}/blog`, formData,  {
      headers: {
        'token': token,
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getBlogByIdData(id) {
  const token = getToken('spm_token');

  try {
    const res = await axios.get(`${CONS.page}/blog/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function updateBlogData(id, title, text, publisher, active, comImg) {
  const token = getToken('spm_token');

  const formData = new FormData();
  formData.append('id', id);
  formData.append('title', title);
  formData.append('text', text);
  formData.append('publisher', publisher);
  formData.append('active', active);
	(comImg === null) ?  formData.append('file', comImg) : formData.append('file', comImg.file);

  try {
    const res = await axios.put(`${CONS.page}/blog`, formData,  {
      headers: {
        'token': token,
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function deleteBlogData(id) {
  const token = getToken('spm_token');

  try {
    const res = await axios.delete(`${CONS.page}/services/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

//COMPANY
export async function getCompanyInfoData() {
  const token = getToken('spm_token');

  try {
    const res = await axios.get(`${CONS.page}/company`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function updateCompanyData(name, info, day, hour, email, phone, instagram, comImg) {
  const token = getToken('spm_token');

  const formData = new FormData();
  formData.append('name', name);
  formData.append('info', info);
  formData.append('day', day);
  formData.append('hour', hour);
  formData.append('email', email);
  formData.append('phone', phone);
  formData.append('instagram', instagram);
  (comImg === null) ?  formData.append('file', comImg) : formData.append('file', comImg.file);

  try {
    const res = await axios.put(`${CONS.page}/company`, formData,  {
      headers: {
        'token': token,
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}