import { Container, Navbar } from "reactstrap";
import GLogo from './../../assets/img/logo.png'

export default function AuthHeader() {
  return (
    <>
    <Navbar
      style={{
        backgroundColor: "#ffffff",
      }}
      expand="md">
        <Container>
          <div className="text-center" style={{width: '100%'}}>
            <div>
              <img style={{
                width: '180px',
                height: 'auto',
                marginTop: '5px'
              }} alt="GoSuite" src={GLogo} />
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

