import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function getAllRolData() {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.users}/rol`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getUsersData() {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(CONS.users, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getUsersByIdData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.users}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setNewUsersData(name, email, phone, rol, active, code, comImg, seller) {
	const token = getToken('spm_token');
	
  const formData = new FormData();
  formData.append('name', name);
  formData.append('email', email);
  formData.append('phone', phone);
  formData.append('rol', rol);
  formData.append('active', active);
  formData.append('code', code);
  formData.append('seller', seller);
	(comImg === null) ?  formData.append('file', comImg) : formData.append('file', comImg.file, comImg.file.name);

	try {
		const res = await axios.post(CONS.users, formData,  {
      headers: {
        'token': token,
					'Content-type': 'multipart/form-data'
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateUsersData(id, name, email, phone, rol, active, code, comImg, seller) {
	const token = getToken('spm_token');
	const formData = new FormData();
  formData.append('name', name);
  formData.append('email', email);
  formData.append('phone', phone);
  formData.append('rol', rol);
  formData.append('active', active);
  formData.append('code', code);
  formData.append('seller', seller);
	(comImg === null) ?  formData.append('file', comImg) : formData.append('file', comImg.file, comImg.file.name);
	
	try {
		const res = await axios.put(`${CONS.users}/${id}`, formData, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function deleteUsersData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.delete(`${CONS.users}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

