/* eslint-disable no-useless-escape */
import React, {useState, useContext} from "react";
import {Spinner, Button, Card, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import AuthHeader from "components/Headers/AuthHeader";
import { AuthContext } from "./../../context/AuthContext";
import { setLogin } from "../../api/auth";
import lang from "./../../lang/lang";
import NotificationAlert from "react-notification-alert";

function Login() {
  const notificationAlertRef = React.useRef(null);
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [loading, setLoading] = useState(false);
  const {signIn} = useContext(AuthContext);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const validData = () =>{
    return (
      email !== "" &&
      pass !== ""
    )
  }

  const sendData = async () => {
    setLoading(true);
    
    if(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/.test(email) === false ) {
      console.log(1);
      notifyAlert('danger', lang('MESSAGE'), "El correo electrónico no es válido");
    }
    else {
      const res = await setLogin(email, pass);

      if(res.success === true) {
        signIn(res.token);
      }
      else {
        notifyAlert('danger', lang('MESSAGE'), res.error.message);
        setLoading(false);
      }
    }

    setLoading(false);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendData();
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <AuthHeader/>
      <Container className="pb-5" style={{
        marginTop: '25px'
      }}>
        <Row className="justify-content-center">
          <Col lg="3" md="6">
            <h2 style={{
              fontSize: '35px',
              fontWeight: 'bold'
            }}> Bienvenido </h2>
            <ul style={{
              padding: 0,
              lineHeight: 2,
              marginTop: '20px',
              listStyle: 'none'
            }}>
              <li> <i style={{marginRight: '5px'}}className="fas fa-list-ul" /> Gestiona tus ordenes </li>
              <li> <i style={{marginRight: '5px'}}className="fas fa-users" /> Metricas de los vendedores</li>
              <li> <i style={{marginRight: '5px'}}className="fas fa-bell" /> Recibe notificaciones </li>
              <li> <i style={{marginRight: '5px'}}className="fas fa-database" /> Visualiza tu base de datos </li>
            </ul>
          </Col>
          <Col lg="5" md="7">
            <Card className="border-0 mb-0" style={{boxShadow: '0 0 0 0'}}>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" style={{marginTop: "50px"}}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        value={email}
                        onChange={val => setEmail(val.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Contraseña"
                        type="password"
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        value={pass}
                        onChange={val => setPass(val.target.value)}
                        onKeyDown={(event) => handleKeyDown(event)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    {
                    (!loading) ? <>
                        <Button
                          disabled={!validData()}
                          color="primary" 
                          type="button"
                          style={{width: '100%'}}
                          onClick={() => sendData()}
                        > Inicio de sesión
                        </Button>
                      </>
                      :
                      <>
                        <Spinner color="primary" /> 
                      </>
                    }
                  </div>

                  <div style={{
                    marginTop: '15px',
                    float: 'right',
                    fontSize: '13px'
                  }}>
                    <Link 
                      to='/auth/password'>¿Olvidaste tu contraseña?</Link>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
