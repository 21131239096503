/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import Header from "../../components/Headers/Header";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Compressor from 'compressorjs';
import { getTokenData, setToken } from "./../../utils/token";
import { updateUserData, setPictureUser, updateEmailUserData, updatePasswordData } from "../../api/config";
import NotificationAlert from "react-notification-alert";

const noPicture = require('./../../assets/img/noPicture.png');

const Config = () => {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({load: true, save: false, picture: false, email: false, pass: false});
  const [user, setUser] = useState({name:'', email:'', phone:'', lang: 'es', picture: null});
  const [password, setPassword] = useState({oldPass: '', newPass: '', repeatPass: ''});
  const [file, setFile] = useState({});
  const [picture, setPicture] = useState('');
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    getUserInfo();
  },[]);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getUserInfo = async () => {
    const _name = getTokenData('spm_token', 'name');
    const _email = getTokenData('spm_token', 'email');
    const _lang = getTokenData('spm_token', 'lang');
    const _phone = getTokenData('spm_token', 'phone');
    const _picture = getTokenData('spm_token', 'picture');
    
    setUser({
      name: _name,
      email: _email,
      lang: _lang,
      phone: _phone,
      picture: _picture
    });

    setPicture(_picture);
    setLoading({...loading, load:false});
  }

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				setPicture(e.target.result);
			};

			reader.readAsDataURL(event.target.files[0]);
			setFile(event.target.files[0]);
		}
	}

	const renderImageToSend = async (f) => {
		return new Compressor(f, {quality: 0.6});
	}

  const savePicture = async () => {
    setLoading({...loading, picture: true});

    let fileCompress = null;
    
    if(picture === '') {
      notifyAlert('danger', "Mensaje", 'Imagen vacia');
    }
    else {
      fileCompress = (file && Object.keys(file).length === 0 && file.constructor === Object) ? null : await renderImageToSend(file);
      
      const res = await setPictureUser(fileCompress);

      if(res.success === true) {
        console.log(res.data);
        setToken('spm_token', res.data);
        window.location.reload();
      }
      else {
        notifyAlert('danger', "Mensaje", res.error.message);
      }

    }
    
    setLoading({...loading, picture: false});
  }

  const saveData = async () => {
    setLoading({...loading, save: true});
    const _name = String(user.name).trim();
    const _phone = String(user.phone).trim();

    if(_name === "") {
      notifyAlert('danger', "Mensaje", 'Nombre vacio');
    }
    else if(_phone === "") {
      notifyAlert('danger', "Mensaje", 'Telefono vacio');
    }
    else {
      const jsn = {
        'name': _name,
        'phone': _phone,
        'lang': user.lang
      }

      const res = await updateUserData(jsn);

      if(res.success === true) {
        setToken('spm_token', res.data);
        window.location.reload();
      }
      else {
        notifyAlert('danger', "Mensaje", res.error.message);
      }
    }

    setLoading({...loading, save: false});
  }

  const saveEmail = async () => {
    setLoading({...loading, email: true});
    const email = String(user.email).trim();

    if(email === "") {
      notifyAlert('danger', "Mensaje", 'Email vacio');
    }
    else if(!/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/.test(email)) {
      notifyAlert('danger', "Mensaje", 'Email invalido');
    }
    else {
      const res = await updateEmailUserData(email);

      if(res.success === true) {
        setToken('spm_token', res.data);
        notifyAlert('success', "Mensaje", "Email actualizado");
      }
      else {
        notifyAlert('danger', "Mensaje", res.message);
      }
    }

    setLoading({...loading, email: false});
  }

  const updatePassword = async () => {
    setLoading({...loading, pass: true});

    const {newPass, oldPass, repeatPass} = password;
    
    if(oldPass === "") {
      notifyAlert('danger', "Mensaje", 'Contraseña actual vacia');
    }
    else if(newPass === '') {
      notifyAlert('danger', "Mensaje", 'Contraseña nueva vacia');
    }
    else if(repeatPass === '') {
      notifyAlert('danger', "Mensaje", 'Repetir contraseña vacia');
    }
    else if(oldPass === newPass) {
      notifyAlert('danger', "Mensaje", 'Contraseña actual y nueva son iguales');
    }
    else if(newPass !== repeatPass) {
      notifyAlert('danger', "Mensaje", 'Contraseña nueva y repetir contraseña no son iguales');
    }
    else {
      const jsn = {
        "oldPass": oldPass,
        "newPass": newPass
      }

      const res = await updatePasswordData(jsn);
      
      if(res.success === true) {
        notifyAlert('success', "Mensaje", "Contraseña actualizada");
        setPassword({oldPass: '', newPass: '', repeatPass: ''});
      }
      else {
        notifyAlert('danger', "Mensaje", res.message);
      }
    }

    setLoading({...loading, pass: false});
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Perfil </h3>
                  </div>
                </Row>
              </CardHeader>
              
              <CardBody>
                <div className='paddingHorizonal'>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={(activeTab === '1') ? 'active' : ''}
                        onClick={() => { toggle('1'); }}>
                        Perfil
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={(activeTab === '2') ? 'active' : ''}
                        onClick={() => { toggle('2'); }}>
                        Seguridad
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <div className="tabInside">
                      {
                        (loading.load) ? <div className="text-center">
                          <Spinner style={{
                            color: '#004899'
                          }} />
                        </div> :
                        <>
                          <Row>
                            <Col lg="6">
                              <div className="text-center">
                                <img
                                  alt="..."
                                  id="imgBrand"
                                  src={(picture === null) ? noPicture.default : picture}/>
                                <br />
                                {
                                  (loading.picture) ? <div className="text-center" style={{marginTop: '5px'}}>
                                    <Spinner style={{
                                      color: '#004899'
                                    }} />
                                  </div> :
                                  <> 
                                    <label className="custom-file-upload">
                                      <input 
                                        type="file"
                                        accept="image/*"
                                        onChange={onImageChange}/>
                                      Editar
                                    </label>
                                    <Button
                                      style={{
                                        backgroundColor: '#004899',
                                        color: '#fff',
                                        border: 0
                                      }}
                                      onClick={() => savePicture()}> Guardar
                                    </Button>
                                  </> 
                                }
                              </div>
                            </Col>
                            
                            <Col lg="6">
                              <div>
                                <FormGroup>
                                  <label className="form-control-label"> Nombre </label>
                                  <Input
                                    value={user.name}
                                    className="form-control"
                                    onChange={val => setUser({...user, name: val.target.value })}/>
                                </FormGroup>

                                <FormGroup>
                                  <label className="form-control-label"> Telefono </label>
                                  <Input
                                    value={user.phone}
                                    className="form-control"
                                    onChange={val => setUser({...user, phone: val.target.value })}/>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12" >
                              <br />
                              <div className="float-right">
                              {
                                (loading.save) ? <div className="text-center">
                                  <Spinner style={{
                                    color: '#004899'
                                  }} />
                                </div> :
                                <>
                                  <Button
                                    style={{
                                      backgroundColor: '#004899',
                                      color: '#ffffff',
                                      border: 0
                                    }}
                                    onClick={() => saveData()}
                                    > Guardar
                                  </Button>
                                </>
                              }
                              </div>
                            </Col>
                          </Row>
                        </>
                      }
                      </div>
                    </TabPane>

                    <TabPane tabId="2">
                      <div className="tabInside">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label className="form-control-label">Email</label>
                              <Input
                                value={user.email}
                                onChange={val => setUser({...user, email: val.target.value})}
                                placeholder="Email"
                              />
                              </FormGroup>
                              {
                                (loading.email) ? <div>
                                  <Spinner style={{
                                    color: '#004899'
                                  }} />
                                </div> :
                                <>
                                  <Button
                                    style={{
                                      backgroundColor: '#004899',
                                      color: '#ffffff',
                                      border: 0
                                    }}
                                    onClick={() => saveEmail()}
                                    > Guardar
                                  </Button>
                                </>  
                              }
                            <br /><br />
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label className="form-control-label">Contraseña actual</label>
                              <Input
                                value={password.oldPass}
                                type="password"
                                placeholder="Contraseña actual"
                                onChange={val => setPassword({...password, oldPass: val.target.value})}
                              />
                              <br />
                              <label className="form-control-label">Nueva contraseña</label>
                              <Input
                                value={password.newPass}
                                type="password"
                                placeholder="Nueva contraseña"
                                onChange={val => setPassword({...password, newPass: val.target.value})}
                              />
                              <br />

                              <label className="form-control-label">Repetir contraseña</label>
                              <Input
                                value={password.repeatPass}
                                type="password"
                                placeholder="Repetir contraseña"
                                onChange={val => setPassword({...password, repeatPass: val.target.value})}
                              />

                            </FormGroup>
                            {
                              (loading.pass) ? <div>
                                <Spinner style={{
                                  color: '#004899',
                                }} />
                              </div> :
                              <>
                                <Button
                                  style={{
                                    backgroundColor: '#004899',
                                    color: '#ffffff',
                                    border: 0
                                  }}
                                  onClick={() => updatePassword()}
                                  > Actualizar
                                </Button>
                              </>
                            }
                          </Col>
                        </Row>
                      </div>
                    </TabPane>

                  </TabContent>
                  
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Config;