/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import Compressor from 'compressorjs';
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getCarouselByIdData, updateCarouselData, deleteCarouselData } from "../../../api/page";
import NotificationAlert from "react-notification-alert";

const EditCarousel = () => {
  const notificationAlertRef = React.useRef(null);
  const { id } = useParams();
  const [picture, setPicture] = useState('');
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [active, setActive] = useState('0');
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState({page: true, btn: false});
  const [error, setError] = useState(false);

  useEffect( ()=> {
    getCarouselById();
  }, []) 

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				setPicture(e.target.result);
			};

			reader.readAsDataURL(event.target.files[0]);
			setFile(event.target.files[0]);
		}
	}

	const renderImageToSend = async (f) => {
		return new Compressor(f, {quality: 0.6});
	}

  const getCarouselById = async () => {
    const res = await getCarouselByIdData(id);

    if(res.success === true) {
      setError(false);
      setPicture(res.data.picture);
      setTitle(res.data.title);
      setSubtitle(res.data.subtitle);
      setActive(res.data.active);
    }
    else {
      setError(true);
      notifyAlert("danger", "Error", "Ha ocurrido un error al obtener los datos de la marca");
    }
    setLoading(false);
  }

  const updateCarousel = async () => {
    setLoading({...loading, btn: true});
    
    let fileCompress = null;
    
    const _name = title.trim();
    const _code = subtitle.trim();

    if(_name === "") {
      notifyAlert("danger", "Error", "El titulo no puede estar vacío");
    }
    else if(_code === "") {
      notifyAlert("danger", "Error", "El subtitulo no puede estar vacío");
    }
    else {
      fileCompress = (file && Object.keys(file).length === 0 && file.constructor === Object) ? null : await renderImageToSend(file);

      console.log("DATA ==> ", fileCompress, id, _name, _code, active);

      const res = await updateCarouselData(_name, _code, active, fileCompress, id);

      if(res.success === true) {
        notifyAlert("success", "Éxito", "Se ha actualizado el carrusel exitosamente");
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al actualizar el carrusel");
      }
    }
    setLoading({...loading, btn: false});
  }

  const deleteCarousel = async () =>  {
    const res = await deleteCarouselData(id);

    if(res.success === true) {
      window.location.href = '/admin/carousel'
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al eliminar la marca");
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Marca </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
              {
                (loading.page) ?
                <div className="text-center">
                  <Spinner style={{color: '#004899'}} />
                </div>:
                (error) ? 
                <div>
                  <div colSpan={10} className='text-muted text-center'>
                    <label>Se ha presentado un error, intente de nuevo mas tarde</label>
                    <br />
                    <Button color="gray" to="/admin/brand" tag={Link}> Regresar </Button>
                  </div>
                </div>: 
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <label className="form-control-label"> Imagen </label>
                      <div 
                        id="dvImage"
                        style={{
                          width: '100%',
                          height: '150px',
                          marginBottom: '10px',
                          borderRadius: '.25rem',
                          border: '1px solid #ced4da'
                        }}>
                          <img 
                            style={{
                              width: '100%',
                              height: '148px',
                              objectFit: 'scale-down',
                            }}
                            alt="Cargar imagen"
                            src={picture} />
                      </div>

                      <div className="float-right">
                        <label className="custom-file-upload">
                          <input 
                            type="file"
                            accept="image/*"
                            onChange={onImageChange}/>
                            Cargar imagen
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> Titulo </label>
                        <Input
                          value={title}
                          className="form-control"
                          onChange={val => setTitle(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> Subtitulo </label>
                        <Input
                          value={subtitle}
                          className="form-control"
                          onChange={val => setSubtitle(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Activo </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={active}
                          onChange={val => setActive(val.target.value)}
                        >
                          <option value="1"> Si </option>
                          <option value="0"> No </option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading.btn) ? <Spinner style={{color: '#004899'}}/> :
                          <div>
                            <Button color="gray" to="/admin/brand" tag={Link}> Regresar </Button>
                            <Button color="danger" onClick={() => setModal(!modal)}> Eliminar </Button>
                            <Button
                              style={{
                                backgroundColor: '#004899',
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => updateCarousel()}
                              > Guardar
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                </div>
              }
              </CardBody>
            </Card>
 
          </div>
        </Row>
	
        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader toggle={() => setModal()}> Mensaje </ModalHeader>
          <ModalBody>
            ¿Está seguro que desea eliminar el carrusel?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal()}> Cancelar </Button>
            <Button color="danger" onClick={() => deleteCarousel()}> Eliminar </Button>
          </ModalFooter>
        </Modal>
     

      </Container>

    </>
  )
}

export default EditCarousel;