import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../components/Headers/Header";
import { setBudgetsData } from "../../api/budgets";
import NotificationAlert from "react-notification-alert";
import dayjs from "dayjs";

const NewBudgets = () => {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [initDate, setInitDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [goals, setGoals] = useState('');

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span> <br />
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const addNewBudgets = async () => {
    setLoading(true);

    if(title.trim() === "") {
      notifyAlert('warning', "Mensaje", "El campo título es requerido");
    }
    else if(initDate === "") {
      notifyAlert('warning', "Mensaje", "El campo fecha de inicio es requerido");
    }
    else if(endDate === '') {
      notifyAlert('warning', "Mensaje", "El campo fecha de finalización es requerido");
    }
    else if(dayjs(endDate) <= dayjs(initDate)) {
      notifyAlert('warning', "Mensaje", "La fecha de finalización debe ser mayor a la fecha de inicio");
    }
    else if (goals.trim() === '') {
      notifyAlert('warning', "Mensaje", "El campo metas es requerido");
    }
    else if(Number(goals) === 0){
      notifyAlert('warning', "Mensaje", "El campo metas debe ser mayor a 0");
    }
    else {
      const jsn = {
        "title": title,
        "description":description,
        "initDate": initDate,
        "endDate": endDate,
        "goals": goals
      }

      const res = await setBudgetsData(jsn);

      if(res.success === true) {
        setTitle('');
        setDescription('');
        setInitDate('');
        setEndDate('');
        setGoals('');
        notifyAlert('success', "Mensaje", "Presupuesto creado correctamente");
      }
      else {
        notifyAlert('danger', "Mensaje", "Error al crear el presupuesto");
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

            <CardHeader className="border-0">
              <Row>
                <div className="col mb-0 float-left">
                  <h3 style={{color: '#004899',fontSize:'30px'}}> Presupuesto </h3>
                </div>
              </Row>
            </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> Titulo </label>
                        <Input
                          value={title}
                          className="form-control"
                          onChange={val => setTitle(val.target.value)}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> Descripción (opcional) </label>
                        <textarea 
                          style={{
                            height: '100px',
                            resize: 'none'
                          }}
                          value={description}
                          className="form-control"
                          onChange={(val) => setDescription(val.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Fecha de inicio </label>
                        <Input
                          type="date"
                          value={initDate}
                          className="form-control"
                          onChange={val => setInitDate(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Fecha final </label>
                        <Input
                          type="date"
                          value={endDate}
                          className="form-control"
                          onChange={val => setEndDate(val.target.value)}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Meta </label>
                        <Input
                          type="number"
                          value={goals}
                          className="form-control"
                          onChange={val => setGoals(val.target.value)}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading) ? <Spinner style={{color: '#004899'}}/> :
                          <div>
                            <Button color="gray" to="/admin/budgets" tag={Link}> Regresar </Button>
                            <Button
                              style={{
                                backgroundColor: '#004899',
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => addNewBudgets()}
                              > Guardar
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
 
          </div>
        </Row>
      </Container>

    </>
  )
}

export default NewBudgets;