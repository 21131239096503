/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { setNewWarehouse } from "../../../api/database";
import NotificationAlert from "react-notification-alert";

const NewWarehouse = () => {
  const notificationAlertRef = React.useRef(null);
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [manager, setManager] = useState('');
  const [active, setActive] = useState('0');
  const [loading, setLoading] = useState(false);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const addNewWarehouse = async () => {
    setLoading(true);

    const _name = name.trim();
    const _code = code.trim();

    if(_name === "") {
      notifyAlert("danger", "Error", "El nombre no puede estar vacío");
    }
    else if(_code === "") {
      notifyAlert("danger", "Error", "El código no puede estar vacío");
    }
    else {
      let jsn = {
        "name": _name,
        "code": _code,
        "manager": manager,
        "active": active
      }

      const res = await setNewWarehouse(jsn);

      if(res.success === true) {
        setCode('');
        setName('');
        setManager('');
        setActive('0');
        notifyAlert("success", "Éxito", "Almacén creado correctamente");
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al crear el almacén");
      }
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Almacen </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Codigo </label>
                        <Input
                          value={code}
                          className="form-control"
                          onChange={val => setCode(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Nombre </label>
                        <Input
                          value={name}
                          className="form-control"
                          onChange={val => setName(val.target.value)}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Encargado </label>
                        <Input
                          value={manager}
                          className="form-control"
                          onChange={val => setManager(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Activo </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={active}
                          onChange={val => setActive(val.target.value)}
                        >
                          <option value="1">Si </option>
                          <option value="0"> No </option>
                        </select>
                      </FormGroup>
                    </Col> 
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading) ? <Spinner style={{color: '#004899'}}/> :
                          <div>
                            <Button color="gray" to="/admin/warehouse" tag={Link}> Regresar </Button>
                            <Button
                              style={{
                                backgroundColor: '#004899',
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => addNewWarehouse()}
                              > Guardar
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

    </>
  )
}

export default NewWarehouse;