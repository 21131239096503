/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from "react";
import { useLocation, NavLink as NavLinkRRD, Link } from "react-router-dom";
import classnames from "classnames";
import { PropTypes } from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav } from "reactstrap";
import { AuthContext } from "./../../context/AuthContext";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";

function Sidebar({ toggleSidenav, sidenavOpen, logo }) {
  const {signOut} = React.useContext(AuthContext);
  const [orderToggle, setOrderToggle] = useState(false);
  const [statsToggle, setStatsToogle] = useState(false);
  const [bugetToggle, setBugetToogle] = useState(false);
  const [webPageToggle, setWebPageToggle] = useState(false);
  const [personalizationToggle, setPersonalizationToggle] = useState(false);
  const [databaseToggle, setDatabaseToggle] = useState(false);
  const location = useLocation();
  const [role, setRole] = useState(0);

  useEffect(() => {
    const getUserRol = async () => {
      const token_decode = jwt_decode(window.localStorage.getItem('spm_token'));
      setRole(token_decode.role);
    }

    getUserRol();
  }, []);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };

  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };

  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  }
  else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  
  return (
    <Navbar
      className={"sidenav navbar-vertical navbar-expand-xs navbar-light bg-white fixed-left"}
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      <PerfectScrollbar>
        <div className="scrollbar-inner">
          <div className="sidenav-header d-flex align-items-center">
            {logo ? (
              <NavbarBrand {...navbarBrandProps}>
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
              </NavbarBrand>
            ) : null}
            <div className="ml-auto">
              <div
                className={classnames("sidenav-toggler d-none d-xl-block", {
                  active: sidenavOpen,
                })}
                onClick={toggleSidenav}
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                </div>
              </div>
            </div>
          </div>

          <div className="navbar-inner">
            <Collapse navbar isOpen={true}>
              <h6 className="navbar-heading p-0 text-muted">
                <span className="docs-normal"> Menu </span>
                <span className="docs-mini">
                  <i className="fas fa-list" />
                </span>
              </h6>

              <Nav navbar>
              {
                ([1, 2, 5, 6].includes(role)) ? <> 
                  <NavItem>
                    <NavLink
                      data-toggle="collapse"
                      aria-expanded={orderToggle}
                      onClick={() => {
                        setOrderToggle(!orderToggle)
                      }}
                    >
                      <i className="fas fa-list"></i>
                      <span className="nav-link-text">Ordenes</span>
                    </NavLink>

                    <Collapse isOpen={orderToggle}>
                      <Nav className="nav-sm flex-column">
                        <NavItem className={activeRoute(`/admin/orders`)}>
                          <NavLink
                            to="/admin/orders"
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <span className="sidenav-mini-icon">
                              <i className="far fa-circle"></i>
                            </span>
                            <span className="sidenav-normal"> Pedidos web </span>
                          </NavLink>
                        </NavItem>

                        <NavItem className={activeRoute(`/admin/transit`)}>
                          <NavLink
                            to="/admin/transit"
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <span className="sidenav-mini-icon">
                              <i className="far fa-circle"></i>
                            </span>
                            <span className="sidenav-normal"> Transito </span>
                          </NavLink>
                        </NavItem>

                        <NavItem className={activeRoute(`/admin/invoice`)}>
                          <NavLink
                            to="/admin/invoice"
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <span className="sidenav-mini-icon">
                              <i className="far fa-circle"></i>
                            </span>
                            <span className="sidenav-normal"> Factura </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Collapse>
                  </NavItem>
                </>: <></>
              }

              {
                ([1, 2].includes(role)) ? <> 
                  <NavItem>
                    <NavLink
                      data-toggle="collapse"
                      aria-expanded={statsToggle}
                      onClick={() => {
                        setStatsToogle(!statsToggle)
                      }}
                    >
                      <i className="far fa-chart-bar"></i>
                      <span className="nav-link-text">Gráfica</span>
                    </NavLink>

                    <Collapse isOpen={statsToggle}>
                      <Nav className="nav-sm flex-column">
                        <NavItem className={activeRoute(`/admin/graph`)}>
                          <NavLink
                            to="/admin/graph"
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <span className="sidenav-mini-icon">
                              <i className="far fa-circle"></i>
                            </span>
                            <span className="sidenav-normal"> Graficas </span>
                          </NavLink>
                        </NavItem>

                          <NavItem className={activeRoute(`/admin/graph/mix`)}>
                            <NavLink
                              to="/admin/graph/mix"
                              onClick={closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <span className="sidenav-mini-icon">
                                <i className="far fa-circle"></i>
                              </span>
                              <span className="sidenav-normal"> Consolidadas </span>
                            </NavLink>
                          </NavItem>
                      </Nav>
                    </Collapse>
                  </NavItem>
                </>: <></>
              }

              {
                ([1, 2].includes(role)) ? <> 
                  <NavItem>
                    <NavLink
                      data-toggle="collapse"
                      aria-expanded={bugetToggle}
                      onClick={() => {
                        setBugetToogle(!bugetToggle)
                      }}
                    >
                      <i className="fas fa-file-invoice-dollar"></i>
                      <span className="nav-link-text">Presupuesto</span>
                    </NavLink>

                    <Collapse isOpen={bugetToggle}>
                      <Nav className="nav-sm flex-column">
                        <NavItem className={activeRoute(`/admin/budgets`)}>
                          <NavLink
                            to="/admin/budgets"
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <span className="sidenav-mini-icon">
                              <i className="far fa-circle"></i>
                            </span>
                            <span className="sidenav-normal"> Facturacion </span>
                          </NavLink>
                        </NavItem>

                        <NavItem className={activeRoute(`/admin/goals`)}>
                          <NavLink
                            to="/admin/goals"
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <span className="sidenav-mini-icon">
                              <i className="far fa-circle"></i>
                            </span>
                            <span className="sidenav-normal"> Vendedor </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Collapse>
                  </NavItem>
                </>: <></>
              }
              {
                ([1, 2, 4, 5,6].includes(role)) ? <> 
                  <NavItem>
                    <NavLink
                      to="/admin/catalog"
                      tag={Link}>
                      <i className="fas fa-book"></i>
                      <span className="nav-link-text">Catalogo</span>
                    </NavLink>
                  </NavItem>
                </>: <></>
              }
              {
                ([1, 2, 6].includes(role)) ? <> 
                  <NavItem>
                    <NavLink
                      to="/admin/reports"
                      tag={Link}>
                      <i className="far fa-file-alt"></i>
                      <span className="nav-link-text">Reportes</span>
                    </NavLink>
                  </NavItem>
                </>: <></>
              }

              {
                ([1, 2, 4].includes(role)) ? <> 
                  <NavItem>
                    <NavLink
                      data-toggle="collapse"
                      aria-expanded={webPageToggle}
                      onClick={() => {
                        setWebPageToggle(!webPageToggle)
                      }}
                    >
                      <i className="fas fa-laptop-code"></i>
                      <span className="nav-link-text">Pagina Web</span>
                    </NavLink>

                    <Collapse isOpen={webPageToggle}>
                      <Nav className="nav-sm flex-column">
                        <NavItem className={activeRoute(`/admin/company`)}>
                          <NavLink
                            to="/admin/company"
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <span className="sidenav-mini-icon">
                              <i className="far fa-circle"></i>
                            </span>
                            <span className="sidenav-normal"> Compañia </span>
                          </NavLink>
                        </NavItem>

                        <NavItem className={activeRoute(`/admin/blog`)}>
                          <NavLink
                            to="/admin/blog"
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <span className="sidenav-mini-icon">
                              <i className="far fa-circle"></i>
                            </span>
                            <span className="sidenav-normal"> Blog </span>
                          </NavLink>
                        </NavItem>

                        <NavItem className={activeRoute(`/admin/contact`)}>
                          <NavLink
                            to="/admin/contact"
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <span className="sidenav-mini-icon">
                              <i className="far fa-circle"></i>
                            </span>
                            <span className="sidenav-normal"> Contacto </span>
                          </NavLink>
                        </NavItem>

                        <NavItem className={activeRoute(`/admin/carousel`)}>
                          <NavLink
                            to="/admin/carousel"
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <span className="sidenav-mini-icon">
                              <i className="far fa-circle"></i>
                            </span>
                            <span className="sidenav-normal"> Carrusel </span>
                          </NavLink>
                        </NavItem>

                        <NavItem className={activeRoute(`/admin/services`)}>
                          <NavLink
                            to="/admin/services"
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <span className="sidenav-mini-icon">
                              <i className="far fa-circle"></i>
                            </span>
                            <span className="sidenav-normal"> Servicios </span>
                          </NavLink>
                        </NavItem>

                        <NavItem className={activeRoute(`/admin/faq`)}>
                          <NavLink
                            to="/admin/faq"
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <span className="sidenav-mini-icon">
                              <i className="far fa-circle"></i>
                            </span>
                            <span className="sidenav-normal"> FAQ </span>
                          </NavLink>
                        </NavItem>

                      </Nav>
                    </Collapse>
                  </NavItem>
                </>: <></>
              }

              {
                ([1, 2, 4].includes(role)) ? <> 
                  <NavItem>
                    <NavLink
                      data-toggle="collapse"
                      aria-expanded={personalizationToggle}
                      onClick={() => {
                        setPersonalizationToggle(!personalizationToggle)
                      }}
                    >
                      <i className="fas fa-desktop"></i>
                      <span className="nav-link-text">Personalización</span>
                    </NavLink>

                    <Collapse isOpen={personalizationToggle}>
                      <Nav className="nav-sm flex-column">
                        <NavItem className={activeRoute(`/admin/brand`)}>
                          <NavLink
                            to="/admin/brand"
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <span className="sidenav-mini-icon">
                              <i className="far fa-circle"></i>
                            </span>
                            <span className="sidenav-normal"> Marcas </span>
                          </NavLink>
                        </NavItem>

                        <NavItem className={activeRoute(`/admin/category`)}>
                          <NavLink
                            to="/admin/category"
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <span className="sidenav-mini-icon">
                              <i className="far fa-circle"></i>
                            </span>
                            <span className="sidenav-normal"> Categoría </span>
                          </NavLink>
                        </NavItem>

                      </Nav>
                    </Collapse>
                  </NavItem>
                </>: <></>
              }

              {
                ([1, 2, 4, 6].includes(role)) ? <> 
                  <NavItem>
                    <NavLink
                      data-toggle="collapse"
                      aria-expanded={databaseToggle}
                      onClick={() => {
                        setDatabaseToggle(!databaseToggle)
                      }}
                    >
                      <i className="fas fa-database"></i>
                      <span className="nav-link-text">Base de Datos</span>
                    </NavLink>

                    <Collapse isOpen={databaseToggle}>
                      <Nav className="nav-sm flex-column">
                        <NavItem className={activeRoute(`/admin/pieces`)}>
                          <NavLink
                            to="/admin/pieces"
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <span className="sidenav-mini-icon">
                              <i className="far fa-circle"></i>
                            </span>
                            <span className="sidenav-normal"> Piezas </span>
                          </NavLink>
                        </NavItem>
                        {
                          ([1, 2].includes(role)) ? <> 
                            <NavItem className={activeRoute(`/admin/warehouse`)}>
                              <NavLink
                                to="/admin/warehouse"
                                onClick={closeSidenav}
                                tag={NavLinkRRD}
                              >
                                <span className="sidenav-mini-icon">
                                  <i className="far fa-circle"></i>
                                </span>
                                <span className="sidenav-normal"> Almacenes </span>
                              </NavLink>
                            </NavItem>

                            <NavItem className={activeRoute(`/admin/clients`)}>
                              <NavLink
                                to="/admin/clients"
                                onClick={closeSidenav}
                                tag={NavLinkRRD}
                              >
                                <span className="sidenav-mini-icon">
                                  <i className="far fa-circle"></i>
                                </span>
                                <span className="sidenav-normal"> Clientes </span>
                              </NavLink>
                            </NavItem>
                          </>
                        : <></>
                        }
                      </Nav>
                    </Collapse>
                  </NavItem>
                </>: <></>
              }

              {
                ([1, 2].includes(role)) ? <> 
                  <NavItem>
                    <NavLink
                      to="/admin/users"
                      tag={Link}>
                      <i className="fas fa-users"></i>
                      <span className="nav-link-text">Usuarios</span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      to="/admin/notification"
                      tag={Link}>
                      <i className="fas fa-bell"></i>
                      <span className="nav-link-text">Notificaciones</span>
                    </NavLink>
                  </NavItem>
                </>: <></>
              }
              </Nav>

              <hr className="my-3" />
              <h6 className="navbar-heading p-0 text-muted">
                <span className="docs-normal">CONFIGURACIONES</span>
                <span className="docs-mini">
                  <i className="fas fa-cogs" />
                </span>
              </h6>

              <Nav className="mb-md-3" navbar>
                <NavItem>
                  <NavLink
                    to="/admin/profile"
                    tag={Link}>
                    <i className="ni ni-palette" />
                    <span className="nav-link-text">Perfil</span>
                  </NavLink>
                </NavItem>

                <NavItem style={{cursor: 'pointer'}}>
                  <NavLink
                    onClick={() => signOut()}>
                    <i className="fas fa-sign-out-alt" />
                    <span className="nav-link-text">Cerrar sesión</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </div>
      </PerfectScrollbar>
    </Navbar>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
};

Sidebar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  })
};

export default Sidebar;
