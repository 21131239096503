import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Header from "../../components/Headers/Header";
import {Spinner, Card, Col, CardHeader, Table, Container, Row, Badge, Button, CardBody} from "reactstrap";
import { getCatalogData } from '../../api/catalog';
import dayjs from 'dayjs';

const Catalog = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getCatalog();
  }, []);

  const getCatalog = async () => {
    setLoading(true);
    const res = await getCatalogData();

    if(res.success === true) {
      setError(false);
      setData(res.data);
    }
    else {
      setError(true);
      setData([]);
    }
    setLoading(false);
  }

  const checkActive = (i) => {
    return (i === 0) ? 
      <Badge
        style={{
          backgroundColor: '#cc415d',
          color: '#ffffff'
        }}
        className='badgePadding' 
        color="danger">No</Badge> :
      <Badge 
        style={{
          backgroundColor: '#14a969',
          color: '#ffffff'
        }}
        className='badgePadding' 
        color="success"> Si </Badge>;
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Catalogo </h3>
                  </div>
                  <div className="col">
                    <Button 
                      to={`/admin/catalog/new`} 
                      tag={Link}
                      className="float-right btn-sm"
                      style={{
                        backgroundColor:"#004899",
                        color: "#fff",
                        border: 0
                      }}> Nuevo catalogo </Button>
                  </div>
                </Row>
              </CardHeader>
              
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" />
                      <th scope="col"> Nombre </th>
                      <th scope="col"> Fecha </th>
                      <th scope="col"> Cargada </th>
                      <th scope="col" />
                    </tr>
                  </thead>

                  <tbody>
                    {
                      (loading) ?
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <Spinner style={{color: '#004899'}} />
                        </td>
                      </tr>:
                      (error) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'>
                          <label> Ha ocurrido un error, por favor intente de nuevo </label>
                          <br />
                          <Button 
                            color="secondary"
                            onClick={() => getCatalog()}>Cargar</Button>
                        </td>
                      </tr>:
                      (data.length === 0) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'> No se posee información </td>
                      </tr>
                      :
                      data.map( (d,i) => (
                        <tr key={i}>
                          <td>
                            {++i}
                          </td>
                          <td>
                            {d.name}
                          </td>
                          <td>
                            {dayjs(d.date).add(1, 'day').format('DD/MM/YYYY')}
                          </td>
                          <td>
                            {checkActive(d.active)}
                          </td>
                          <td className="text-right">
                            <Button 
                              size='sm'
                              style={{
                                backgroundColor:"#004899",
                                color: "#fff",
                                border: 0
                              }}
                              disabled={d.active === 0}
                              onClick={() => window.open(d.url)}
                            > Descargar </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Catalog;