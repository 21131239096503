import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function getBudgetsData() {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(CONS.budgets, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setBudgetsData(jsn) {
	const token = getToken('spm_token');

	try {
		const res = await axios.post(CONS.budgets, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getBudgetsByIdData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.budgets}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getBudgetsStatsByIdData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.budgets_stats}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getBudgetsSellerData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.budgets_seller}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getBudgetsBrandData(id, qty) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.budgets_brand}/${id}/${qty}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getBudgetsPiecesData(id, qty) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.budgets_piece}/${id}/${qty}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getBudgetsStatsGraphByIdData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.budgets_graph}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getBudgetsSellerGeneralData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.budgets_seller_gen}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updBudgetsData(jsn) {
	const token = getToken('spm_token');

	try {
		const res = await axios.put(CONS.budgets, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

/* GOALS */
export async function setGoalsData(jsn) {
  const token = getToken('spm_token');

  try {
    const res = await axios.post(CONS.goals, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getSellerData() {
  const token = getToken('spm_token');

  try {
    const res = await axios.get(`${CONS.goals}/seller`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getGoalsData() {
  const token = getToken('spm_token');

  try {
    const res = await axios.get(CONS.goals, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getGoalsByIdData(id) {
  const token  = getToken('spm_token');

  try {
    const res = await axios.get(`${CONS.goals}/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function updateGoalsData(jsn) {
	const token = getToken('spm_token');

	try {
		const res = await axios.put(CONS.goals, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getGoalsInfoData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.goals}/stats/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getGoalsSellerData(id) {
  const token = getToken('spm_token');

  try {
    const res = await axios.get(`${CONS.goals}/graph/profit/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getGoalsBrandData(id) {
  const token = getToken('spm_token');

  try {
    const res = await axios.get(`${CONS.goals}/graph/brand/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getGoalsPieceData(id) {
  const token = getToken('spm_token');

  try {
    const res = await axios.get(`${CONS.goals}/graph/pieces/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

