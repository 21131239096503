import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function getBasicStatsData(code) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.invoices}/basic/stats`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getInvoiceStatusData() {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.invoices}/status`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getAllInvoicesData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.invoices}/all/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getInvoicesByCode(code) {
	const token = getToken('spm_token');

	console.log(code);

	try {
		const res = await axios.get(`${CONS.invoices}/${code}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setInvoiceStatusData(status, id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.put(CONS.invoices , {'status': status, 'id': id}, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getInvoiceNotificationData() {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(CONS.notification_invoices, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getBasicTransitStatsData() {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.invoices}/transit/basic/stats`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getAllInvoicesTransitData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.invoices}/transit/all/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateClientData(jsn) {
  const token = getToken('spm_token');

  try {
    const res = await axios.put(CONS.clients, jsn, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}
