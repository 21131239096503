/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Header from "../../../components/Headers/Header";
import { Spinner, Col, Card, CardHeader, Table, Container, Row, Button, Input, InputGroupAddon, InputGroup, InputGroupText, CardBody } from "reactstrap";
import { getClientsData, getClientsPaginationData, getClientBySearch } from '../../../api/database';

const Clients = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [search, setSearch] = useState('');
  const [totalRow, setTotalRow] = useState('50');
  const [page, setPage] = useState({currentPage: 1, page: 1, total: 0})

  useEffect(() => {
    getClients();
  }, []);

  const findByText = async () => {
    const txt = String(search).trim();

    if(txt === '') {
      setData(data);
    }
    else {
      const res = await getClientBySearch(txt);
      setDataSearch(res.data);
    }  
  }

  const getClients = async () => {
    setLoading(true);
    const res = await getClientsData(totalRow);
    console.log(res);

    if(res.success === true) {
      setError(false);
      setPage({...page, total: Number(res.data.page.page)});
      setData(res.data.result);
      setDataSearch(res.data.result);
    }
    else {
      setError(true);
      setData([]);
      setDataSearch([]);
    }
    setLoading(false);
  }

  const getMoreRowsBySelect = async (val) => {
    setLoading(true);
    const res = await getClientsData(val);
 
    if(res.success === true) {
      setTotalRow(val);
      setError(false);
      setPage({...page, total: Number(res.data.page.page)});
      setData(res.data.result);
      setDataSearch(res.data.result);
    }
    else {
      setError(true);
      setData([]);
      setDataSearch([]);
    }
    setLoading(false);
  }

  const lessPage = async () => {
    setLoading(true);
    const cp = page.currentPage;
    const newCp = cp - 1;

    if(cp > 0) {
      const res = await getClientsPaginationData(totalRow, newCp)
      setPage({...page, currentPage: newCp});
      setDataSearch(res.data);
      setData(res.data);
    }
    setLoading(false);
  }

  const morePage = async () => {
    setLoading(true);
    const cp = page.currentPage;
    const newCp = cp + 1;

    if(cp < newCp) {
      const res = await getClientsPaginationData(totalRow, newCp);
      setPage({...page, currentPage: newCp});
      setDataSearch(res.data);
      setData(res.data);
    }
    setLoading(false);
  }

  return (
    <>
      <Header />
      
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Clientes </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className='paddingHorizonal'>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={search}
                      style={{paddingLeft: '5px', width: '70%'}}
                      placeholder="Buscar"
                      onChange={(val) =>  setSearch(val.target.value)}
                    />
      
                    <Button 
                      style={{
                        backgroundColor: '#004899',
                        borderColor: '#004899',
                        color: "#fff"
                      }}
                      onClick={() => findByText()}>
                      Buscar
                    </Button>
      
                    <select
                      className='form-control'
                      value={totalRow}
                      style={{
                        appearance: 'auto'
                      }}
                      onChange={(val) => getMoreRowsBySelect(val.target.value)}
                    >
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                    </select>
                  </InputGroup>
                  <br />
                </div>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" />
                      <th scope="col"> Codigo </th>
                      <th scope="col"> Nombre </th>
                      <th scope="col"> Contacto </th>
                      <th scope="col"> RIF </th>
                      <th scope="col"> Estado </th>
                      <th scope="col"> Ciudad </th>
                      <th scope="col" />
                    </tr>
                  </thead>

                  <tbody>
                    {
                      (loading) ?
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <Spinner style={{color: '#004899'}} />
                        </td>
                      </tr>:
                      (error) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'>
                          <label> Ha ocurrido un error, por favor intente nuevamente. </label>
                          <br />
                          <Button 
                            color="secondary"
                            onClick={() => getClients()}> Cargar </Button>
                        </td>
                      </tr>:
                      (data.length === 0) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'> No hay información registrada. </td>
                      </tr>
                      :
                      dataSearch.map( (d,i) => (
                        <tr key={i}>
                          <td>
                            {++i}
                          </td>
                          <td>
                            {String(d.code).trim()}
                          </td>
                          <td>
                            {String(d.name).trim()}
                          </td>
                          <td>
                            {String(d.contact).trim()}
                          </td>
                          <td>
                            {String(d.rif).trim()}
                          </td>
                          <td>
                            {String(d.state).trim()}
                          </td>
                          <td>
                            {String(d.city).trim()}
                          </td>
                          <td className="text-right">
                            <Button 
                              size='sm'
                              style={{
                                backgroundColor: '#004899',
                                borderColor: '#004899',
                                color: '#fff'
                              }}
                              to={`/admin/clients/${d.id}`} 
                              tag={Link}
                            > Ir </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>

                <div>
                  <ul id="ulArrow">
                    <li>
                      {
                        (page.currentPage === 1) ? <></> : <>
                          <i 
                            onClick={() => lessPage()}
                            className="arrowPage fas fa-chevron-left"></i>
                        </>
                      }
                    </li>
                    <li>
                      <span>{page.currentPage} / {page.total}</span>
                    </li>
                    <li>
                      {
                        (page.currentPage === page.total) ? <> </> : <>
                          <i 
                            onClick={() => morePage()}
                            className="arrowPage fas fa-chevron-right"></i>
                        </>
                      }
                    </li>
                  </ul>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Clients;