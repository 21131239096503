import { Container, Row, Col } from "reactstrap";

function AuthFooter() {

  return (
    <>
      <footer className="py-5">
        <Container fluid>
          <Row className="align-items-center">
            <Col xl="12">
              <div className="copyright text-center text-muted">
                <label>© {new Date().getFullYear()}</label>
                <label className="font-weight-bold ml-1 text-muted"> Ing. Andres Medina{'\xa0'}</label>
                <label>All rights reserved. </label>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default AuthFooter;
