/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, InputGroup, Table } from "reactstrap";
import moment from "moment";
import Header from "../../components/Headers/Header";
import { getInvoicesByCode, updateClientData, setInvoiceStatusData } from "../../api/invoices";
import NotificationAlert from "react-notification-alert";

export default function Invoices() {
  const notificationAlertRef = React.useRef(null)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [find, setFind] = useState(false);
  const [search, setSearch] = useState('');
  const [data, setData] = useState({});
  const [piece, setPiece] = useState([]);
  const [client, setClient] = useState({
    name: '',
    code: '',
    contact: '',
    rif: '',
    state: '',
    city: '',
    address: ''
  });

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getInvoices = async () => {
    setLoading(true);
    setFind(true);

    const res = await getInvoicesByCode(search);

    if(res.success === true) {
      setError(false);
      setData(res.data.invoices_data);
      setClient({
        name: res.data.invoices_data.client_name,
        code: res.data.invoices_data.client_code,
        contact: res.data.invoices_data.client_contact,
        rif: res.data.invoices_data.client_rif,
        state: res.data.invoices_data.client_state,
        city: res.data.invoices_data.client_city,
        address: res.data.invoices_data.client_address
      });
      setPiece(res.data.invoices_details);
    }
    else {
      setError(true);
    }

    setLoading(false);
  }

  const updateClient = async () => {
    
    if(String(client.name).trim() === '') {
      notifyAlert("danger", "Error", "Debe ingresar el nombre del cliente");
    }
    else if(String(client.code).trim() === '') {
      notifyAlert("danger", "Error", "Debe ingresar el codigo del cliente");
    }
    else if(String(client.contact).trim() === '') {
      notifyAlert("danger", "Error", "Debe ingresar el contacto del cliente");
    }
    else if(String(client.rif).trim() === '') {
      notifyAlert("danger", "Error", "Debe ingresar el rif del cliente");
    }
    else if(String(client.state).trim() === '') {
      notifyAlert("danger", "Error", "Debe ingresar el estado del cliente");
    }
    else if(String(client.city).trim() === '') {
      notifyAlert("danger", "Error", "Debe ingresar la ciudad del cliente");
    }
    else if(String(client.address).trim() === '') {
      notifyAlert("danger", "Error", "Debe ingresar la dirección del cliente");
    }
    else {
      const jsn = {
        "name": client.name,
        "code": client.code,
        "contact": client.contact,
        "rif": client.rif,
        "state": client.state,
        "city": client.city,
        "address": client.address,
        "invoice_id": data.id
      }
      
      const res = await updateClientData(jsn);

      if(res.success === true) {
        notifyAlert("success", "Exito", "Cliente actualizado");
      }
      else {
        notifyAlert("danger", "Error", "Se ha presentado un error. Intente nuevamente");
      }
    }


  }

  const setInvoiceStatus = async () => {
    setLoading(true);

    const res = await setInvoiceStatusData(5, data.code);
    
    if(res.success === true) {
      setLoading(false);
      notifyAlert("success", "Mensaje", "Pedido anulado");
      getInvoices();
    }
    else {
      notifyAlert("danger", "Mensaje", "Error procesar el pedido");
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Factura </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>

                <Row>
                  <InputGroup>
                    <Input
                      value={search}
                      style={{paddingLeft: '10px', paddingRight: '10px', width: '40%'}}
                      placeholder="Buscar..."
                      onChange={(val) =>  setSearch(val.target.value)}
                    />
                    <Button
                      style={{
                        backgroundColor: '#004899',
                        color: '#ffffff'
                      }}
                      onClick={() => getInvoices()}> Buscar </Button>
                  </InputGroup>
                </Row>

                <Row>
                  {
                    (!find) ? 
                    <> 
                      <div className="text-center">
                        <br />
                        <label className='text-muted'> Ingrese el número de la factura para visualizarla </label>
                      </div>
                    </> : 
                    <Col>
                      {
                        (loading) ?
                          <div className="text-center">
                            <br />
                            <Spinner style={{color: '#004899'}} />
                          </div>:
                        (error) ? 
                          <div>
                            <div className='text-muted text-center'>
                              <br />
                              <label> Se ha presentado un error. Intente nuevamente mas tarde </label>
                            </div>
                          </div>: 
                          <div>
                            <br />
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Factura </label>
                                  <Input
                                    value={data.code}
                                    className="form-control" readOnly={true}/>
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Estatus </label>
                                  <Input
                                    value={data.status}
                                    className="form-control" readOnly={true}/>
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Total a pagar </label>
                                  <Input
                                    value={`$${data.total}`}
                                    className="form-control" readOnly={true}/>
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Fecha </label>
                                  <Input
                                      value={moment(new Date(data.date)).subtract(4, 'hour').format('L')}
                                    className="form-control" readOnly={true}/>
                                </FormGroup>
                              </Col>

                              <Col lg="12">
                                <FormGroup>
                                  <label className="form-control-label"> Comentario </label>
                                  <textarea 
                                    value={data.comment}
                                    className="form-control" readOnly={true}>
                                  </textarea>
                                </FormGroup>
                              </Col>
                            </Row>

                            <h3>Datos del vendedor</h3>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Nombre </label>
                                  <Input
                                    value={data.seller_name}
                                    className="form-control" readOnly={true}/>
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Correo Electronico </label>
                                  <Input
                                    value={data.seller_email}
                                    className="form-control" readOnly={true}/>
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Telefono </label>
                                  <Input
                                    value={data.seller_phone}
                                    className="form-control" readOnly={true}/>
                                </FormGroup>
                              </Col>
                            </Row>

                            <h3>Datos del cliente</h3>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Nombre </label>
                                  <Input
                                    value={client.name}
                                    className="form-control" 
                                    onChange={(val) => setClient({...client, name: val.target.value})}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Codigo (Premium) </label>
                                  <Input
                                    value={client.code}
                                    className="form-control"
                                    onChange={(val) => setClient({...client, code: val.target.value})}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Contacto </label>
                                  <Input
                                    value={client.contact}
                                    className="form-control"
                                    onChange={(val) => setClient({...client, contact: val.target.value})}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> RIF </label>
                                  <Input
                                    value={client.rif}
                                    className="form-control"
                                    onChange={(val) => setClient({...client, rif: val.target.value})}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Estado </label>
                                  <Input
                                    value={client.state}
                                    className="form-control"
                                    onChange={(val) => setClient({...client, state: val.target.value})}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Ciudad </label>
                                  <Input
                                    value={client.city}
                                    className="form-control"
                                    onChange={(val) => setClient({...client, city: val.target.value})}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <label className="form-control-label"> Dirección </label>
                                  <textarea 
                                    value={client.address}
                                    className="form-control"
                                    onChange={(val) => setClient({...client, address: val.target.value})}>
                                  </textarea>
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="12" style={{
                                textAlign: 'right'
                              }}>
                                <Button
                                  style={{
                                    backgroundColor: '#004899',
                                    color: '#ffffff'
                                  }}
                                  onClick={() => updateClient()}> Actualizar </Button>
                              </Col>
                              <br />
                            </Row>

                            <h3> Detalle </h3>
                            <Row>
                              <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col" />
                                    <th scope="col"> Imagen </th>
                                    <th scope="col"> Nombre </th>
                                    <th scope="col"> Marca </th>
                                    <th scope="col"> Cantidad </th>
                                    <th scope="col"> Precio </th>
                                    <th scope="col"> Almacen </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {
                                    (loading) ?
                                    <tr>
                                      <td colSpan={10} className='text-center'>
                                        <Spinner style={{color: '#004899'}} />
                                      </td>
                                    </tr>:
                                    (error) ? 
                                    <tr>
                                      <td colSpan={10} className='text-muted text-center'>
                                        <label>Se ha presentado un error</label>
                                        <br />
                                        <Button 
                                          color="secondary"
                                          onClick={() => getInvoices()}> Cargar </Button>
                                      </td>
                                    </tr>:
                                    (piece.length === 0) ? 
                                    <tr>
                                      <td colSpan={10} className='text-muted text-center'> No hay información registrada </td>
                                    </tr>
                                    :
                                    piece.map( (d,i) => (
                                      <tr key={i}>
                                        <td>
                                          {++i}
                                        </td>
                                        <td>
                                          <img 
                                            alt='Error'
                                            style={{
                                              width: '64px',
                                              height: '64px',
                                              objectFit:'scale-down'
                                            }}
                                            src={d.picture}/>
                                        </td>
                                        <td>
                                          {String(d.name).trim()}
                                        </td>
                                        <td>
                                          {d.brand}
                                        </td>
                                        <td>
                                          {d.quantity}
                                        </td>
                                        <td>
                                          ${d.price}
                                        </td>
                                        <td>
                                          {d.warehouse_name}
                                        </td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </Table>
                            </Row>
                            <Row>
                              <Col lg="12" style={{
                                  textAlign: 'right'
                                }}>
                                <Button color="danger" onClick={() => setInvoiceStatus()} > Anular </Button>
                              </Col>
                            </Row>
                          </div>
                      }
                    </Col>
                  }
                </Row>
              </CardBody>

            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
