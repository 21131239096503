/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Spinner, Button, Card, CardHeader, CardBody, Container, Row, Col, InputGroup, Table} from "reactstrap";
import moment from 'moment';
import Header from "../../components/Headers/Header";
import { getBasicStatsData, getInvoiceStatusData, getAllInvoicesData } from './../../api/invoices';

const Orders = () => {
  const [loading, setLoading] = useState({stats: true, table: true});
  const [error, setError] = useState(false);
  const [stats, setStats] = useState({});
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [selStatus, setSelStatus] = useState('');

  useEffect(() => {
    runFunc();
  }, []);

  const runFunc = async () => {
    await getBasicStats();
    await getInvoiceStatus();
    getAllInvoices(selStatus);
  }

  const getBasicStats = async () => {
    const res = await getBasicStatsData();
    setLoading({...loading, stats: false});
    setStats(res);
  }

  const getInvoiceStatus = async () => {
    const res = await getInvoiceStatusData();
    
    setStatus(res);
  }

  const getAllInvoices = async(id) => {
    const val = (id === '') ? 1 : id;
    setSelStatus(val);
    const res = await getAllInvoicesData(val);

    if(res.success === true) {
      setData(res.data);
      setError(false);
    }
    else {
      setError(true);
    }

    setLoading({stats:false, table: false});
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h2> Ordenes </h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Container>
                      <Row>
                        {
                          (loading.stats) ?
                          <div 
                            style={{
                              width: "100%",
                              marginBottom: "20px"
                            }}
                            className="text-center">
                            <Spinner style={{color: '#004899'}} />
                          </div> : 
                          <>
                            <Col>
                              <Card style={{border: "1px solid #999"}}>
                                <CardHeader style={{
                                  backgroundColor: '#004899',
                                }}>
                                  <h2 className="text-center" style={{color: '#fff'}}>
                                    Total
                                  </h2>
                                </CardHeader>
                                <CardBody>
                                  <h2 
                                    className="text-center" 
                                    style={{color: '#004899'}}>
                                      {stats.total}
                                  </h2>
                                </CardBody>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{border: "1px solid #999"}}>
                                <CardHeader style={{
                                  backgroundColor: '#004899',
                                }}>
                                  <h2 className="text-center" style={{color: '#fff'}}>
                                    Aprobadas
                                  </h2>
                                </CardHeader>
                                <CardBody>
                                  <h2 
                                    className="text-center" 
                                    style={{color: '#004899'}}>
                                      {stats.approved}
                                  </h2>
                                </CardBody>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{border: "1px solid #999"}}>
                                <CardHeader style={{
                                  backgroundColor: '#004899',
                                }}>
                                  <h2 className="text-center" style={{color: '#fff'}}>
                                    Pendientes
                                  </h2>
                                </CardHeader>
                                <CardBody>
                                  <h2 
                                    className="text-center" 
                                    style={{color: '#004899'}}>
                                      {stats.pending}
                                  </h2>
                                </CardBody>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{border: "1px solid #999"}}>
                                <CardHeader style={{
                                  backgroundColor: '#004899',
                                }}>
                                  <h2 className="text-center" style={{color: '#fff'}}>
                                    Rechazadas
                                  </h2>
                                </CardHeader>
                                <CardBody>
                                  <h2 
                                    className="text-center" 
                                    style={{color: '#004899'}}>
                                      {stats.cancel}
                                  </h2>
                                </CardBody>
                              </Card>
                            </Col>

                            <Col>
                              <Card style={{border: "1px solid #999"}}>
                                <CardHeader style={{
                                  backgroundColor: '#004899',
                                }}>
                                  <h2 className="text-center" style={{color: '#fff'}}>
                                    Canceladas
                                  </h2>
                                </CardHeader>
                                <CardBody>
                                  <h2 
                                    className="text-center" 
                                    style={{color: '#004899'}}>
                                      {stats.nullify}
                                  </h2>
                                </CardBody>
                              </Card>
                            </Col>
                          </>
                        }
                      </Row>
                    </Container>
                  </Col>
                </Row>
                
                <Row>
                  <Col>
                    <div>
                      <InputGroup>
                        <select
                          className='form-control'
                          style={{
                            appearance: 'auto',
                            width: '30%'
                          }}
                          value={selStatus}
                          onChange={(val) => getAllInvoices(val.target.value)}
                        >
                          {
                            status.map( (s,i) => (
                              <option 
                                key={i}
                                value={s.id}>{s.name}</option>
                            ))
                          }
                        </select>
                      </InputGroup>
                      <br />
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" />
                            <th scope="col"> Codigo </th>
                            <th scope="col"> Usuario </th>
                            <th scope="col"> Cliente </th>
                            <th scope="col"> Contacto </th>
                            <th scope="col"> Total </th>
                            <th scope="col"> Fecha </th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (loading.table) ?
                            <tr>
                              <td colSpan={10} className='text-center'>
                                <Spinner style={{color: '#004899'}} />
                              </td>
                            </tr>:
                            (error) ? 
                            <tr>
                              <td colSpan={10} className='text-muted text-center'>
                                <label>Ha ocurrido un error, vuelva a intentar. </label>
                                <br />
                                <Button 
                                  color="secondary"
                                  onClick={() => getAllInvoices("1")}>Cargar</Button>
                              </td>
                            </tr>:
                            (data.length === 0) ? 
                            <tr>
                              <td colSpan={10} className='text-muted text-center'> No hay registros almacenados </td>
                            </tr>
                            :
                            data.map( (d,i) => (
                              <tr key={i}>
                                <td>
                                  {++i}
                                </td>
                                <td>
                                  {d.code}
                                </td>
                                <td>
                                  {String(d.user_name).trim()}
                                </td>
                                <td>
                                  {String(d.client_name).trim()}
                                </td>
                                <td>
                                  {String(d.client_contact).trim()}
                                </td>
                                <td>
                                  ${d.total}
                                </td>
                                <td>
                                  {moment(new Date(d.date)).subtract(4, 'hour').format('L')}
                                </td>
                                <td className="text-right">
                                  <Button 
                                    size="sm"
                                    style={{
                                      backgroundColor: '#004899',
                                      borderColor: '#004899',
                                      color: '#fff'
                                    }}
                                    to={`/admin/orders/${d.code}`} 
                                    tag={Link}
                                  > Visualizar </Button>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>

            </Card>
          </Col>
        </Row>

    </Container>
    </>

  )

}

export default Orders;