import React from "react";
import ReactDOM from "react-dom";

import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
import "assets/css/global.css";
import App from './App';

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
