import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";


export async function getAllReportData() {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.get(`${CONS.report}/pieces` , {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getReportByCategoryData(id) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.get(`${CONS.report}/category/${id}` , {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getReportByBrandCategoryData(brand, category) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.get(`${CONS.report}/${brand}/${category}` , {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getReportByStatusBillsData(status) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.get(`${CONS.report}/status/${status}` , {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getReportByStatusBillsDateData(init, end) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.post(`${CONS.report}/date/status`, {
      'initDate': init,
      'endDate': end
    }, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getReportApproveDateData(apDate) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.get(`${CONS.report}/approve/${apDate}` , {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}