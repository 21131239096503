import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Header from "../../../components/Headers/Header";
import {Spinner, Card, CardHeader, Table, Container, Row, Col, CardBody, Badge, Button, Input, InputGroupAddon, InputGroup, InputGroupText} from "reactstrap";
import { getCategoryData } from '../../../api/database';

const Category = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    setLoading(true);
    const res = await getCategoryData();
    console.log(res);

    if(res.success === true) {
      setError(false);
      setData(res.data);
      setDataSearch(res.data);
    }
    else {
      setError(true);
      setData([]);
      setDataSearch([]);
    }
    setLoading(false);
  }

  const handleSearch = (text) => {
    if (text) {
      const newData = data.filter( (item) => {
        const itemData = item.name
          ? item.name.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setSearch(text);
      setDataSearch(newData);
    } 
    else {
      setSearch(text);
      setDataSearch(data);
    }   
  }

  const checkActive = (i) => {
    return (i === 0) ? 
      <Badge
        style={{
          backgroundColor: '#cc415d',
          color: '#ffffff'
        }}
        className='badgePadding'> No </Badge> :
      <Badge 
        style={{
          backgroundColor: '#14a969',
          color: '#ffffff'
        }}
        className='badgePadding'> Si </Badge>;
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Categoría </h3>
                  </div>
                  <div className="col">
                    <Button 
                      to={`/admin/category/new`} 
                      tag={Link}
                      className="float-right btn-sm"
                      style={{
                        backgroundColor:"#004899",
                        color: "#fff",
                        border: 0
                      }}>Nueva categoría</Button>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className='paddingHorizonal'>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={search}
                      style={{paddingLeft: '5px'}}
                      placeholder="Buscar"
                      onChange={(val) =>  handleSearch(val.target.value)}
                      />
                  </InputGroup>
                  <br />
                </div>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" />
                      <th scope="col"> Imagen </th>
                      <th scope="col"> Name </th>
                      <th scope="col"> Codigo </th>
                      <th scope="col"> Active </th>
                      <th scope="col" />
                    </tr>
                  </thead>

                  <tbody>
                    {
                      (loading) ?
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <Spinner style={{color: '#004899'}} />
                        </td>
                      </tr>:
                      (error) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'>
                          <label> Se ha presentado un error, intente de nuevo mas tarde. </label>
                          <br />
                          <Button 
                            color="secondary"
                            onClick={() => getCategory()}>Cargar</Button>
                        </td>
                      </tr>:
                      (data.length === 0) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'> No hay registro almacenados. </td>
                      </tr>
                      :
                      dataSearch.map( (d,i) => (
                        <tr key={i}>
                          <td>
                            {++i}
                          </td>
                          <td>
                            <img 
                              alt='Error'
                              style={{
                                width: '64px',
                                height: '64px'
                              }}
                              src={d.picture}/>
                          </td>
                          <td>
                            {d.name}
                          </td>
                          <td>
                            {d.code}
                          </td>
                          <td>
                            {checkActive(d.active)}
                          </td>
                          <td className="text-right">
                            <Button 
                              size='sm'
                              style={{
                                backgroundColor:"#004899",
                                color: "#fff",
                              }}
                              to={`/admin/category/${d.id}`} 
                              tag={Link}
                            > Editar </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Category;