/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Spinner } from "reactstrap";
import { AuthContext } from "./context/AuthContext";
import { getToken, setToken, deleteToken } from './utils/token';
import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";
import { checkSession } from './api/auth';

export default function App() {
  const initialLoginState = {
    isLoading: true,
    isSignout: false,
    spm_token: null
  }

  const loginReducer = (prevState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case 'RESTORE_TOKEN':
        return {
          ...prevState,
          spm_token: action.token,
          isLoading: false,
        };
      case 'LOGIN':
        return {
          ...prevState,
          spm_token: action.token,
          isLoading: false,
        };
      case 'LOGOUT':
        return {
          ...prevState,
          spm_token: null,
          isLoading: false,
        };
    }
  }
  
  const [loginState, dispatch] = useReducer(loginReducer, initialLoginState);

  const authContext = React.useMemo(
    () => ({
      signIn: async data => {
        try {
          setToken('spm_token', data);
        } 
        catch (e) {
          console.log(e)
        }
       
        dispatch({ type: 'LOGIN', token: data });
      },
      signOut: () => {
        deleteToken('spm_token')
        dispatch({ type: 'LOGOUT', token: null });
      },
    }),[]);

  useEffect(() => {
      const bootstrapAsync = async () => {  
      let userToken;

      const res = await checkSession();

      if(res.success === true) {
        userToken = getToken('spm_token');
      }
      else {
        userToken = null;
        setToken('spm_token', '');
        //deleteToken('spm_token'); check later!
      }

      dispatch({ type: 'RESTORE_TOKEN', token: userToken });
    };

    bootstrapAsync();
  }, []);

  return (
    <>
      {
        (loginState.isLoading) ?
          <div style={{
            position: 'absolute',
            display: 'block',
            top: '50%',
            left: '50%',
          }}>
            <Spinner color="primary" /> 
          </div>:
          <>
            <AuthContext.Provider value={authContext}>
              <BrowserRouter>
                {
                  loginState.spm_token === null ? 
                    ( 
                      <Switch>
                        <Route path="/" render={(props) => <AuthLayout {...props} />} />
                        
                        <Redirect from="*" to="/" />
                      </Switch>
                      ) :
                    ( 
                      <Switch>
                        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                        <Redirect from="*" to="/admin" />
                      </Switch>
                    )
                }
              </BrowserRouter>
            </AuthContext.Provider>
          </>
      }
    </>
  )
}
