/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../components/Headers/Header";
import { getBudgetsByIdData, updBudgetsData } from "../../api/budgets";
import NotificationAlert from "react-notification-alert";
import dayjs from "dayjs";

const EditBudgets = () => {
  const { id } = useParams();
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [initDate, setInitDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [goals, setGoals] = useState('');

  useEffect( () => {
    getBudgetsById();
  }, []);
  
  const getBudgetsById = async () => {
    const res = await getBudgetsByIdData(id);
    console.log(res.data);

    if(res.success === true) {
      setTitle(res.data.title);
      setDescription(res.data.description);
      setInitDate(dayjs(res.data.init_date).add(1, 'd').format('YYYY-MM-DD'));
      setEndDate(dayjs(res.data.end_date).add(1, 'd').format('YYYY-MM-DD'));
      setGoals(res.data.goals);
    }
    else {
      setError(true);
    }
    setLoading(false);
  }

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span> <br />
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const updNewBudgets = async () => {
    setLoading(true);

    if(title.trim() === "") {
      notifyAlert('warning', "Mensaje", "El campo título es obligatorio");
    }
    else if(initDate === "") {
      notifyAlert('warning', "Mensaje", "El campo fecha de inicio es obligatorio");
    }
    else if(endDate === '') {
      notifyAlert('warning', "Mensaje", "El campo fecha de finalización es obligatorio");
    }
    else if(dayjs(endDate) <= dayjs(initDate)) {
      notifyAlert('warning', "Mensaje", "La fecha de finalización debe ser mayor a la fecha de inicio");
    }
    else if(String(goals).trim() === '') {
      notifyAlert('warning', "Mensaje", "El campo metas es obligatorio");
    }
    else if(Number(goals) === 0){
      notifyAlert('warning', "Mensaje", "El campo metas debe ser mayor a 0");
    }
    else {
      const jsn = {
        "id": id,
        "title": title,
        "description":description,
        "initDate": initDate,
        "endDate": endDate,
        "goals": goals
      }

      const res = await updBudgetsData(jsn);

      if(res.success === true) {
        notifyAlert('success', "Mensaje", "Presupuesto actualizado correctamente");
      }
      else {
        notifyAlert('danger', "Mensaje", "Error al actualizar el presupuesto");
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Presupuesto </h3>
                  </div>
                </Row>
              </CardHeader>
              
              <CardBody>
                <div className='paddingHorizonal'>
                  {
                    (loading) ? <>
                      <div className='text-center'>
                        <Spinner style={{color: '#004899'}} />
                      </div>
                    </> : <>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label"> Titulo </label>
                            <Input
                              value={title}
                              className="form-control"
                              onChange={val => setTitle(val.target.value)}/>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label"> Descripcion (opcional) </label>
                            <textarea 
                              style={{
                                height: '100px',
                                resize: 'none'
                              }}
                              value={description}
                              className="form-control"
                              onChange={(val) => setDescription(val.target.value)} />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Fecha inicial </label>
                            <Input
                              type="date"
                              value={initDate}
                              className="form-control"
                              onChange={val => setInitDate(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Fecha final </label>
                            <Input
                              type="date"
                              value={endDate}
                              className="form-control"
                              onChange={val => setEndDate(val.target.value)}/>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Metas </label>
                            <Input
                              type="number"
                              value={goals}
                              className="form-control"
                              onChange={val => setGoals(val.target.value)}/>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" >
                          <br />
                          <div className="float-right">
                            {
                              (loading) ? <Spinner style={{color: '#004899'}}/> :
                              <div>
                                <Button color="gray" to="/admin/budgets" tag={Link}> Regresar </Button>
                                <Button
                                  style={{
                                    backgroundColor: '#004899',
                                    color: '#fff',
                                    border: 0
                                  }}
                                  onClick={() => updNewBudgets()}
                                  > Guardar
                                </Button>
                              </div>
                            } 
                          </div>
                        </Col>
                      </Row>
                    </>
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

    </>
  )
}

export default EditBudgets;