/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, {useState, useEffect} from "react";
import { Spinner } from "reactstrap";
import { useLocation, Route, Switch } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminFooter from "components/Footers/AdminFooter";
import Sidebar from "components/Sidebar/Sidebar";
import { getTokenData, getColor} from "utils/token";
import { routes } from "routes";

function Admin() {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [picture, setPicture] = useState('');
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);

  useEffect(() => {
    getData();
  },[]);
  
  const getData = async () => {
    const _name = getTokenData('spm_token', 'name');
    const _picture = getTokenData('spm_token', 'picture');
    setName(_name);
    setPicture(_picture);
    setLoading(false);
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            exact={true}
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  return (
    <>
      {
        (loading) ? <>
          <div style={{
            position: 'absolute',
            display: 'block',
            top: '50%',
            left: '50%',
          }}>
            <Spinner style={{color: getColor(1), width: '3rem', height: '3rem' }}/>
          </div>
        </> :
        <>
          <Sidebar
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            logo={{
              innerLink: "/",
              imgSrc: require("assets/img/logo.png").default,
              imgAlt: "...",
            }}
          />

          <div className="main-content" ref={mainContentRef}>
            <AdminNavbar
              name={name}
              picture={picture}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              brandText={getBrandText(location.pathname)}
            />

            <Switch>
              {getRoutes(routes)}
              {/* 
                <Route component={NotFound} />
                <Redirect from="*" to="/admin/" /> 
              */}
            </Switch>
            <AdminFooter />

          </div>

          {sidenavOpen ? (
            <div className="backdrop d-xl-none" onClick={toggleSidenav} />
          ) : null}
        </>
      }
    </>
  );
}

export default Admin;
