/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import { Spinner, Card, Container, CardHeader, Row, Button, Col, CardBody, Table } from "reactstrap";
import { Bar } from "react-chartjs-2";
import Header from "../../components/Headers/Header";
import { getBudgetsByIdData, getBudgetsStatsByIdData, getBudgetsSellerData, getBudgetsBrandData, getBudgetsPiecesData, getBudgetsStatsGraphByIdData, getBudgetsSellerGeneralData } from "../../api/budgets";
import dayjs from "dayjs";

export default function ViewBudgets() {
  const { id } = useParams();
  const [loading, setLoading] = useState({
    init: false,
    stats: false,
    seller: false,
    brand: false,
    piece: false,
    graph: false,
    seller_general:false
  });
  const [data, setData] = useState({title: "", description: "", init_date: "", end_date: "", goals: ''});
  const [stats, setStats] = useState({sold: '0', piece: '0', objective: '0'});
  const [arrSeller, setArrSeller] = useState({legend: [] ,labels: [], datasets: [{ label: 'Vendedores', backgroundColor: '#004899', data: [] }]});
  const [brand, setBrand] = useState([]);
  const [pieces, setPiece] = useState([]);
  const [seller, setSeller] = useState([]);
  const [error, setError] = useState(false);
  const [selection, setSelection] = useState({brand: '5', piece: '5'});
  const [graphData, setGraphData] = useState({legend: [] ,labels: [], datasets: [{ label: 'Vendedores', backgroundColor: '#004899', data: [] }]});

  useEffect(() => {
    funct();
  }, []);

  const funct = async () => {
    await getBudgetsById();
    await getBudgetsStatsById();
    await getBudgetsSeller();
    await getBudgetsBrand();
    await getBudgetsPieces();
    await getBudgetsStatsGraphById();
    await getBudgetsSellerGeneral();
  }

  const getBudgetsById = async () => {
    setLoading({...loading, init: true});
    const res = await getBudgetsByIdData(id);

    if(res.success === true) {
      setError(false);
      setData({
        title: res.data.title,
        description: res.data.description,
        end_date: res.data.end_date,
        init_date: res.data.init_date,
        goals: res.data.goals});
    }
    else {
      setError(true);
    }
    setLoading({...loading, init: false});
  }

  const getBudgetsStatsById = async () => {
    setLoading({...loading, stats: true});
    const res = await getBudgetsStatsByIdData(id);

    if(res.success === true) {
      setError(false);
      setStats({
        sold: res.data.sold,
        piece: res.data.pieces,
        objective: res.data.objective
      });
    }
    else {
      setError(true);
    }
    setLoading({...loading, stats: false});
  }

  const getBudgetsSeller = async () => {
    setLoading({...loading, seller: true});
    const res = await getBudgetsSellerData(id);

    const data = res.data;
    const valuesText = data.map( (v) => {
      return v.Name
    });
    const valuesData = data.map( (v) => {
      return v.Total
    });

    
    if(res.success === true) {
      setError(false);
      setArrSeller({
        legend: data,
        labels: valuesText, 
        datasets: [{
        label: 'Vendedores',
        backgroundColor: '#004899',
        data: valuesData
      }]});
    }
    else {
      setError(true);
    }
    setLoading({...loading, seller: false});
  }

  const getBudgetsBrand = async(val) => {
    val = (val === undefined) ? selection.brand : val;

    setLoading({...loading, brand: true});
    const res = await getBudgetsBrandData(id, val);

    if(res.success === true) {
      setError(false);
      setBrand(res.data);
    }
    else {
      setError(true);
    }
    setLoading({...loading, brand: false});
  }

  const getBudgetsPieces = async(val) => {
    val = (val === undefined) ? selection.piece : val;

    setLoading({...loading, piece: true});
    const res = await getBudgetsPiecesData(id, val);

    if(res.success === true) {
      setError(false);
      setPiece(res.data);
    }
    else {
      setError(true);
    }
    setLoading({...loading, piece: false});
  }

  const getSelectionBrand = async (val) => {
    getBudgetsBrand(val);
    setSelection({...selection, brand: val});
  }
  
  const getSelectionPiece = async (val) => {
    console.log(val);
    getBudgetsPieces(val);
    setSelection({...selection, piece: val});
  }

  const getBudgetsStatsGraphById = async() => {
    setLoading({...loading, graph: true});
    const res = await getBudgetsStatsGraphByIdData(id);
    const data = res.data;

    const valuesText = data.map( (v) => {
      return dayjs(v.date).add(1, 'd').format("DD-MM-YYYY")
    });
    const valuesData = data.map( (v) => {
      return v.total
    });

    if(res.success === true) {
      setError(false);
      setGraphData({
        legend: data,
        labels: valuesText, 
        datasets: [{
        label: 'Dias',
        backgroundColor: '#004899',
        data: valuesData
      }]});
    }
    else {
      setError(true);
    }

    setLoading({...loading, graph: false});
  }

  const getBudgetsSellerGeneral = async() => {
    setLoading({...loading, seller_general: true});
    const res = await getBudgetsSellerGeneralData(id);

    if(res.success === true) {
      setError(false);
      setSeller(res.data);
    }
    else {
      setError(true);
    }
    setLoading({...loading, seller_general: false});
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Presupuesto </h3>
                  </div>
                </Row>
              </CardHeader>
              
              <CardBody>
                <div className='paddingHorizonal'>
                  <Row>
                    <Container>
                      {
                        (loading.init) ? <div>
                          <Spinner size="sm" style={{color: '#004899'}} />
                        </div> : <>
                          <h4>{data.title}</h4>
                          {
                            (data.description !== '') ? 
                              <p>{data.description}</p>
                            :<></>
                          }
                        </>
                      }
                    </Container>
                  </Row>

                  <Row>
                    <Col className="mb-5 mb-xl-0">
                      <Card className="bg-gradient-default shadow">
                        <CardHeader className="bg-transparent border-0">
                          <Row className="align-items-center">
                            <div className="col">
                              <h3 className="mb-0"
                              style={{
                                color: '#fff',
                                textAlign: 'center'
                              }}>Piezas</h3>
                            </div>
                          </Row>
                        </CardHeader>
                        <CardBody style={{
                          backgroundColor: '#ffffff',
                          textAlign: 'center'
                        }}>
                          {
                            (loading.stats) ? <div className="text-center">
                              <Spinner size="md" style={{color: '#fff'}} />
                            </div> : <>
                              <h2 className="mb-0"> {stats.piece} </h2>
                            </>
                          }
                        </CardBody>
                      </Card>
                    </Col>

                    <Col className="mb-5 mb-xl-0">
                      <Card className="bg-gradient-default shadow">
                        <CardHeader className="bg-transparent border-0">
                          <Row className="align-items-center">
                            <div className="col">
                              <h3 className="mb-0"
                              style={{
                                color: '#fff',
                                textAlign: 'center'
                              }}>Ventas</h3>
                            </div>
                          </Row>
                        </CardHeader>
                        <CardBody style={{
                          backgroundColor: '#fff',
                          textAlign: 'center'
                        }}>
                          {
                            (loading.stats) ? <div className="text-center">
                              <Spinner size="md" style={{color: '#fff'}} />
                            </div> : <>
                              <h2 className="mb-0"> {stats.sold} </h2>
                            </>
                          }
                        </CardBody>
                      </Card>
                    </Col>

                    <Col className="mb-5 mb-xl-0">
                      <Card className="bg-gradient-default shadow">
                        <CardHeader className="bg-transparent border-0">
                          <Row className="align-items-center">
                            <div className="col">
                              <h3 className="mb-0"
                              style={{
                                color: '#fff',
                                textAlign: 'center'
                              }}> Meta </h3>
                            </div>
                          </Row>
                        </CardHeader>
                        <CardBody style={{
                          backgroundColor: '#fff',
                          textAlign: 'center'
                        }}>
                          {
                            (loading.init) ? <div className="text-center">
                              <Spinner size="md" style={{color: '#fff'}} />
                            </div> : <>
                              <h2 className="mb-0">${data.goals} </h2>
                            </>
                          }
                        </CardBody>
                      </Card>
                    </Col>

                    <Col className="mb-5 mb-xl-0">
                      <Card className="bg-gradient-default shadow">
                        <CardHeader className="bg-transparent border-0">
                          <Row className="align-items-center">
                            <div className="col">
                              <h3 className="mb-0" 
                              style={{
                                color: '#fff',
                                textAlign: 'center'
                              }}> Indicador </h3>
                            </div>
                          </Row>
                        </CardHeader>
                        <CardBody style={{
                          backgroundColor: '#fff',
                          textAlign: 'center'
                        }}>
                          {
                            (loading.stats) ? <div className="text-center">
                              <Spinner size="md" style={{color: '#fff'}} />
                            </div> : <>
                              <h2 className="mb-0" style={{
                                color: (parseFloat(stats.objective) >= parseFloat(data.goals)) ? '#00a100': '#b70000'
                              }}>${stats.objective} </h2>
                            </>
                          }
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      width: '100%'
                    }}>
                      {
                        (loading.init) ? <div className="text-center">
                          <Spinner size="md" style={{color: '#004899'}} />
                        </div> : <>
                          <h3><i style={{
                            fontSize: '18px',
                            display: 'inline-flex',
                            position: 'relative',
                            top: '-4px',
                            left: '-6px'
                          }}className="far fa-calendar"></i>{dayjs(data.init_date).add(1, 'd').format("DD-MM-YYYY")}</h3>
                          <h3>-</h3>
                          <h3><i style={{
                            fontSize: '18px',
                            display: 'inline-flex',
                            position: 'relative',
                            top: '-4px',
                            left: '-6px'
                          }}className="far fa-calendar"></i>{dayjs(data.end_date).add(1, 'd').format("DD-MM-YYYY")}</h3>
                        </>
                      }
                    </div>
                  </Row>
                  
                  <Row>
                    <div style={{
                      marginTop: '40px',
                      width: '100%'
                    }}>
                      {
                        (loading.seller) ? <div className="text-center">
                          <Spinner style={{color: '#004899'}} />
                        </div> : <>
                          <div>
                            <h3>Ventas por vendedor</h3>
                          </div>
                          <Row>
                            <Col md="12">
                              <Bar
                                data={arrSeller}
                                options= {{maintainAspectRatio: false}}
                              />
                            </Col>
                          </Row>
                        </>
                      }
                    </div>
                  </Row>
                  
                  <Row>
                    <Col md="6">
                      <div style={{
                        marginTop: '40px'
                      }}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}>
                          <h3>Total de marcas vendidas</h3>
                          <select 
                            className='form-control' 
                            style={{
                              width: '100px',
                              appearance: 'auto',
                              marginBottom: '10px'
                            }}
                            value={selection.brand}
                            onChange={(val) => getSelectionBrand(val.target.value)}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="Todos">Todos</option>
                          </select>
                        </div>
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col" />
                              <th scope="col"> Nombre </th>
                              <th scope="col"> Codigo </th>
                              <th scope="col"> Total </th>
                          </tr>
                          </thead>
                          <tbody>
                            {
                              (loading.brand) ?
                              <tr>
                                <td colSpan={10} className='text-center'>
                                  <Spinner style={{color: '#004899'}} />
                                </td>
                              </tr> :
                              (brand.length === 0) ? 
                              <tr>
                                <td colSpan={10} className='text-muted text-center'> No hay registros almacenados </td>
                              </tr> :
                              brand.map( (d,i) => (
                                <tr key={i}>
                                  <td>
                                    {++i}
                                  </td>
                                  <td>
                                    {d.BrandName}
                                  </td>
                                  <td>
                                    {d.BrandCode}
                                  </td>
                                  <td>
                                    {d.Total}
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      </div>
                    </Col>

                    <Col md="6">
                      <div style={{
                        marginTop: '40px',
                        width: '100%'
                      }}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}>
                          <h3>Total de piezas vendidas</h3>
                          <select 
                            className='form-control' 
                            style={{
                              width: '100px',
                              appearance: 'auto',
                              marginBottom: '10px'
                            }}
                            value={selection.piece}
                            onChange={(val) => getSelectionPiece(val.target.value)}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="Todos">Todos</option>
                          </select>
                        </div>
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col" />
                              <th scope="col"> Nombre </th>
                              <th scope="col"> Codigo </th>
                              <th scope="col"> Total </th>
                          </tr>
                          </thead>
                          <tbody>
                            {
                              (loading.piece) ?
                              <tr>
                                <td colSpan={10} className='text-center'>
                                  <Spinner style={{color: '#004899'}} />
                                </td>
                              </tr> :
                              (pieces.length === 0) ? 
                              <tr>
                                <td colSpan={10} className='text-muted text-center'> No hay registro almacenados </td>
                              </tr> :
                              pieces.map( (d,i) => (
                                <tr key={i}>
                                  <td>
                                    {++i}
                                  </td>
                                  <td>
                                    {d.name}
                                  </td>
                                  <td>
                                    {d.brand}
                                  </td>
                                  <td>
                                    {d.Total}
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <div style={{
                      marginTop: '40px',
                      width: '100%',
                    }}>
                      {
                        (loading.seller) ? <div className="text-center">
                          <Spinner style={{color: '#004899'}} />
                        </div> : <>
                          <div>
                            <h3>Ventas por dias</h3>
                          </div>
                          <Row>
                            <Col md="12">
                              <Bar
                                data={graphData}
                                options= {{maintainAspectRatio: false}}
                              />
                            </Col>
                          </Row>
                        </>
                      }
                    </div>
                  </Row>
                  
                  <Row>
                    <div style={{
                      marginTop: '40px'
                    }}>
                      <h3>Estadistica de los vendedores</h3>
                    </div>
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" />
                          <th scope="col"> NOMBRE </th>
                          <th scope="col"> VENTAS </th>
                          <th scope="col"> GANANCIAS </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          (loading.seller_general) ?
                          <tr>
                            <td colSpan={10} className='text-center'>
                              <Spinner style={{color: '#004899'}} />
                            </td>
                          </tr> :
                          (seller.length === 0) ? 
                          <tr>
                            <td colSpan={10} className='text-muted text-center'> No hay registro almacenados. </td>
                          </tr> :
                          seller.map( (d,i) => (
                            <tr key={i}>
                              <td>
                                {++i}
                              </td>
                              <td>
                                {d.name}
                              </td>
                              <td>
                                {d.sales}
                              </td>
                              <td>
                                ${d.profit}
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        <Button color="gray" to="/admin/budgets" tag={Link}> Regresar </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
