/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import Compressor from 'compressorjs';
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../components/Headers/Header";
import { getUsersByIdData, getAllRolData, updateUsersData, deleteUsersData } from "../../api/users";
import NotificationAlert from "react-notification-alert";

const EditUsers = () => {
  const notificationAlertRef = React.useRef(null);
  const { id } = useParams();
  const [picture, setPicture] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [rol, setRol] = useState('');
  const [code, setCode] = useState('');
  const [rolData, setRolData] = useState([]);
  const [active, setActive] = useState('0');
  const [sellerProfile, setSellerProfile] = useState('0');
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState({page: true, btn: false});
  const [error, setError] = useState(false);

  useEffect( ()=> {
    getAllRol();
    getUsersById();
  }, []) 

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				setPicture(e.target.result);
			};

			reader.readAsDataURL(event.target.files[0]);
			setFile(event.target.files[0]);
		}
	}

	const renderImageToSend = async (f) => {
		return new Compressor(f, {quality: 0.6});
	}
  
  const getAllRol = async () => {
    const res = await getAllRolData();
    setRolData(res.data);
  }

  const getUsersById = async () => {
    const res = await getUsersByIdData(id);

    if(res.success === true) {
      setError(false);
      const _picture = (res.data.picture === null) ? '' : res.data.picture;
      setPicture(_picture);
      setName(res.data.name);
      setEmail(res.data.email);
      setPhone(res.data.phone);
      setRol(res.data.rol);
      setCode(res.data.code);
      setActive(res.data.active);
      setSellerProfile(res.data.seller);
    }
    else {
      notifyAlert('danger', 'Error', "Ha ocurrido un error al obtener los datos del usuario");
    }
    setLoading(false);
  }

  const updateBrand = async () => {
    setLoading(true);
    let fileCompress = null;
    
    const _name = name.trim();
    const _email = email.trim();

    if(_name === "") {
      notifyAlert('danger', 'Error', "El nombre no puede estar vacio");
    }
    else if(_email === "") {
      notifyAlert('danger', 'Error', "El correo no puede estar vacio");
    }
    else if(!/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/.test(_email)) {
      notifyAlert('danger', 'Error', "El correo no es valido");
    }
    else if(rol === "") {
      notifyAlert('danger', 'Error', "El rol no puede estar vacio");
    }
    else if (Number(rol) === 3 && Number(sellerProfile) === 0 ) {
      notifyAlert('warning', 'Error', "Debe activiar el perfil de ventas al vendedor");
    }
    else {
      fileCompress = (file && Object.keys(file).length === 0 && file.constructor === Object) ? null : await renderImageToSend(file);

      const res = await updateUsersData(id, _name, _email, phone, rol, active, code, fileCompress, sellerProfile);

      if(res.success === true) {
        notifyAlert('success', 'Mensaje', "Usuario actualizado correctamente");
      }
      else {
        notifyAlert('danger', 'Error', "Ha ocurrido un error al actualizar el usuario");
      }
    }

    setLoading(false);
  }

  const deleteUsers = async () =>  {
    const res = await deleteUsersData(id);

    if(res.success === true) {
      window.location.href = '/admin/users'
    }
    else {
      notifyAlert('danger', 'Error', "Ha ocurrido un error al eliminar el usuario");
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Usuarios </h3>
                  </div>
                </Row>
              </CardHeader>
              
              <CardBody>
              {
                (loading.page) ?
                  <div className="text-center">
                    <Spinner style={{color: '#004899'}} />
                  </div>:
                (error) ? 
                  <div>
                    <div colSpan={10} className='text-muted text-center'>
                      <label>Ha ocurrido un error, por favor intente nuevamente. </label>
                      <br />
                      <Button color="gray" to="/admin/brand" tag={Link}> Regresar </Button>
                    </div>
                  </div>: 
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <label className="form-control-label">  </label>
                      <div 
                        id="dvImage"
                        style={{
                          width: '100%',
                          height: '150px',
                          marginBottom: '10px',
                          borderRadius: '.25rem',
                          border: '1px solid #ced4da'
                        }}>
                          <img 
                            style={{
                              width: '100%',
                              height: '148px',
                              objectFit: 'scale-down',
                            }}
                            alt="Cargar Imagen"
                            src={picture} />
                      </div>

                      <div className="float-right">
                        <label className="custom-file-upload">
                          <input 
                            type="file"
                            accept="image/*"
                            onChange={onImageChange}/>
                          Cargar Imagen
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Nombre </label>
                        <Input
                          value={name}
                          className="form-control"
                          onChange={val => setName(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> E-mail </label>
                        <Input
                          value={email}
                          className="form-control"
                          onChange={val => setEmail(val.target.value)}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Telefono </label>
                        <Input
                          value={phone}
                          className="form-control"
                          onChange={val => setPhone(val.target.value)}/>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Codigo (este es el codigo del usuario en premium) </label>
                        <Input
                          value={code}
                          className="form-control"
                          onChange={val => setCode(val.target.value)}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Rol </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={rol}
                          onChange={val => setRol(val.target.value)}
                        >
                          <option value=''>Seleccionar</option>
                          {
                            rolData.map( (r, i) => (
                              <option key={i} value={r.id}>{r.name}</option>
                            ))
                          }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label">Perfil de ventas</label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={sellerProfile}
                          onChange={val => setSellerProfile(val.target.value)}
                        >
                          <option value="1"> Si </option>
                          <option value="0"> No </option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Activo </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={active}
                          onChange={val => setActive(val.target.value)}
                        >
                          <option value="0"> Si </option>
                          <option value="1"> No </option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>


                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading.btn) ? <Spinner style={{color: '#004899'}}/> :
                          <div>
                            <Button color="gray" to="/admin/users" tag={Link}> Regresar </Button>
                            <Button color="danger" onClick={() => setModal(!modal)}> Eliminar </Button>
                            <Button
                              style={{
                                backgroundColor: '#004899',
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => updateBrand()}
                              > Guardar
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                </div>
              }
              </CardBody>
            </Card>
 
          </Col>
        </Row>
	
        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader toggle={() => setModal(!modal)}> Mensaje </ModalHeader>
          <ModalBody>
            ¿Está seguro que desea eliminar este usuario?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal(!modal)}> OK </Button>
            <Button color="secondary" onClick={() => deleteUsers()}> OK </Button>
          </ModalFooter>
        </Modal>
     
      </Container>
    </>
  )
}

export default EditUsers;