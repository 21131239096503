/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getPiecesByIdData } from "../../../api/database";

const ViewClient = () => {
  const {id} = useParams();
  const [piece, setPiece] = useState({})
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect( ()=> {
    getPieceById();
  }, []) 

  const getPieceById = async () => {
    const res = await getPiecesByIdData(id);
    console.log(res.data);
    if(res.success === true) {
      setError(false);
      setPiece(res.data);
    }
    else {
      setError(true);
      setErrorMsg(res.error.message)
    }
    setLoading(false);
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Piezas </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
              {
                (loading) ?
                <div className="text-center">
                  <Spinner style={{color: '#004899'}} />
                </div>:
                (error) ? 
                <div>
                  <div colSpan={10} className='text-muted text-center'>
                    <label>{errorMsg}</label>
                    <br />
                    <Button color="gray" to="/admin/clients" tag={Link}> Regresar </Button>
                  </div>
                </div>: 
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <div 
                        id="dvImage"
                        style={{
                          width: '100%',
                          height: '300px',
                          marginBottom: '10px',
                          borderRadius: '.25rem',
                          border: '1px solid #ced4da'
                        }}>
                          <img 
                            style={{
                              width: '100%',
                              height: '298px',
                              padding: '5px',
                              objectFit: 'scale-down',
                            }}
                            alt="Cargar imagen"
                            src={piece.picture} />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Nombre </label>
                        <Input
                          value={piece.name}
                          className="form-control" readOnly={true}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Marca </label>
                        <Input
                          value={piece.brand}
                          className="form-control" readOnly={true}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Categoría </label>
                        <Input
                          value={piece.category}
                          className="form-control" readOnly={true}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Precio </label>
                        <Input
                          value={`$${piece.price}`}
                          className="form-control" readOnly={true}/>
                      </FormGroup>
                    </Col>

                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Cantidad </label>
                        <Input
                          value={piece.quantity}
                          className="form-control" readOnly={true}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> Almacen </label>
                        <Input
                          value={piece.warehouse}
                          className="form-control" readOnly={true}/>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> Descripción </label>
                        <textarea 
                          value={piece.description}
                          className="form-control" readOnly={true}>
                        </textarea>
                      </FormGroup>
                    </Col>
                  </Row>


                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        <Button color="gray" to="/admin/pieces" tag={Link}> Regresar </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ViewClient;