import React, {useState, useEffect} from 'react';
import Header from "../../../components/Headers/Header";
import {Spinner, Card, CardHeader, CardBody, Table, FormGroup, Container, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, InputGroupAddon, InputGroup, InputGroupText} from "reactstrap";
import { getContactData } from '../../../api/page';
import dayjs from 'dayjs';

const Contact = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [search, setSearch] = useState('');
  const [contact, setContact] = useState({
    name: "",
    email: "",
    message: "",
    date: "",
  });

  useEffect(() => {
    getContacts();
  }, []);

  const getContacts = async () => {
    setLoading(true);
    const res = await getContactData();

    if(res.success === true) {
      setError(false);
      setData(res.data);
      setDataSearch(res.data);
    }
    else {
      setError(true);
      setData([]);
      setDataSearch([]);
    }
    setLoading(false);
  }

  const handleSearch = (text) => {
    if (text) {
      const newData = data.filter( (item) => {
        const itemData = item.name
          ? item.name.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setSearch(text);
      setDataSearch(newData);
    } 
    else {
      setSearch(text);
      setDataSearch(data);
    }   
  }

  const viewContact = (value) => {
    setContact({
      name: value.name,
      email: value.email,
      message: value.message,
      date: dayjs(value.date).format('DD/MM/YYYY')
    });
    setModal(!modal);
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Contacto </h3>
                  </div>
                </Row>
              </CardHeader>
              
              <CardBody>
                <div className='paddingHorizonal'>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={search}
                      style={{paddingLeft: '5px'}}
                      placeholder={"Buscar"}
                      onChange={(val) =>  handleSearch(val.target.value)}
                      />
                  </InputGroup>
                  <br />
                </div>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" />
                      <th scope="col"> Nombre </th>
                      <th scope="col"> Correo Electronico </th>
                      <th scope="col"> Mensaje </th>
                      <th scope="col"> Fecha </th>
                      <th scope="col" />
                    </tr>
                  </thead>

                  <tbody>
                    {
                      (loading) ?
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <Spinner style={{color: '#004899'}} />
                        </td>
                      </tr>:
                      (error) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'>
                          <label> Ha ocurrido un error, por favor intente nuevamente otra vez.</label>
                          <br />
                          <Button 
                            color="secondary"
                            onClick={() => getContacts()}> Cargar </Button>
                        </td>
                      </tr>:
                      (data.length === 0) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'> No hay información registrada </td>
                      </tr>
                      :
                      dataSearch.map( (d,i) => (
                        <tr key={i}>
                          <td>
                            {++i}
                          </td>
                          <td>
                            {d.name}
                          </td>
                          <td>
                            {d.email}
                          </td>
                          <td>
                            {d.message}
                          </td>
                          <td>
                            {dayjs(d.date).format('DD/MM/YYYY')}
                          </td>
                          <td className="text-right">
                            <Button 
                              size='sm'
                              style={{backgroundColor: '#004899', color: "#ffffff", border: "0"}} 
                              onClick={() => viewContact(d)}
                            > Ver </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>Contacto</ModalHeader>
        <ModalBody>
          <Container>
            <FormGroup>
              <label> Nombre </label>
              <Input 
                readOnly
                value={contact.name} />
            </FormGroup>

            <FormGroup>
              <label> Email </label>
              <Input 
                readOnly
                value={contact.email} />
            </FormGroup>

            <FormGroup>
              <label> Mensaje </label>
              <Input 
                readOnly
                value={contact.message}
              type="textarea" />
            </FormGroup>

            <FormGroup>
              <label> Fecha </label>
              <Input 
                readOnly
                value={contact.date} />
            </FormGroup>

          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal(!modal)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default Contact;