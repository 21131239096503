import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

//BRAND
export async function getBrandData() {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(CONS.brand, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getBrandByIdData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.brand}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setNewBrandData(name, code, active, comImg) {
	const token = getToken('spm_token');
	
  const formData = new FormData();
  formData.append('name', name);
  formData.append('code', code);
  formData.append('active', active);
	(comImg === null) ?  formData.append('file', comImg) : formData.append('file', comImg.file, comImg.file.name);

	try {
		const res = await axios.post(CONS.brand, formData,  {
      headers: {
        'token': token,
					'Content-type': 'multipart/form-data'
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateBrandData(id, name, code, active, comImg) {
	const token = getToken('spm_token');
	const formData = new FormData();
	formData.append('name', name);
	formData.append('code', code);
	formData.append('active', active);
	(comImg === null) ?  formData.append('file', comImg) : formData.append('file', comImg.file, comImg.file.name);
	
	try {
		const res = await axios.put(`${CONS.brand}/${id}`, formData, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function deleteBrandData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.delete(`${CONS.brand}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

//CATEGORY
export async function getCategoryData() {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(CONS.category, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCategoryByIdData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.category}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setNewCategoryData(name, code, active, comImg) {
	const token = getToken('spm_token');
	
  const formData = new FormData();
  formData.append('name', name);
  formData.append('code', code);
  formData.append('active', active);
	(comImg === null) ?  formData.append('file', comImg) : formData.append('file', comImg.file, comImg.file.name);

	try {
		const res = await axios.post(CONS.category, formData,  {
      headers: {
        'token': token,
					'Content-type': 'multipart/form-data'
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateCategoryData(id, name, code, active, comImg) {
	const token = getToken('spm_token');
	const formData = new FormData();
	formData.append('name', name);
	formData.append('code', code);
	formData.append('active', active);
	(comImg === null) ?  formData.append('file', comImg) : formData.append('file', comImg.file, comImg.file.name);
	
	try {
		const res = await axios.put(`${CONS.category}/${id}`, formData, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function deleteCategoryData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.delete(`${CONS.category}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

//WAREHOUSE
export async function getAllWarehouseData() {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(CONS.warehouse, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setNewWarehouse(jsn) {
	const token = getToken('spm_token');

	try {
		const res = await axios.post(CONS.warehouse, jsn , {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getWarehouseByIdData(id) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.get(`${CONS.warehouse}/${id}` , {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateNewWarehouseData(jsn) {
	const token = getToken('spm_token');

	try {
		const res = await axios.put(CONS.warehouse, jsn , {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

//CLIENT
export async function getClientsData(rows) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.clients}/${rows}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getClientsPaginationData(rows, page) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.clients}/page/${rows}/${page}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getClientBySearch(txt) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.clients}/search/${txt}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getClientByIdData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.clients}/find/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

//PIECES
export async function getPiecesData(rows) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.pieces}/${rows}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getPiecesPaginationData(rows, page) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.pieces}/page/${rows}/${page}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getPiecesBySearch(txt) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.pieces}/search/${txt}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getPiecesByIdData(id) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.pieces}/find/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getPiecesByCodeData(code) {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(`${CONS.pieces}/code/${code}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}


