import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import AuthFooter from "components/Footers/AuthFooter.js";

import Login from "views/auth/Login";
import Password from "views/auth/Password";

function Auth() {
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    document.body.classList.add("bg-default");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove("bg-default");
    };
  });
  
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <Switch>
          <Route path="/auth/login" component={Login} />
          <Route path="/auth/password" component={Password} />
          <Redirect from="*" to="/auth/login" />
        </Switch>
      </div>
      <AuthFooter />
    </>
  );
}

export default Auth;
