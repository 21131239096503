import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function updateUserData(jsn) {
	const token = getToken('spm_token');

	try {
		const res = await axios.post(CONS.config, jsn,  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setPictureUser(comImg) {
	const token = getToken('spm_token');

  const formData = new FormData();
  formData.append('file', comImg.file, comImg.file.name);

	try {
		const res = await axios.put(`${CONS.config}/image`, formData,  {
      headers: {
        'token': token,
					'Content-type': 'multipart/form-data'
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateEmailUserData(email) {
	const token = getToken('spm_token');

	const jsn = {
		"email": email
	}

	try {
	  	const res = await axios.put(`${CONS.config}/email`, jsn,  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updatePasswordData(jsn) {
	const token = getToken('spm_token');
	
	try {
    const res = await axios.put(`${CONS.config}/password`, jsn,  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

/* NOTIFIACIONES */
export async function getAllNotificationUsersData() {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.get(CONS.notification, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getNotificationTypeData() {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.get(`${CONS.config}/notification/type`,  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setNotificationData(user, notype) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.post(`${CONS.config}/notification/type`, {'user':user, "notype": notype},  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getNotificationUserIdData(id) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.get(`${CONS.notification}/user/${id}`,  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function deleteUserNotificationData(id) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.delete(`${CONS.notification}/user/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateNotificationData(id, user, notype) {
	const token = getToken('spm_token');
	
	try {
		const res = await axios.put(`${CONS.notification}/user`, {"id": id, 'user':user, "notype": notype},  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}
