import React from "react";
import { Container } from "reactstrap";
import { getColor } from "utils/token";

export default function Header() {

  return (
    <>
      <div
        style={{
          backgroundColor: getColor(1)
        }}
        className="header pb-5 pt-5 pt-md-5">
        <Container fluid>
          <br />
        </Container>
      </div>
    </>
  )
}
