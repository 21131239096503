import Welcome from 'views/welcome/Welcome';
import Invoices from './views/Orders/Invoices';
import Orders from './views/Orders/Orders';
import ViewOrder from './views/Orders/ViewOrder';
import Transit from 'views/Orders/Transit';
import ViewTransit from 'views/Orders/ViewTransit';

import Brand from './views/Database/Brand/Brand';
import NewBrand from './views/Database/Brand/NewBrand';
import EditBrand from './views/Database/Brand/EditBrand';
import Category from './views/Database/Category/Category';
import NewCategory from './views/Database/Category/NewCategory';
import EditCategory from './views/Database/Category/EditCategory';
import Pieces from './views/Database/Pieces/Pieces';
import ViewPieces from './views/Database/Pieces/ViewPieces';
import Clients from './views/Database/Clients/Clients';
import ViewClients from './views/Database/Clients/ViewClients';
import Warehouse from './views/Database/Warehouse/Warehouse';
import NewWarehouse from './views/Database/Warehouse/NewWarehouse';
import EditWarehouse from './views/Database/Warehouse/EditWarehouse';

import Users from './views/Users/Users';
import NewUsers from './views/Users/NewUsers';
import EditUsers from './views/Users/EditUsers';
import Config from "./views/Config/Config";
import Notification from "./views/Notification/Notification";
import NewNotification from "./views/Notification/NewNotification";
import EditNotification from "./views/Notification/EditNotification";
import Graph from "./views/Graph/Graph";

import Reports from 'views/Reports/Reports';
import Budgets from 'views/Budgets/Budgets';
import NewBudgets from 'views/Budgets/NewBudgets';
import ViewBudgets from 'views/Budgets/ViewBudgets';
import EditBudgets from 'views/Budgets/EditBudgets';

import Goals from 'views/Budgets/Goals/Goals';
import NewGoals from 'views/Budgets/Goals/NewGoals';
import EditGoals from 'views/Budgets/Goals/EditGoals';
import ViewGoals from 'views/Budgets/Goals/ViewGoals';
import Blog from 'views/Page/Blog/Blog';
import NewBlog from 'views/Page/Blog/NewBlog';
import EditBlog from 'views/Page/Blog/EditBlog';
import Company from 'views/Page/Company/Company';

import Carousel from 'views/Page/Carousel/Carousel';
import NewCarousel from 'views/Page/Carousel/NewCarousel';
import EditCarousel from 'views/Page/Carousel/EditCarousel';
import Contact from 'views/Page/Contact/Contact';
import Services from 'views/Page/Services/Services';
import NewServices from 'views/Page/Services/NewServices';
import EditServices from 'views/Page/Services/EditServices';
import FAQ from 'views/Page/FAQ/FAQ';
import GraphMix from 'views/Graph/GraphMix';
import Catalog from 'views/Catalog/Catalog';
import NewCatalog from 'views/Catalog/NewCatalog';

export const routes = [
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
    layout: "/admin",
    rol: [1, 2, 3, 4]
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    layout: "/admin",
    rol: [1, 2, 5]
  },
  {
    path: "/orders/:id",
    name: "Orders",
    component: ViewOrder,
    layout: "/admin",
    rol: [1, 2, 5]
  },
  {
    path: "/invoice",
    name: "Invoices",
    component: Invoices,
    layout: "/admin",
    rol: [1, 2, 5]
  },
  {
    path: "/transit",
    name: "Transit",
    component: Transit,
    layout: "/admin",
    rol: [1, 2, 5]
  },
  {
    path: "/transit/:id",
    name: "Transit",
    component: ViewTransit,
    layout: "/admin",
    rol: [1, 2, 5]
  },
  {
    path: "/graph",
    name:"Graph",
    component: Graph,
    layout: "/admin",
    rol: [1, 2]
  },
  {
    path: "/catalog",
    name:"Catalog",
    component: Catalog,
    layout: "/admin",
    rol: [1, 2, 4, 5, 6]
  },
  {
    path: "/catalog/new",
    name:"Catalog",
    component: NewCatalog,
    layout: "/admin",
    rol: [1, 2, 4, 5, 6]
  },
  {
    path: "/graph/mix",
    name:"Graph",
    component: GraphMix,
    layout: "/admin",
    rol: [1, 2]
  },
  {
    path: "/budgets",
    name: "Budgets",
    component: Budgets,
    layout: "/admin",
    rol: [1, 2]
  },
  {
    path: "/budgets/view/:id",
    name: "Budgets",
    component: ViewBudgets,
    layout: "/admin",
    rol: [1, 2]
  },
  {
    path: "/budgets/new",
    name: "Budgets",
    component: NewBudgets,
    layout: "/admin",
    rol: [1, 2]
  },
  {
    path: "/budgets/:id",
    name: "Budgets",
    component: EditBudgets,
    layout: "/admin",
    rol: [1, 2]
  },
  {
    path: "/reports",
    name:"Reports",
    component: Reports,
    layout: "/admin",
    rol: [1, 2]
  },

  {
    path: "/goals",
    name: "Goals",
    component: Goals,
    layout: "/admin",
    rol: [1, 2]
  },
  {
    path: "/goals/view/:id",
    name: "Goals",
    component: ViewGoals,
    layout: "/admin",
    rol: [1, 2]
  },
  {
    path: "/goals/new",
    name: "Goals",
    component: NewGoals,
    layout: "/admin",
    rol: [1, 2]
  },
  {
    path: "/goals/:id",
    name: "Goals",
    component: EditGoals,
    layout: "/admin",
    rol: [1, 2]
  },
  {
    path: "/brand",
    name:"Brand",
    component: Brand,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/brand/new",
    name: "Brand",
    component: NewBrand,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/brand/:id",
    name: "Brand",
    component: EditBrand,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/category",
    name:"Category",
    component: Category,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/category/new",
    name: "Category",
    component: NewCategory,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/category/:id",
    name: "Category",
    component: EditCategory,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    layout: "/admin",
    rol: [1, 2]
  },
  {
    path: "/clients/:id",
    name: "Clients",
    component: ViewClients,
    layout: "/admin",
    rol: [1, 2]
  },
  {
    path: "/pieces",
    name: "Pieces",
    component: Pieces,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/pieces/:id",
    name: "Pieces",
    component: ViewPieces,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    component: Warehouse,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/warehouse/new",
    name: "Warehouse",
    component: NewWarehouse,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/warehouse/:id",
    name: "Warehouse",
    component: EditWarehouse,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/users",
    name:"Users",
    component: Users,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/users/new",
    name: "Category",
    component: NewUsers,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/users/:id",
    name:"Users",
    component: EditUsers,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/notification",
    name:"Notification",
    component: Notification,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/notification/new",
    name:"Notification",
    component: NewNotification,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/notification/:id",
    name:"Notification",
    component: EditNotification,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/profile",
    name:"Profile",
    component: Config,
    layout: "/admin",
    rol: [1, 2, 3, 4, 5]
  },
  {
    path: "/blog",
    name:"Blog",
    component: Blog,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/blog/new",
    name:"Blog",
    component: NewBlog,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/blog/:id",
    name:"Blog",
    component: EditBlog,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/company",
    name:"Company",
    component: Company,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/carousel",
    name:"Carousel",
    component: Carousel,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/carousel/new",
    name:"Carousel",
    component: NewCarousel,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/carousel/:id",
    name:"Carousel",
    component: EditCarousel,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/contact",
    name:"Contact",
    component: Contact,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/services",
    name:"Services",
    component: Services,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/services/new",
    name:"Services",
    component: NewServices,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/services/:id",
    name:"Services",
    component: EditServices,
    layout: "/admin",
    rol: [1, 2, 4]
  },
  {
    path: "/faq",
    name:"FAQ",
    component: FAQ,
    layout: "/admin",
    rol: [1, 2, 4]
  },
]
