/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getWarehouseByIdData, updateNewWarehouseData } from "../../../api/database";
import NotificationAlert from "react-notification-alert";

const EditWarehouse = () => {
  const notificationAlertRef = React.useRef(null);
  const { id } = useParams();
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [manager, setManager] = useState('');
  const [active, setActive] = useState('0');
  const [loading, setLoading] = useState({page: true, btn: false});
  
  useEffect(() => {
    getWarehouseById();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getWarehouseById = async () => {
    const res = await getWarehouseByIdData(id);

    if(res.success === true) {
      setCode(res.data.code);
      setName(res.data.name);
      setManager(res.data.manager);
      setActive(res.data.active);
    }
    else {
      notifyAlert("danger", "Error", "Error al obtener los datos del almacen");
      window.location.href = "/admin/warehouse";
    }
    setLoading(false);
  }

  const setNewWarehouse = async () => {
    setLoading({...loading, btn: true});

    const _name = name.trim();
    const _code = code.trim();

    if(_name === "") {
      notifyAlert("danger", "Error", "El nombre no puede estar vacío");
    }
    else if(_code === "") {
      notifyAlert("danger", "Error", "El código no puede estar vacío");
    }
    else {
      let jsn = {
        "name": _name,
        "code": _code,
        "manager": manager,
        "active": active,
        "id": id
      }

      const res = await updateNewWarehouseData(jsn);

      if(res.success === true) {
        setLoading({...loading, btn: false});
        notifyAlert("success", "Correcto", "Almacen actualizado correctamente");
      }
      else {
        setLoading({...loading, btn: false});
        notifyAlert("danger", "Error", "Error al actualizar el almacen");
      }
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Almacen </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                {
                  (loading.page) ?
                  <div className="text-center">
                    <Spinner style={{color: '#004899'}} />
                  </div>:
                  <>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label"> Codigo </label>
                          <Input
                            value={code}
                            className="form-control"
                            onChange={val => setCode(val.target.value)}/>
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label"> Nombre </label>
                          <Input
                            value={name}
                            className="form-control"
                            onChange={val => setName(val.target.value)}/>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label"> Encargado </label>
                          <Input
                            value={manager}
                            className="form-control"
                            onChange={val => setManager(val.target.value)}/>
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label"> Activo </label>
                          <select
                            style={{
                              appearance: 'auto'
                            }}
                            className="form-control"
                            value={active}
                            onChange={val => setActive(val.target.value)}
                          >
                            <option value="1"> Si </option>
                            <option value="0"> No </option>
                          </select>
                        </FormGroup>
                      </Col> 
                    </Row>

                    <Row>
                      <Col lg="12" >
                        <br />
                        <div className="float-right">
                          {
                            (loading.btn) ? <Spinner style={{color: '#004899'}}/> :
                            <div>
                              <Button color="gray" to="/admin/warehouse" tag={Link}> Regresar </Button>
                              <Button
                                style={{
                                  backgroundColor: '#004899',
                                  color: '#fff',
                                  border: 0
                                }}
                                onClick={() => setNewWarehouse()}
                                > Guardar
                              </Button>
                            </div>
                          } 
                        </div>
                      </Col>
                    </Row>
                  </>
                }
                </div>
              </CardBody>
            </Card>
 
          </div>
        </Row>
      </Container>

    </>
  )
}

export default EditWarehouse;