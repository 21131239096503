/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import { Spinner, Card, Container, CardHeader, Row, Button, Col, CardBody, Table } from "reactstrap";
import { Bar } from "react-chartjs-2";
import Header from "../../../components/Headers/Header";
import { getGoalsInfoData,
  getGoalsSellerData,
  getGoalsBrandData,
  getGoalsPieceData } from "../../../api/budgets";
import dayjs from "dayjs";

export default function ViewGoals() {
  const { id } = useParams();
  const [loading, setLoading] = useState({
    stats: false,
    dates: false,
    seller: false,
    brand: false,
    piece: false,
  });
  const [data, setData] = useState({
    name: "",
    goals: "",
    brand: "",
    pieces: "",
    description: "",
    initDate: "",
    endDate: "",
    real_goals: "",
    real_brand: "",
    real_pieces: "",
  });
  const [arrSeller, setArrSeller] = useState({legend: [] ,labels: [], datasets: [{ label: 'Vendedores', backgroundColor: '#004899', data: [] }]});
  const [brand, setBrand] = useState([]);
  const [pieces, setPiece] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    funct();
  }, []);

  const funct = async () => {
    await getGoalsInfo();
    await getGoalsSeller();
    await getGoalsBrand();
    await getGoalsGraphPiece();

  }

  const getGoalsInfo = async () => {
    setLoading({...loading, stats: true});

    const res = await getGoalsInfoData(id);
    if(res.success === true) {
      setError(false);
      setData({
        name: res.data.name,
        goals: res.data.goals,
        brand: res.data.brand,
        pieces: res.data.pieces,
        description: res.data.description,
        initDate: res.data.initDate,
        endDate: res.data.endDate,
        real_goals: (res.data.real_goals === null) ? 0 : res.data.real_goals,
        real_brand: (res.data.real_brand === null) ? 0 : res.data.real_brand,
        real_pieces: (res.data.real_pieces === null) ? 0 : res.data.real_pieces,
      });
    }

    setLoading({...loading, stats: false});
  }

  const getGoalsSeller = async () => {
    setLoading({...loading, seller: true});

    const res = await getGoalsSellerData(id);

    const data = res.data;
    const valuesText = data.map( (v) => {
      return dayjs(v.day).format("DD-MM-YYYY"); 
    });
    const valuesData = data.map( (v) => {
      return v.total
    });

    if(res.success === true) {
      setError(false);
      setArrSeller({
        legend: data,
        labels: valuesText, 
        datasets: [{
        label: 'Vendedores',
        backgroundColor: '#004899',
        data: valuesData
      }]});
    }
    else {
      setError(true);
    }
    setLoading({...loading, seller: false});
  }

  const getGoalsBrand = async () => {
    setLoading({...loading, brand: true});

    const res = await getGoalsBrandData(id);

    if(res.success === true) {
      setBrand(res.data);
    }
    else {
      setError(true);
    }
    setLoading({...loading, brand: false});
  }

  const getGoalsGraphPiece = async () => {
    setLoading({...loading, piece: true});

    const res = await getGoalsPieceData(id);

    if(res.success === true) {
      setPiece(res.data);
    }
    else {
      setError(true);
    }
    setLoading({...loading, piece: false});
  }

  const getSumBrand = () => {
    let sum = 0;
    brand.map( (v) => {
      sum += v.total;
    });
    return sum;
  }
  
  const getSumPiece = () => { 
    let sum = 0;
    pieces.map( (v) => {
      sum += v.total;
    });
    return sum;
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Meta de {data.name} </h3>
                  </div>
                </Row>
              </CardHeader>
              
              <CardBody style={{marginTop: "-30px"}}>
                <div className='paddingHorizonal'>
                  <Row>
                    <Container>
                      {
                        (loading.init) ? <div>
                          <Spinner size="sm" style={{color: '#004899'}} />
                        </div> : <>
                          <h4>{data.title}</h4>
                          {
                            (data.description !== '') ? 
                              <p>{data.description}</p>
                            :<></>
                          }
                        </>
                      }
                    </Container>
                  </Row>

                  <Row>
                    <Col className="mb-5 mb-xl-0">
                      <Card className="bg-gradient-default shadow">
                        <CardHeader className="bg-transparent border-0">
                          <Row className="align-items-center">
                            <div className="col">
                              <h3 className="mb-0" 
                              style={{
                                color: '#fff',
                                textAlign: 'center'
                              }}> Meta </h3>
                            </div>
                          </Row>
                        </CardHeader>
                        <CardBody style={{
                          backgroundColor: '#fff',
                          textAlign: 'center'
                        }}>
                          {
                            (loading.stats) ? <div className="text-center">
                              <Spinner size="md" style={{color: '#fff'}} />
                            </div> : <>
                              <h2 className="mb-0" style={{
                                color: (parseFloat(data.real_goals) >= parseFloat(data.goals)) ? '#00a100': '#b70000',
                                display: "inline-flex"
                              }}>${data.real_goals} </h2> / <h2 style={{display: "inline-flex"}}> ${data.goals} </h2>
                            </>
                          }
                        </CardBody>
                      </Card>
                    </Col>

                    <Col className="mb-5 mb-xl-0">
                      <Card className="bg-gradient-default shadow">
                        <CardHeader className="bg-transparent border-0">
                          <Row className="align-items-center">
                            <div className="col">
                              <h3 className="mb-0"
                              style={{
                                color: '#fff',
                                textAlign: 'center'
                              }}>Piezas</h3>
                            </div>
                          </Row>
                        </CardHeader>
                        <CardBody style={{
                          backgroundColor: '#ffffff',
                          textAlign: 'center'
                        }}>
                          {
                            (loading.stats) ? <div className="text-center">
                              <Spinner size="md" style={{color: '#fff'}} />
                            </div> : <>
                              <h2 className="mb-0"> {data.real_pieces} / {data.pieces}</h2> 
                            </>
                          }
                        </CardBody>
                      </Card>
                    </Col>

                    <Col className="mb-5 mb-xl-0">
                      <Card className="bg-gradient-default shadow">
                        <CardHeader className="bg-transparent border-0">
                          <Row className="align-items-center">
                            <div className="col">
                              <h3 className="mb-0"
                              style={{
                                color: '#fff',
                                textAlign: 'center'
                              }}>Marcas</h3>
                            </div>
                          </Row>
                        </CardHeader>
                        <CardBody style={{
                          backgroundColor: '#fff',
                          textAlign: 'center'
                        }}>
                          {
                            (loading.stats) ? <div className="text-center">
                              <Spinner size="md" style={{color: '#fff'}} />
                            </div> : <>
                              <h2 className="mb-0"> {data.real_brand} / {data.brand}</h2>
                            </>
                          }
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      width: '100%'
                    }}>
                      {
                        (loading.init) ? <div className="text-center">
                          <Spinner size="md" style={{color: '#004899'}} />
                        </div> : <>
                          <h3><i style={{
                            fontSize: '18px',
                            display: 'inline-flex',
                            position: 'relative',
                            top: '-4px',
                            left: '-6px'
                          }}className="far fa-calendar"></i>{dayjs(data.initDate).add(1, 'd').format("DD-MM-YYYY")}</h3>
                          <h3>-</h3>
                          <h3><i style={{
                            fontSize: '18px',
                            display: 'inline-flex',
                            position: 'relative',
                            top: '-4px',
                            left: '-6px'
                          }}className="far fa-calendar"></i>{dayjs(data.endDate).add(1, 'd').format("DD-MM-YYYY")}</h3>
                        </>
                      }
                    </div>
                  </Row>
                  
                  <Row>
                    <div style={{
                      marginTop: '40px',
                      width: '100%'
                    }}>
                      {
                        (loading.seller) ? <div className="text-center">
                          <Spinner style={{color: '#004899'}} />
                        </div> : <>
                          <div>
                            <h3>Ventas por fecha </h3>
                          </div>
                          <Row>
                            <Col md="12">
                              <Bar
                                data={arrSeller}
                                options= {{maintainAspectRatio: true}}
                              />
                            </Col>
                          </Row>
                        </>
                      }
                    </div>
                  </Row>
                  
                  <Row>
                    <Col md="6">
                      <div style={{
                        marginTop: '40px'
                      }}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}>
                          <h3>Total de marcas vendidas</h3>
                        </div>
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col" />
                              <th scope="col"> Nombre </th>
                              <th scope="col"> Codigo </th>
                              <th scope="col"> Total </th>
                          </tr>
                          </thead>
                          <tbody>
                            {
                              (loading.brand) ?
                              <tr>
                                <td colSpan={10} className='text-center'>
                                  <Spinner style={{color: '#004899'}} />
                                </td>
                              </tr> :
                              (brand.length === 0) ? 
                              <tr>
                                <td colSpan={10} className='text-muted text-center'> No hay registros almacenados </td>
                              </tr> :
                              brand.map( (d,i) => (
                                <tr key={i}>
                                  <td>
                                    {++i}
                                  </td>
                                  <td>
                                    {d.name}
                                  </td>
                                  <td>
                                    {d.code}
                                  </td>
                                  <td>
                                    {d.total}
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}>
                        <h2> Marcas: {brand.length} </h2> 
                        <h2> Total: {getSumBrand()} </h2>
                      </div>
                    </Col>

                    <Col md="6">
                      <div style={{
                        marginTop: '40px',
                        width: '100%'
                      }}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}>
                          <h3>Total de piezas vendidas</h3>
                        </div>

                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col" />
                              <th scope="col"> Nombre </th>
                              <th scope="col"> Codigo </th>
                              <th scope="col"> Total </th>
                          </tr>
                          </thead>
                          <tbody>
                            {
                              (loading.piece) ?
                              <tr>
                                <td colSpan={10} className='text-center'>
                                  <Spinner style={{color: '#004899'}} />
                                </td>
                              </tr> :
                              (pieces.length === 0) ? 
                              <tr>
                                <td colSpan={10} className='text-muted text-center'> No hay registro almacenados </td>
                              </tr> :
                              pieces.map( (d,i) => (
                                <tr key={i}>
                                  <td>
                                    {++i}
                                  </td>
                                  <td>
                                    {d.name}
                                  </td>
                                  <td>
                                    {d.brand}
                                  </td>
                                  <td>
                                    {d.total}
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}>
                        <h2> Piezas: {pieces.length} </h2> 
                        <h2> Total: {getSumPiece()} </h2>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        <Button color="gray" to="/admin/goals" tag={Link}> Regresar </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
