import React from 'react';
import {Card, Container, Row} from "reactstrap";
import Header from "components/Headers/Header";

export default function Welcome() {

  return (
    <>
      <Header />
      
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card>
              <div style={{
                textAlign: 'center'
              }}>
                <img alt="...." src={require('../../assets/img/logo.png').default} 
                style={{
                  marginTop: '30px',
                  width: '200px',
                  opacity: '0.9'
                }}/>
                <br /> <br />
                <label style={{
                  fontSize: '30px',
                  fontWeight: '400',
                  marginBottom: '10px'
                }}> Bienvenido al <b>Admin Panel</b> </label>
                <br />
                <span> Selecciona una opción para empezar a trabajar</span>
                <br />
                <br />
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
