/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import { Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getSellerData, updateGoalsData, getGoalsByIdData } from "../../../api/budgets";
import NotificationAlert from "react-notification-alert";
import dayjs from "dayjs";

export default function EditGoals () {
  const { id } = useParams();
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({page: true, btn: false});
  const [error, setError] = useState(false);
  const [userArr, setUserArr] = useState('');
  const [user, setUser] = useState('');
  const [goals, setGoals] = useState('');
  const [brand, setBrand] = useState("");
  const [quantity, setQuantity] = useState("");
  const [initDate, setInitDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [description, setDescription] = useState('');
  
  useEffect(() => {
    funct();
  }, []);
  
  const funct = async () => {
    await getSeller();
    getGoals();
  }
  
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span> <br />
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const getGoals = async () => {
    setLoading({page: true, btn: false});

    const res = await getGoalsByIdData(id);

    if(res.success === true) {
      setError(false);
      setUser(res.data.user_id);
      setBrand(res.data.brand);
      setQuantity(res.data.pieces);
      setDescription(res.data.description);
      setInitDate(dayjs(res.data.initDate).add(1, 'd').format('YYYY-MM-DD'));
      setEndDate(dayjs(res.data.endDate).add(1, 'd').format('YYYY-MM-DD'));
      setGoals(res.data.goals);
    }
    else {
      setError(true);
    }
    setLoading({page: false, btn: false});
  }

  const getSeller = async () => {
    const res = await getSellerData();

    if(res.success === true) {
      setUserArr(res.data);
    }
    else {
      setUserArr([]);
    }
  }

  const addNewGoals = async () => {
    setLoading({page: false, btn: true});

    if(String(user).trim() === "") {
      notifyAlert('warning', "Mensaje", "Debe seleccionar un vendedor");
    }
    else if(initDate === "") {
      notifyAlert('warning', "Mensaje", "El campo fecha de inicio es requerido");
    }
    else if(endDate === '') {
      notifyAlert('warning', "Mensaje", "El campo fecha de finalización es requerido");
    }
    else if(dayjs(endDate) <= dayjs(initDate)) {
      notifyAlert('warning', "Mensaje", "La fecha de finalización debe ser mayor a la fecha de inicio");
    }
    else if (String(goals).trim() === '') {
      notifyAlert('warning', "Mensaje", "El campo metas es requerido");
    }
    else if(Number(goals) === 0){
      notifyAlert('warning', "Mensaje", "El campo metas debe ser mayor a 0");
    }
    else {
      const jsn = {
        "id": id,
        "user": user,
        "brand": brand,
        "quantity": quantity,
        "description":description,
        "initDate": initDate,
        "endDate": endDate,
        "goals": goals
      }

      const res = await updateGoalsData(jsn);

      if(res.success === true) {
        notifyAlert('success', "Mensaje", "Meta actualizada correctamente");
      }
      else {
        notifyAlert('danger', "Mensaje", "Error al actualizar el presupuesto");
      }
    }

    setLoading({page: false, btn: false});
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Metas </h3>
                  </div>
                </Row>
              </CardHeader>
              
              <CardBody>
                <div className='paddingHorizonal'>
                  {
                    loading.page === true ?
                      <div className="text-center">
                        <Spinner color="primary" />
                      </div> : 
                      <>
                        {
                          error === true ?
                            <div className="text-center">
                              <h3> Error al cargar la información </h3>
                            </div>
                          :
                          <>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Vendedor </label>
                                  <select
                                    value={user}
                                    className='form-control'
                                    onChange={val => setUser(val.target.value)}>
                                    <option value=""> Seleccionar </option>
                                    {
                                      userArr.map( (v, k) => (
                                        <option 
                                          key={k} 
                                          value={v.id}> {v.name} </option>
                                      ))
                                    }
                                  </select>
                                </FormGroup>
                              </Col>
    
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Meta </label>
                                  <Input
                                    type="number"
                                    value={goals}
                                    className="form-control"
                                    onChange={val => setGoals(val.target.value)}/>
                                </FormGroup>
                              </Col>
    
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Cantidad de marcas </label>
                                  <Input
                                    type="number"
                                    value={brand}
                                    className="form-control"
                                    onChange={val => setBrand(val.target.value)}/>
                                </FormGroup>
                              </Col>
    
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Cantidad de piezas </label>
                                  <Input
                                    type="number"
                                    value={quantity}
                                    className="form-control"
                                    onChange={val => setQuantity(val.target.value)}/>
                                </FormGroup>
                              </Col>
    
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Fecha de inicio </label>
                                  <Input
                                    type="date"
                                    value={initDate}
                                    className="form-control"
                                    onChange={val => setInitDate(val.target.value)}/>
                                </FormGroup>
                              </Col>
    
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label"> Fecha final </label>
                                  <Input
                                    type="date"
                                    value={endDate}
                                    className="form-control"
                                    onChange={val => setEndDate(val.target.value)}/>
                                </FormGroup>
                              </Col>
    
                              <Col lg="12">
                                <FormGroup>
                                  <label className="form-control-label"> Descripción (opcional) </label>
                                  <textarea 
                                    style={{
                                      height: '100px',
                                      resize: 'none'
                                    }}
                                    value={description}
                                    className="form-control"
                                    onChange={(val) => setDescription(val.target.value)} />
                                </FormGroup>
                              </Col>
                            </Row>
    
                            <Row>
                              <Col lg="12" >
                                <br />
                                <div className="float-right">
                                  {
                                    (loading.btn) ? <Spinner style={{color: '#004899'}}/> :
                                    <div>
                                      <Button color="gray" to="/admin/goals" tag={Link}> Regresar </Button>
                                      <Button
                                        style={{
                                          backgroundColor: '#004899',
                                          color: '#fff',
                                          border: 0
                                        }}
                                        onClick={() => addNewGoals()}
                                        > Guardar
                                      </Button>
                                    </div>
                                  } 
                                </div>
                              </Col>
                            </Row>
                          </>
                        }
                      </>
                  }
                </div>
              </CardBody>
            </Card>
 
          </Col>
        </Row>
      </Container>

    </>
  )
}
