import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function getCatalogData() {
	const token = getToken('spm_token');

	try {
		const res = await axios.get(CONS.catalog, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function createCatalogData(jsn) {
	const token = getToken('spm_token');

	try {
		const res = await axios.post(CONS.catalog, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}
